import { StateProvider, UrlRouterProvider, Transition } from "@uirouter/angularjs";
import NewsService from "./services/news.service";
import SessionService from "../../services/Session.service";
import ProductsService from "./services/products.service";
import EventsService from "./services/events.service";
import JobsService from "./services/jobs.service";
import FeedsService from "./services/feeds.service";
import DownloadsService from "./services/download.service";
import VideosService from "./services/videos.service";
import GalleriesService from "./services/galleries.service";
import LogosService from "./services/logos.service";
import TopimagesService from "./services/topimages.service";
import InfoService from "./services/info.service";
import CompanypersonsService from "./services/companypersons.service";
import JobwareService from "./services/jobware.service";
import CompanybasedataService from "./services/companybasedata.service";
import SocialmediaService from "./services/socialmedia.service";

"use strict";

export default class RouterConfig {
    static $inject = ["$stateProvider"];

    constructor($stateProvider: StateProvider) {

        $stateProvider //Firmeneintrag Detail
            .state('index.companyprofile',
                {
                    url: "/companyprofile",
                    template: require("./profile.html")
                    
                })
            .state('index.companyprofile.general', {
                url: "/general",
                component: "profileGeneralTabs",
                params: {

                },
                resolve: {
                }
            })
            .state('index.companyprofile.logos', {
                url: "/logos",
                template: "<ui-view></ui-view>",
                resolve: {
                },
                abstract: true
            })
            .state('index.companyprofile.general.logos', {
                url: "/logos?page",
                params: {
        
                },
                component: "profileLogosListing",
                resolve: {
                    result: ["LogosService","$transition$", (LogosService: LogosService,$transition$) => {
                        let page =$transition$.params().page?$transition$.params().page:1
                        return LogosService.fetchAll(10,page * 10 - 10);
                    }],
                    page :["$transition$",($transition$)=>{
                        return $transition$.params().page?$transition$.params().page:1;
                    }]  
                }
            })
            .state('index.companyprofile.logos.detail', {
                url: "/:id",
                component: "profileLogosDetail",
                params: {

                },
                resolve: {
                    content: ["LogosService", "$transition$", (LogosService: LogosService, $transition$: Transition) => {                   
                        return LogosService.fetch($transition$.params().id).$promise;
                    }],   
                    id :["$transition$",($transition$)=>{
                        return $transition$.params().id;
                    }]                 
                }
            })
            .state('index.companyprofile.topimages', {
                url: "/topimages",
                template: "<ui-view></ui-view>",
                resolve: {
                },
                abstract: true
            })
            .state('index.companyprofile.general.topimages', {
                url: "/topimages?page",
                params: {
        
                },
                component: "profileTopimagesListing",
                resolve: {
                    result: ["TopimagesService","$transition$", (TopimagesService: TopimagesService,$transition$) => {
                        let page =$transition$.params().page?$transition$.params().page:1
                        return TopimagesService.fetchAll(10,page * 10 - 10);
                    }],
                    pagel :["$transition$",($transition$)=>{
                        return $transition$.params().page?$transition$.params().page:1;
                    }]  
                }
            })
            .state('index.companyprofile.topimages.detail', {
                url: "/:id",
                component: "profileTopimagesDetail",
                params: {

                },
                resolve: {
                    content: ["TopimagesService", "$transition$", (TopimagesService: TopimagesService, $transition$: Transition) => {                   
                        return TopimagesService.fetch($transition$.params().id).$promise;
                    }],   
                    id :["$transition$",($transition$)=>{
                        return $transition$.params().id;
                    }]                 
                }
            })
            .state('index.companyprofile.events', {
                url: "/events",
                params: {

                },
                template: "<ui-view></ui-view>",
                resolve: {
                },
                abstract: true
            })
            .state('index.companyprofile.events.listing', {
                url: "?page",
                params: {
        
                },
                component: "profileEvents",
                resolve: {
                    result: ["EventsService","$transition$", (EventsService: EventsService,$transition$) => {
                        let page =$transition$.params().page?$transition$.params().page:1
                        return EventsService.fetchAll(10,page * 10 - 10);
                    }],
                    page :["$transition$",($transition$)=>{
                        return $transition$.params().page?$transition$.params().page:1;
                    }]  
                }
            })
            .state('index.companyprofile.events.detail', {
                url: "/:id",
                component: "profileEventsDetail",
                params: {

                },
                resolve: {
                    content: ["EventsService", "$transition$", (EventsService: EventsService, $transition$: Transition) => {                   
                        return EventsService.fetch($transition$.params().id).$promise;
                    }],   
                    id :["$transition$",($transition$)=>{
                        return $transition$.params().id;
                    }]                 
                }
            })
            .state('index.companyprofile.contents', {
                url: "/contents",
                params: {

                },
                component: "profileNewsTabs",
                resolve: {
                },
                abstract: true
            })
            .state('index.companyprofile.contents.news', {
                url: "/news?page",
                params: {
        
                },
                component: "profileNews",
                resolve: {
                    result: ["NewsService","$transition$", (NewsService: NewsService,$transition$) => {
                        let page =$transition$.params().page?$transition$.params().page:1
                        return NewsService.fetchAll(false,10,page * 10 - 10);
                    }],
                    page :["$transition$",($transition$)=>{
                        return $transition$.params().page?$transition$.params().page:1;
                    }] 
                }
            })
            .state('index.companyprofile.contents.feeds', {
                url: "/feeds?page",
                params: {
        
                },
                component: "profileNewsFeeds",
                resolve: {
                    result: ["NewsService","$transition$", (NewsService: NewsService,$transition$) => {
                        let page =$transition$.params().page?$transition$.params().page:1
                        return NewsService.fetchAll(true,10,page * 10 - 10);
                    }],
                    page :["$transition$",($transition$)=>{
                        return $transition$.params().page?$transition$.params().page:1;
                    }],
                    feedsResult :["FeedsService",(FeedsService:FeedsService)=>{
                        return FeedsService.fetchAll("news");
                    }]  
                }
            })
            .state('index.companyprofile.contents.articles', {
                url: "/articles?page",
                params: {
        
                },
                component: "profileNewsArticles",
                resolve: {
                    page :["$transition$",($transition$)=>{
                        return $transition$.params().page?$transition$.params().page:1;
                    }]
                }
            })
            .state('index.companyprofile.news', {
                url: "/news",
                template: "<ui-view></ui-view>",
                resolve: {
                },
                abstract: true
            })
            .state('index.companyprofile.news.detail', {
                url: "/:id",
                component: "profileNewsDetail",
                params: {

                },
                data: {
                    label: 'Nachrichten', //label to show in breadcrumbs
                },
                resolve: {
                    content: ["NewsService", "$transition$", (NewsService: NewsService, $transition$: Transition) => {                   
                        return NewsService.fetch($transition$.params().id).$promise;
                    }],   
                    id :["$transition$",($transition$)=>{
                        return $transition$.params().id;
                    }]                 
                }
            })
            .state('index.companyprofile.contact', {
                url: "/contact",
                component: "profileContactTabs",
                params: {

                },
                resolve: {
                }
            })
            .state('index.companyprofile.contact.basedata', {
                url: "/basedata?page",
                component: "profileBasedata",
                params: {

                },
                resolve: {
                    result: ["CompanybasedataService","SessionService","$transition$", (CompanybasedataService: CompanybasedataService,SessionService:SessionService,$transition$) => {
                        let page =$transition$.params().page?$transition$.params().page:1
                        return CompanybasedataService.fetchAll(10,page * 10 - 10);
                    }],
                    page :["$transition$",($transition$)=>{
                        return $transition$.params().page?$transition$.params().page:1;
                    }] ,
                }
            })
            .state('index.companyprofile.basedata', {
                url: "/basedata",
                template: "<ui-view></ui-view>",
                params: {

                },
               
                abstract: true
            })
            .state('index.companyprofile.basedata.detail', {
                url: "/:id",
                component: "profileBasedataDetail",
                params: {

                },
                resolve: {
                    basedata: ["CompanybasedataService", "$transition$", (CompanybasedataService: CompanybasedataService, $transition$: Transition) => {                   
                        return CompanybasedataService.fetch($transition$.params().id).$promise;
                    }],   
                    id :["$transition$",($transition$)=>{
                        return $transition$.params().id;
                    }]                 
                }
            })
            .state('index.companyprofile.contact.socialmedia', {
                url: "/socialmedia?page",
                component: "profileSocialmedia",
                params: {

                },
                resolve: {
                    result: ["SocialmediaService","SessionService","$transition$", (SocialmediaService: SocialmediaService,SessionService:SessionService,$transition$) => {
                        let page =$transition$.params().page?$transition$.params().page:1
                        return SocialmediaService.fetchAll(10,page * 10 - 10);
                    }],
                    page :["$transition$",($transition$)=>{
                        return $transition$.params().page?$transition$.params().page:1;
                    }] ,
                }
            })
            .state('index.companyprofile.socialmedia', {
                url: "/socialmedia",
                template: "<ui-view></ui-view>",
                params: {

                },
               
                abstract: true
            })
            .state('index.companyprofile.socialmedia.detail', {
                url: "/:id",
                component: "profileSocialmediaDetail",
                params: {

                },
                resolve: {
                    basedata: ["SocialmediaService", "$transition$", (SocialmediaService: SocialmediaService, $transition$: Transition) => {                   
                        return SocialmediaService.fetch($transition$.params().id).$promise;
                    }],   
                    id :["$transition$",($transition$)=>{
                        return $transition$.params().id;
                    }]                 
                }
            })
            .state('index.companyprofile.contact.contactpersons', {
                url: "/contactpersons?page",
                component: "profileContactpersons",
                params: {

                },
                resolve: {
                    result: ["CompanypersonsService","SessionService","$transition$", (CompanypersonsService: CompanypersonsService,SessionService:SessionService,$transition$) => {
                        let page =$transition$.params().page?$transition$.params().page:1
                        return CompanypersonsService.fetchAll(10,page * 10 - 10);
                    }],
                    page :["$transition$",($transition$)=>{
                        return $transition$.params().page?$transition$.params().page:1;
                    }] ,
                },
            })
            .state('index.companyprofile.contactpersons', {
                url: "/contactpersons",
                template: "<ui-view></ui-view>",
                params: {

                },
               
                abstract: true
            })
            .state('index.companyprofile.contactpersons.detail', {
                url: "/:id",
                component: "profileContactpersonsDetail",
                params: {

                },
                resolve: {
                    person: ["CompanypersonsService", "$transition$", (CompanypersonsService: CompanypersonsService, $transition$: Transition) => {                   
                        return CompanypersonsService.fetch($transition$.params().id).$promise;
                    }],   
                    id :["$transition$",($transition$)=>{
                        return $transition$.params().id;
                    }]                 
                }
            })
            .state('index.companyprofile.galleries', {
                url: "/galleries",
                template: "<ui-view></ui-view>",
                params: {

                },
                data: {
                    label: 'Bilder', //label to show in breadcrumbs
                },
                resolve: {
                },
                abstract: true
            })
            .state('index.companyprofile.galleries.listing', {
                url: "?page",
                component: "profileGalleries",
                params: {

                },
                data: {
                  
                },
                resolve: {
                    result: ["GalleriesService","SessionService","$transition$", (GalleriesService: GalleriesService,SessionService:SessionService,$transition$) => {
                        let page =$transition$.params().page?$transition$.params().page:1
                        return GalleriesService.fetchAll(10,page * 10 - 10);
                    }],
                    page :["$transition$",($transition$)=>{
                        return $transition$.params().page?$transition$.params().page:1;
                    }] ,
                    feedsResult :["FeedsService",(FeedsService:FeedsService)=>{
                        return FeedsService.fetchAll("products");
                    }]  
                }
            })
            .state('index.companyprofile.galleries.detail', {
                url: "/:id",
                component: "profileGalleriesDetail",
                params: {

                },
                data: {
                },
                resolve: {
                    content: ["GalleriesService", "$transition$","SessionService", (GalleriesService: GalleriesService, $transition$: Transition,SessionService:SessionService) => {                   
                        return GalleriesService.fetch($transition$.params().id).$promise;
                    }],   
                    id :["$transition$",($transition$)=>{
                        return $transition$.params().id;
                    }]                 
                }
            })
            .state('index.companyprofile.job', {
                url: "/jobs",
                params: {

                },
                template: "<ui-view></ui-view>",
                resolve: {
                },
                abstract: true
            })
            .state('index.companyprofile.job.listing', {
                url: "?page",
                params: {
        
                },
                component: "profileJobs",
                resolve: {
                    result: ["JobsService","$transition$", (JobsService: JobsService,$transition$) => {
                        let page =$transition$.params().page?$transition$.params().page:1
                        return JobsService.fetchAll(10,page * 10 - 10);
                    }],
                    page :["$transition$",($transition$)=>{
                        return $transition$.params().page?$transition$.params().page:1;
                    }] ,
                    feedsResult :["FeedsService",(FeedsService:FeedsService)=>{
                        return FeedsService.fetchAll("jobs");
                    }],
                    jobwareResult :["JobwareService",(JobwareService:JobwareService)=>{
                        return JobwareService.fetchAll("jobs");
                    }]
                }
            })
            .state('index.companyprofile.job.detail', {
                url: "/:id",
                component: "profileJobsDetail",
                params: {

                },
                resolve: {
                    content: ["JobsService", "$transition$", (JobsService: JobsService, $transition$: Transition) => {                   
                        return JobsService.fetch($transition$.params().id).$promise;
                    }],   
                    id :["$transition$",($transition$)=>{
                        return $transition$.params().id;
                    }]                 
                }
            })
            .state('index.companyprofile.products', {
                url: "/products",
                params: {

                },
                template: "<ui-view></ui-view>",
                data: {
                    label: 'Produkte', //label to show in breadcrumbs
                },
                resolve: {
                },
                abstract: true
            })
            .state('index.companyprofile.products.listing', {
                url: "?page",
                params: {

                },
                component: "profileProducts",
                data: {                
                },
                resolve: {
                    result: ["ProductsService","SessionService","$transition$", (ProductsService: ProductsService,SessionService:SessionService,$transition$) => {
                        let page =$transition$.params().page?$transition$.params().page:1
                        return ProductsService.fetchAll(10,page * 10 - 10);
                    }],
                    page :["$transition$",($transition$)=>{
                        return $transition$.params().page?$transition$.params().page:1;
                    }] ,
                    feedsResult :["FeedsService",(FeedsService:FeedsService)=>{
                        return FeedsService.fetchAll("products");
                    }]  
                }
            })
            .state('index.companyprofile.products.detail', {
                url: "/:id",
                component: "profileProductDetail",
                params: {

                },
                data: {
                },
                resolve: {
                    content: ["ProductsService", "$transition$","SessionService", (ProductsService: ProductsService, $transition$: Transition,SessionService:SessionService) => {                   
                        return ProductsService.fetch($transition$.params().id).$promise;
                    }],   
                    id :["$transition$",($transition$)=>{
                        return $transition$.params().id;
                    }]                 
                }
            })
            .state('index.companyprofile.downloads', {
                url: "/downloads",
                params: {

                },
                template: "<ui-view></ui-view>",
                resolve: {
                },
                abstract: true
            })
            .state('index.companyprofile.downloads.listing', {
                url: "?page",
                params: {
        
                },
                component: "profileDownloads",
                resolve: {
                    result: ["DownloadsService","$transition$", (DownloadsService: DownloadsService,$transition$) => {
                        let page =$transition$.params().page?$transition$.params().page:1
                        return DownloadsService.fetchAll(10,page * 10 - 10);
                    }],
                    page :["$transition$",($transition$)=>{
                        return $transition$.params().page?$transition$.params().page:1;
                    }]  
                }
            })
            .state('index.companyprofile.downloads.detail', {
                url: "/:id",
                component: "profileDownloadsDetail",
                params: {

                },
                resolve: {
                    content: ["DownloadsService", "$transition$", (DownloadsService: DownloadsService, $transition$: Transition) => {                   
                        return DownloadsService.fetch($transition$.params().id).$promise;
                    }],   
                    id :["$transition$",($transition$)=>{
                        return $transition$.params().id;
                    }]                 
                }
            })
            .state('index.companyprofile.videos', {
                url: "/videos",
                params: {

                },
                template: "<ui-view></ui-view>",
                data: {
                    label: 'Nachrichten', //label to show in breadcrumbs
                },
                resolve: {
                },
                abstract: true
            })
            .state('index.companyprofile.videos.listing', {
                url: "?page",
                params: {
        
                },
                component: "profileVideos",
                resolve: {
                    result: ["VideosService","$transition$", (VideosService: VideosService,$transition$) => {
                        let page =$transition$.params().page?$transition$.params().page:1
                        return VideosService.fetchAll(10,page * 10 - 10);
                    }],
                    page :["$transition$",($transition$)=>{
                        return $transition$.params().page?$transition$.params().page:1;
                    }]  
                }
            })
            .state('index.companyprofile.videos.detail', {
                url: "/:id",
                component: "profileVideosDetail",
                params: {

                },
                resolve: {
                    content: ["VideosService", "$transition$", (VideosService: VideosService, $transition$: Transition) => {                   
                        return VideosService.fetch($transition$.params().id).$promise;
                    }],   
                    id :["$transition$",($transition$)=>{
                        return $transition$.params().id;
                    }]                 
                }
            })
            .state('index.companyprofile.info', {
                url: "/aboutus",
                params: {

                },
                template: "<ui-view></ui-view>",
                resolve: {
                },
                abstract: true
            })
            .state('index.companyprofile.info.listing', {
                url: "?page",
                params: {
        
                },
                component: "profileInfo",
                resolve: {
                    result: ["InfoService","$transition$", (InfoService: InfoService,$transition$) => {
                        let page =$transition$.params().page?$transition$.params().page:1
                        return InfoService.fetchAll(10,page * 10 - 10);
                    }],
                    page :["$transition$",($transition$)=>{
                        return $transition$.params().page?$transition$.params().page:1;
                    }]  
                }
            })
            .state('index.companyprofile.info.detail', {
                url: "/:id",
                component: "profileInfoDetail",
                params: {

                },
                resolve: {
                    content: ["InfoService", "$transition$", (InfoService: InfoService, $transition$: Transition) => {                   
                        return InfoService.fetch($transition$.params().id).$promise;
                    }],   
                    id :["$transition$",($transition$)=>{
                        return $transition$.params().id;
                    }]                 
                }
            })
            .state('index.companyprofile.keywords', {
                url: "/keywords",
                params: {
                },
                component: "profileKeywords",
                resolve: {
                }
            })
    }
}