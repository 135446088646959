import { IQService} from "angular";
import moment = require("moment");
import ImagesService from "../../../../services/images.service";
import {StateService} from "@uirouter/angularjs"
import { IToasterService } from "ngtoaster";
import InfoService from "../../../../services/info.service";
import CompanypersonsService from "../../../../services/companypersons.service";

"use strict";


export default class ContactPersonsDetailComponent implements ng.IComponentOptions {

    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;


    constructor() {
        this.bindings = {
            person: '<',
            id: '<'

        };
        this.controllerAs = 'vm';
        this.controller = DetailComponentController;
        this.template = require("./detail.html");

    }


}


export class DetailComponentController implements ng.IComponentController {

   

    person: any;
    file:any;

    datefrom:Date = null;
    dateto:Date = null;

    isSaving:boolean = false;

    image_path:string;
    id:string;
    alerts = [];
    isOpenDateFrom = false;
    isOpenDateTo = false;

    departments:any[];
    positions:any[];

    salutations = [
        "Herr","Frau"
    ];

    lang:string;

    static $inject: Array<String> = ["$translate","ImagesService","CompanypersonsService","$state","$q","toaster","$rootScope"]
    
    constructor(private $translate:angular.translate.ITranslateService,private ImagesService:ImagesService,private CompanypersonsService:CompanypersonsService,private $state:StateService,private $q:IQService,private toaster:IToasterService,private $rootScope) {
        this.departments = require("./../companycontactdepartments.json");
        this.positions = require("./../companycontactpositions.json");
    }
    $onInit(){
        this.lang = this.$translate.use();

        this.$rootScope.$on('$translateChangeSuccess', () => {
            this.lang = this.$translate.use();
        });

        this.positions.some(element => {

            if(element.POSITION == this.person.position)
            {
                this.person.fkposition = element.PKCOMPANYCONTACTPOSITIONS;
                return true;
            }
            return false;
        });
        
        this.departments.some(element => {
            
            if(element.DEPARTMENT == this.person.department)
            {
                this.person.fkdepartment = element.PKCOMPANYCONTACTDEPARTMENTS;
                return true;
            }
            return false;
        });
        
    }
    save()
    {       
       
        this.isSaving = true;

        this.departments.some(element => {
            if(element.PKCOMPANYCONTACTDEPARTMENTS == this.person.fkdepartment)
            {
                this.person.department = element.DEPARTMENT;                
                this.person.department_en = element.DEPARTMENT_EN;        
                return true;
            }
           
            return false;
        });

        this.positions.some(element => {
            if(element.PKCOMPANYCONTACTPOSITIONS == this.person.fkposition)
            {
                this.person.position = element.POSITION;                
                this.person.position_en = element.POSITION_EN;        
                return true;
            }
           
            return false;
        });
        
        let promises:any = {};       
                        
        //Wurde neues Bild hochgeladen?
        if(this.isTempImage()){                        
            promises.image = this.ImagesService.upload(this.file).then((result)=>{
                this.file = null;
                return result.data;
            });                        
        }


        this.$q.all(promises).then((results:any)=>
        {

            let person = this.person;

            if(results.image)
            {
                if(person.image !== null && typeof person.images.id !== "undefined"){
                    //Löschen Async ausführen
                    this.ImagesService.delete(person.image.id);
                }
                person.image = results.image;
            }

            return person;

        })
        .then((person)=>{
            if(this.id == "new")
            {
               return this.CompanypersonsService.create(person);
            }
            else
            {
                return this.CompanypersonsService.update(person);
            }
        })
        .then((result)=> {
            this.person = result;

            this.toaster.success(this.$translate.instant('Erfolgreich gespeichert!'));
            if(  this.id == "new")
            {
                this.$state.transitionTo(this.$state.$current.name,{id:this.person.id},{notify:false});                    
            }
            else
            {
                this.isSaving =false;
            }  

        },
        (responseError:any):any =>
        {    
            this.toaster.error(this.$translate.instant('Es ist ein Fehler aufgetreten!'));                     
            this.isSaving =false;          
        }); 

    }

    isTempImage():boolean
    {
        return (typeof this.file === 'object')
    }


}