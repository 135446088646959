import { StateService } from "@uirouter/core";


"use strict";


export default class BasedataComponent implements ng.IComponentOptions{
    
    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;
              

    constructor() {
        this.bindings = {                            
            id: '<',
            result:'<',
            page:'<'
        };
        this.controllerAs = 'vm';
        this.controller = BasedataComponentController;
        this.template = require("./basedata.html");            
        
    }


}

export class BasedataComponentController implements ng.IComponentController{

    private result:any;

    static $inject:Array<string> = ["$state"]


    constructor(private $state:StateService){

    }

  
    getData(page:number){
        this.$state.transitionTo(this.$state.$current.name,{page:page},{notify:false});
    }

}