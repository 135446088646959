import * as angular from "angular";

export interface IFeedService {
    parseFeed(url: any): angular.IHttpPromise<any>;
    getb2bfeed(url: string): angular.IHttpPromise<any>;
}

export default class FeedService implements IFeedService {
    static $inject: Array<string> = ['$http'];

    constructor(private $http: angular.IHttpService) {
    }

    public parseFeed(url: any) {
        let test = this.$http.jsonp(
            'https://query.yahooapis.com/v1/public/yql?q=select%20*%20from%20xml%20where%20url%20%3D%20' + encodeURIComponent(url) + '&format=json'
        );
        return test;
    }


    public getb2bfeed(url: string): angular.IHttpPromise<any> {
        return this.$http.get(
            "/api/insights/b2bfeed",
            {params: {url: url}}
        );
    }
}