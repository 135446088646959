"use strict";


import * as angular from "angular";

export default class ContactComponent implements ng.IComponentOptions{
    
    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;
            

    constructor() {
        this.bindings = {                            
            id: '<'
        };
        this.controllerAs = 'vm';
        this.controller = ContactComponentController;
        this.template = require("./contact.html");            
        
    }


}

export class ContactComponentController implements ng.IComponentController{
    static $inject: Array<string> = ["NETWORK_ID","NETWORK_CONFIG"];
    constructor(private NETWORK_ID:number,private NETWORK_CONFIG:any){
    }
}