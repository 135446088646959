import { ISessionService } from "../../../../../services/Session.service";
import CompanypersonsService from "../../../services/companypersons.service";
import { StateService } from "@uirouter/core";


"use strict";


export default class ContactPersonsComponent implements ng.IComponentOptions{
    
    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;
            

    constructor() {
        this.bindings = {   
            result:'<' ,                        
            id: '<',
            page :'<'
        };
        this.controllerAs = 'vm';
        this.controller = ContactPersonsComponentController;
        this.template = require("./contactpersons.html");            
        
    }


}

export class ContactPersonsComponentController implements ng.IComponentController{
    static $inject = ["$state"];

    private result:any;

    private items_per_page = 10;

    private page= 1;
    constructor(private $state:StateService){}

    $onInit(){        
       
        
    }


    getData(page:number){
        this.$state.transitionTo(this.$state.$current.name,{page:page},{notify:false});
    }

}