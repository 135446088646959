    'use strict';

    import { IPosition,ICampaign } from "./../../services/campaign.service";
    import {IStatisticsService} from './../../services/stats.service';
    import $ = require("jquery");
    import angular = require("angular");
    import { Transition } from "@uirouter/core";
    import * as moment from 'moment';
    let now = moment().format('LLLL');
    
    interface IPositionSelectable extends IPosition{
        selected:boolean;
         selectedTraffic:boolean;
    }
    export default class ReachadvertorialController {
        static $inject: Array<string> = ['campaign','$translate','DTOptionsBuilder','$transition$'];
        
        
        dtOptions:any;
        loading:boolean=false;
        hideOverview:boolean=false;    
        trafficstats:any;
        selectedPositions:any[] = []; 
        currentPosition:IPosition=null;
        selectedPosition = null;          
        selectedPositionsList:string="";

        constructor(private campaign: ICampaign,private $translate:angular.translate.ITranslateService,private DTOptionsBuilder,private $transition$:Transition) {

            this.hideOverview = $transition$.params().hideOverview;
            
            //Plugin "Utlimate Date / Time Sorting" konfigurieren
            //@ts-ignore
            $.fn.dataTable.moment("DD.MM.YYYY");

            angular.forEach(this.campaign.positions,(position)=>{
                  (<IPositionSelectable>position).selected = true;
                  (<IPositionSelectable>position).selectedTraffic = false;
                  if(position.contenttype == "advertorial")
                  {
                    position.contenttype = "article"
                  }
            });

            this.dtOptions =  this.DTOptionsBuilder.newOptions()
            .withDOM('<"html5buttons"B>lTfgitp')
           
            .withButtons([                
                {extend: 'csv'},
                {extend: 'excel'},
                {extend: 'pdf', title: 'Positionen ' +this.campaign.title},

                {extend: 'print',
                    customize: (win) => {
                        $(win.document.body).addClass('white-bg');
                        $(win.document.body).css('font-size', '10px');

                        $(win.document.body).find('table')
                            .addClass('compact')
                            .css('font-size', 'inherit');
                    }
                }
            ])
            .withOption('order', [1, 'asc']).withOption('lengthMenu', [25, 50, 100])
            .withLanguageSource('assets/localization/dt-'+this.$translate.use()+".json");


        }

        toggleSelection(id){
           
            let index = this.selectedPositions.indexOf(id); 
            if(index > -1){
                this.selectedPositions.splice(index,1);
            }
            else{
                this.selectedPositions.push(id);
            }

            if(this.selectedPositions.length == 1){
                angular.forEach(this.campaign.positions,(position)=>{
                    if(position._id == this.selectedPositions[0]){
                        this.currentPosition =position;
                    }
                });
            }
            this.selectedPositionsList = this.selectedPositions.join(",");
        }

    }


