
        'use strict';
        import * as angular from "angular";
        export interface ILeadResult{
                data:ILead[],
                metadata:ILeadStats;
                headers:string[];
        }
        export interface ILead{
                exported_leads_date: number;
                firstname:string,
                lastname:string,
                email:string,
                leaddate:string,
                company:string,
                downloadtitle:string,
                clientname:string,
                event_id:string,
                user_id:string

        }

        export interface ILeadStats{
                total:number,
                qualified:number
        }


        export interface ILeadsService {
                getLeadsByCampaign(campaignid: string,position_ids?:string): angular.IHttpPromise<ILeadResult>;
                getLeadsStatsByCampaign(campaignid: string,position_ids?:string): angular.IHttpPromise<any>;
                getLead(id: number): angular.IHttpPromise<ILead>;
        }
        export default class LeadsService implements ILeadsService {
                static $inject: Array<string> = ['$http'];
                constructor(private $http: angular.IHttpService) { }

                static URL = "/api/insights/leads";

                getLeadsByCampaign(campaignid: string,position_ids=""): angular.IHttpPromise<ILeadResult> {
                        return this.$http.get(LeadsService.URL, {params:{campaign:campaignid,position_ids:position_ids}});
                }


                getLead(id: number): angular.IHttpPromise<ILead> {
                        return this.$http.get(LeadsService.URL, { params: { id } });
                }

                getLeadsStatsByCampaign(campaignid: string,position_ids=""): angular.IHttpPromise<ILeadStats> {
                        return this.$http.get(LeadsService.URL+"stats/", {params:{campaign:campaignid,position_ids:position_ids}});
                }

        }

      
