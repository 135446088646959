
    'use strict';

    import { IPosition } from "./campaign.service";
    import * as angular from "angular";
    export interface IContent {
        title: string;
        description: string;
        ordernumber: string;
        campaigntype: string;
        campaignproduct: string;
        keywords: string[];

        datefrom: string;
        dateto: string;
        leadmax: number;
        leadmin: number;

        positions: IPosition[];

        company: string;
        fkcompanies: number;
        agency: string;
        fkagencies: number;

        advertorialclicks: number;
        advertorialcalls: number;

        exportconfig: string;

        metadata_cbid: string;
        metadata_modiuser: number;
        metadata_creauser: number;
        metadata_datefrom: string;
        metadata_dateto: string;
        metadata_creadate: string;
        metadata_modidate: string;
        contenttype: string;

        deleted: boolean
    }

       export interface IContentService {
        getContentsByCompanyOrAgency(ctype: string,companyid: number): angular.IHttpPromise<IContent[]>;
        getContetentByType(ctype: string,pk: number): angular.IHttpPromise<IContent>;
       
    }
    

    export default class ContentService implements IContentService {

        static URL: string = "/api/insights/contents/";
        static $inject: Array<string> = ['$http'];
        constructor(private $http: angular.IHttpService) { }

        public getContentsByCompanyOrAgency(ctype: string,companyid: number): angular.IHttpPromise<IContent[]> {
            return this.$http.get(ContentService.URL, { params: { ctype: ctype,cid: companyid } });
            
        }

         public getContetentByType(ctype: string,pk: number): angular.IHttpPromise<IContent> {
            return this.$http.get(ContentService.URL + "detail/",{params:{ctype: ctype,id:pk}});
        }

         
        
    }

   


