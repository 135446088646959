"use strict";


var angular = require("angular");

//Components
//var HeaderComponent = require("./../header/header.component");
import HeaderComponent from "../header/header.component";
angular.module('insights').component('viHeader', new HeaderComponent());


import ChangepasswordComponent from "../auth/changepassword/changepassword.component";
angular.module('insights').component('viChangePassword',new ChangepasswordComponent());


import LoginComponent from "../auth/login/login.component";
angular.module('insights').component('viLogin',new LoginComponent());

import AccountComponent from "../user/account/account.component";
angular.module('insights').component('viAccount',new AccountComponent());

import ResendpasswordComponent from "../auth/resendpassword/resendpassword.component";
angular.module('insights').component('viResendPassword',  new ResendpasswordComponent());

import SetuniqueidComponent from "../auth/setuniqueid/setuniqueid.component";
angular.module('insights').component('viSetuniqueid',new SetuniqueidComponent());

import  UsermenuComponent from "../user/usermenu/usermenu.component";
angular.module('insights').component('viUsermenu',new UsermenuComponent());

import  CompanyentryStatsComponent from "./companyentrystats/companyentrystats";
angular.module('insights').component('viCompanyentryStats',new CompanyentryStatsComponent());

import TrafficStatsComponent from "./trafficstats/trafficstats.component";
angular.module('insights').component('viTrafficStats',new TrafficStatsComponent());

import FeedbackComponent from "./viFeedback/viFeedback.component";
angular.module('insights').component('viFeedback', new FeedbackComponent());

import FooterComponent  from "./viFooter/footer.component";
angular.module('insights').component('viFooter', new FooterComponent());

import LeadtableComponent  from "./viLeadtable/viLeadtable.component";
angular.module('insights').component('viLeadtable',new LeadtableComponent());

import TopnavbarComponent  from "./viTopnavbar/viTopnavbar.component";
angular.module('insights').component('viTopnavbar',new TopnavbarComponent());

import NavigationComponent  from "./viNavigation/viNavigation.component";
angular.module('insights').component('viNavigation',new NavigationComponent());
