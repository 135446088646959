"use strict";

export default class UiSelectConfig{
    static $inject = ["uiSelectConfig"];

    constructor(uiSelectConfig){
        uiSelectConfig.theme = 'bootstrap';
    }
}

  
