import {IQService} from "angular";
import {StateService} from "@uirouter/angularjs"
import {IToasterService} from "ngtoaster";
import SocialmediaService from "../../../../services/socialmedia.service";
import {ContentTypes} from "../contentTypes";

"use strict";


export default class SocialmediaDetailComponent implements ng.IComponentOptions {

    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;


    constructor() {
        this.bindings = {
            basedata: '<',
            id: '<'

        };
        this.controllerAs = 'vm';
        this.controller = DetailComponentController;
        this.template = require("./detail.html");

    }


}


class DetailComponentController implements ng.IComponentController {


    basedata: any;

    isSaving: boolean = false;
    id: string;

    lang: string;

    networks: string[];

    static $inject: Array<String> = ["$translate", "SocialmediaService", "$state", "$q", "toaster", "$rootScope"];

    constructor(private $translate: angular.translate.ITranslateService, private SocialmediaService: SocialmediaService, private $state: StateService, private $q: IQService, private toaster: IToasterService, private $rootScope) {

    }

    $onInit() {
        this.networks = Object.keys(ContentTypes).map(v => ContentTypes[v as keyof typeof ContentTypes])


        this.lang = this.$translate.use();

        this.$rootScope.$on('$translateChangeSuccess', () => {
            this.lang = this.$translate.use();
        });


    }

    save() {

        this.isSaving = true;


        let promise: any = {};


        if (this.id == "new") {
            promise = this.SocialmediaService.create(this.basedata);
        } else {
            promise = this.SocialmediaService.update(this.basedata);
        }

        promise.then((result) => {
                this.basedata = result;

                this.toaster.success(this.$translate.instant('Erfolgreich gespeichert!'));
                if (this.id == "new") {
                    this.$state.transitionTo(this.$state.$current.name, {id: this.basedata.id}, {notify: false});
                } else {
                    this.isSaving = false;
                }

            },
            (responseError: any): any => {
                this.toaster.error(this.$translate.instant('Es ist ein Fehler aufgetreten!'));
                this.isSaving = false;
            });

    }

}