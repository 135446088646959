    import { IStatisticsService } from "./../../services/stats.service";
    import * as Chartist from 'chartist';
    import 'chartist-plugin-tooltips';
    import ngtoaster = require("ngtoaster");
    import * as moment from 'moment';
    let now = moment().format('LLLL');
    import angular = require("angular");

    require("chartist/dist/chartist.min.css");
    
    export default class TrafficStatsComponent implements ng.IComponentOptions {

        public bindings: any;
        public controller: any;
        public controllerAs: string;
        public template: string;
                


        constructor() {
            this.bindings = {                
                contentid: '<',
                client:'<',
                contenttype:'<',                
                datefrom:'<',
                dateto:'<' ,
                datefixed:'<',      
                positions:'<',
                campaignproduct:'<',
                isCampaign:'<',
                adid:'<'
            };
            this.controllerAs = 'vm';
            this.controller = TrafficStatsComponentController;
            this.template = require("./trafficstats.html");       
            
        }



    }

    export interface ITrafficStatsComponentBindings{
         contentid:string;

         adid: string;
         contenttype:string;
         client:number;
         datefrom:string;
         dateto:string;
         datefixed:boolean;
         positions:string;
         campaignproduct:string;
         isCampaign:boolean;
        
    }

    export interface ITrafficStatsComponentController extends ITrafficStatsComponentBindings{

         stats:any;
         datefrom:string;
         dateto:string;
         trafficData:any;

         startDate:Date;
         endDate:Date;
         periods: any;

         trafficLoading:boolean;

         client:number;
         contenttype:string;
         updateStats():void;
         setPeriod(period:string):void;                  
         adviews_total:number;
         pageviews_total:number;
         visits_total:number;     
         adviews_period:number;
         pageviews_period:number;
         visits_period:number;               
         show(type:string):void;
          changeperiod:boolean;

    }

    class TrafficStatsComponentController implements ITrafficStatsComponentController {

        static $inject: Array<string> = ['StatisticsService', '$log',"$translate","toaster"];
        
        
        contentid:string;

        adid:string;
        contenttype:string;
        client:number;
        datefrom:string;
        dateto:string;
        datefixed:boolean;
        positions:string;
        campaignproduct:string;
        periods =  [{"value":"month"}, { "value":"day"}];
        changeperiod:boolean =true;
        isCampaign:boolean = true;
        

        public stats:any;
        public trafficData:any;
        
        public chartData = [[]];
        public chartLabels= [];
        public activeButton = 1;

        public adviews_total:number;
        public pageviews_total:number;
        public visits_total:number;
        public clicks_total:number;
        public adviews_period:number;
        public pageviews_period:number;
        public clicks_period:number;
        public visits_period:number;  
        public ctr_period:string;   
        public ctr_total:string; 
        
        public startDate:Date;
        public endDate:Date;
        
        public isOpen1 = false;
        public isOpen2 = false;

        public chartist:any={};

        private selectedType:string;

        selectedPeriod = "day";

        public trafficLoading = true;

        constructor(private StatisticsService: IStatisticsService, private $log: angular.ILogService,private $translate:angular.translate.ITranslateService,private toaster:ngtoaster.IToasterService) {
            

             this.chartist.LineOptions = {
                lineSmooth: false,
                height: 250      ,
               plugins: [
                    Chartist.plugins.tooltip()
                ]          
            }

           
        }

        public updateStats(){
            this.chartist.lineData = {};

            this.trafficData = {                   
                adviews:[[]],
                pageviews:[[]],
                visits:[[]],
                ctr:[[]],
                clicks:[[]]
            };   
       
            this.trafficLoading = true;
            let datefrom = moment(this.startDate).format("YYYY-MM-DD");
            let dateto = moment(this.endDate).format("YYYY-MM-DD");
            let responsePromise =  this.StatisticsService.getTrafficStatsByType(this.adid?this.adid:this.contentid,this.contenttype,this.client,datefrom,dateto,this.selectedPeriod,this.positions,this.campaignproduct);
            
            responsePromise.then((result)=>{
                if(result.data.metadata && result.data.metadata.success){

                    this.stats = result.data.data;
                    
                    this.chartLabels = [];

                    angular.forEach(this.stats,(item)=>{
                        if(this.selectedPeriod == "month"){
                            this.chartLabels.push(moment(item.date).format('MM.YYYY'));
                        }
                        else{
                            this.chartLabels.push(moment(item.date).format('DD.MM.YYYY'));
                        }
                        this.trafficData.adviews[0].push(item.adviews);
                        this.trafficData.pageviews[0].push(item.pageviews);
                        this.trafficData.visits[0].push(item.visits);
                        this.trafficData.clicks[0].push(item.clicks);
                        if(item.adviews > 0){
                            this.trafficData.ctr[0].push(item.ctr);
                        }   
                        else {
                            this.trafficData.ctr[0].push(0);                   
                        }
                    });

                    if(this.stats.length > 0)
                    {
                        this.adviews_period = this.stats[this.stats.length - 1].adviews_total;
                        this.pageviews_period = this.stats[this.stats.length - 1].pageviews_total;
                        this.visits_period = this.stats[this.stats.length - 1].visits_total;
                        this.clicks_period = this.stats[this.stats.length - 1].clicks_total;
                    }
                    else
                    {
                        this.adviews_period = 0;  
                        this.pageviews_period = 0;
                        this.visits_period = 0;
                        this.clicks_period = 0;
                    }

                    if(this.clicks_period > 0){
                        this.ctr_period = (this.clicks_period /this.adviews_period * 100).toFixed(2) ;
                    }
                    else{
                        this.ctr_period = "0";
                    }

                    this.adviews_total = result.data.metadata.adviews_total;
                    this.pageviews_total = result.data.metadata.pageviews_total;
                    this.visits_total = result.data.metadata.visits_total;
                    this.clicks_total = result.data.metadata.clicks_total;


                    this.ctr_total =   result.data.metadata.ctr_total;

                    //Labels eintragen
                    this.chartist.lineData.labels = this.chartLabels;

                    //View aktualisieren
                    this.show(this.selectedType);
                }
                else{
                     this.toaster.pop('error',this.$translate.instant('stats_error_title'),this.$translate.instant('stats_error_text'));
                }
               
            },
            (error)=>{
                    this.toaster.pop('error',this.$translate.instant('stats_error_title'),this.$translate.instant('stats_error_text'));
            }).finally(()=>{
                this.trafficLoading = false;
                
            });
        }

        $onInit() {
                  
          
        }; 
        
        $onChanges(){
         
            if(this.contenttype == "advertorial" || this.campaignproduct =='transfer'){
                this.selectedType = "adclicks";
            }else{
                this.selectedType = "adviews";
            }

            if(typeof this.positions == "undefined"){
                this.positions = "";
            }

            if(this.datefrom)
            {
                this.startDate = moment(this.datefrom).toDate();
            }
            else
            {
                this.startDate = moment().subtract(3,"months").toDate();
            }

            if(this.dateto && moment(this.dateto ).isAfter(this.startDate))
            {
                this.endDate = moment(this.dateto).toDate();
                
            }
            else  if(this.dateto && moment(this.dateto ).isSame(this.startDate))
            {
                this.endDate = moment(this.startDate).add(7,"days").toDate();

            }
            else
            {
                this.endDate = moment().toDate();
            }


            let duration = moment.duration(moment(this.endDate).diff(moment(this.startDate)));
            let days = duration.asDays();

          
            
            if (this.changeperiod != true)
            {
                if(days > 30){
                    this.selectedPeriod = "month"
                }else{
                    this.selectedPeriod = "day"
                }
            }

            if(this.contenttype == "banner" && this.isCampaign)
            {
                this.contenttype = "banner_campaign";
                this.client = 0;
            }
            
            this.updateStats();
            
        }

        public open(id:number){
            if(id == 1){
                this.isOpen1 = !this.isOpen1;
            }
            else if(id == 2){
                this.isOpen2 = !this.isOpen2;
            }
        }

        setPeriod(){
            
            this.changeperiod=true;
                this.updateStats();
        }

        show(type){
            
            this.selectedType = type;

            switch (type) {
                case "adviews":                        
                    this.chartist.lineData.series = this.trafficData.adviews;          
                    break;
                case "adclicks":
                    this.chartist.lineData.series = this.trafficData.clicks;
                    break;
                case "pageviews":
                    this.chartist.lineData.series = this.trafficData.pageviews;
                    break;
                case "ctr":
                    this.chartist.lineData.series = this.trafficData.ctr;
                    break;                    
                default:
                    break;
            }            


        }

    }

    
