"use strict";

import { StateService } from "@uirouter/core";

export default class AboutUsComponent implements ng.IComponentOptions{
    
    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;
            

    constructor() {
        this.bindings = {                            
            result: '<',
            page:'<'
        };
        this.controllerAs = 'vm';
        this.controller = AboutUsComponentController;
        this.template = require("./aboutus.html");            
        
    }


}

export class AboutUsComponentController implements ng.IComponentController{

    static $inject = ["$state"];

    private result:Array<Object>;

    private contents:Array<Object>;

    private totalItems = 10000;

    private isLoading = true;

    private page;

    private maxSize = 5;
    
    constructor(private $state:StateService){}

    $onInit(){        
       
        
    }


    getData(page:number){
        this.$state.transitionTo(this.$state.$current.name,{page:page},{notify:false});
    }
}