import SessionService from "../../../services/Session.service";
import * as angular from "angular";
import {IPromise, resource} from "angular";
import IContentResourceClass from "../interfaces/IContentResourceClass.interface";

"use strict";

export interface IInsightsResponse {
    status: number,
    valid: boolean,
    message: string,
}

export default class FeedsService {
    static URL: string = "/api/insights/companies/:companyid/feeds/:id";
    static $inject: Array<string> = ['$http', "$resource", "SessionService"];
    private resource: IContentResourceClass<any>;


    constructor(
        private $http: angular.IHttpService,
        $resource: ng.resource.IResourceService,
        private session_service: SessionService
    ) {
        this.resource = <IContentResourceClass<any>>$resource(
            FeedsService.URL,
            {companyid: '@companyid', id: '@id'},
            {
                update: {method: 'PUT', params: {id: '@data.id'}},
                create: {method: 'POST'},
                patch: {method: 'PATCH'},
                query: {isArray: false, method: "GET"},
                delete: {method: 'DELETE'}
            });
    }


    public fetch(id: string): resource.IResource<any> {
        return this.resource.get({
            companyid: this.session_service.getActiveCompany().origid,
            id: id
        });
    }

    public fetchAll(type: string, limit = 10, skip = 0): resource.IResourceArray<any> {
        return this.resource.query({
            companyid: this.session_service.getActiveCompany().origid,
            skip: skip,
            limit: limit,
            type: type
        });
    }


    public update(id: string, content: Object): IPromise<Object> {
        const content_string = angular.toJson(content);
        content = angular.fromJson(content_string);
        return this.resource.update({
            companyid: this.session_service.getActiveCompany().origid,
            data: content,
            id: id
        }).$promise;
    }


    public patch(id: string, map: Object): IPromise<Object> {
        return this.resource.patch({
            companyid: this.session_service.getActiveCompany().origid,
            data: map,
            id: id
        }).$promise;
    }


    public create(content: Object, file: any = null, image: any = null): IPromise<Object> {
        const content_string = angular.toJson(content);
        content = angular.fromJson(content_string);
        return this.resource.create({
            companyid: this.session_service.getActiveCompany().origid,
            data: content
        }).$promise;
    }


    public delete(id: string): IPromise<Object> {
        return this.resource.delete({
            companyid: this.session_service.getActiveCompany().origid,
            id: id
        }).$promise;
    }


    public validateFeedUrl(url: string): angular.IHttpPromise<IInsightsResponse> {
        return this.$http.get(
            "/api/insights/validate-feed-url?feed_url=" + encodeURIComponent(url)
        )
    }
}