import { IScope } from "angular";
import { uniqBy } from "lodash";
import { IToasterService } from "ngtoaster";
import CompaniesService from "../../../../../services/companies.service";
import SessionService from "../../../../../services/Session.service";

("use strict");

export default class KeywordsComponent implements ng.IComponentOptions {
  public bindings: any;
  public controller: any;
  public controllerAs: string;
  public template: string;

  constructor() {
    this.bindings = {};
    this.controllerAs = "vm";
    this.controller = KeywordsComponentController;
    this.template = require("./keywords.html");
  }
}

export class KeywordsComponentController implements ng.IComponentController {
  static $inject = [
    "$translate",
    "$scope",
    "CompaniesService",
    "toaster",
    "SessionService",
  ];

  private result;
  private company;
  private isSaving = false;

  private keywords = [];
  private keywords_application = [];
  private maxKeywords = 30;
  private applications = [];

  constructor(
    private $translate: angular.translate.ITranslateService,
    private $scope: IScope,
    private CompaniesService: CompaniesService,
    private toaster: IToasterService,
    private SessionService: SessionService
  ) {}

  $onInit() {
    this.company = this.SessionService.getActiveCompany();
    const companies = this.SessionService.getCompanyentries().map(
      ({ application_id, application }) => ({ application_id, application })
    );

    this.applications = uniqBy(companies, "application_id");

    this.company.keywords.forEach((keyword) => {
      this.keywords.push({ text: keyword });
    });

    this.keywords_application = this.company?.keywords_application ?? [];

    //Wenn MaxKeywords erreicht, nichts mehr machen
    this.$scope.$watchCollection(
      "vm.keywords",
      (valueNew: any[], valueOld: any[]) => {
        if (valueNew.length > this.maxKeywords) {
          this.keywords = valueOld;
        }
      }
    );
    this.$scope.$watchCollection(
      "vm.keywords_application",
      (valueNew: any[], valueOld: any[]) => {
        if (valueNew.length > this.maxKeywords) {
          this.keywords_application = valueOld;
        }
      }
    );
  }

  save() {
    const newKeywords = this.keywords.map(({ text }) => text);
    const newKeywordsApplication = this.keywords_application.map(
      ({ application_id, keywords }) => ({
        application_id: application_id,
        keywords: keywords.map(({ text }) => text),
      })
    );
    this.isSaving = true;
    this.CompaniesService.updateKeywords(
      this.company.companyid,
      newKeywords,
      newKeywordsApplication
    )
      .then((company) => {
        this.company = company;

        this.SessionService.getActiveCompany().keywords = company.keywords;

        this.toaster.success(
          this.$translate.instant("Erfolgreich gespeichert!")
        );
      })
      .catch((error) => {
        this.toaster.error(
          this.$translate.instant("Es ist ein Fehler aufgetreten!")
        );
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  addKeywords() {
    this.keywords_application.push({ application_id: null, keywords: [] });
  }
}
