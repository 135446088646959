'use strict';
import {ISessionService} from "./Session.service";
import {StateService} from '@uirouter/core';
import * as angular from "angular";

export interface IAuthService {
    logIn(credentials: any): angular.IPromise<any>,
    logOut(): void,
    isLoggedIn(): boolean,
    sendPassword(credentials: any, lang: string): angular.IPromise<any>,
    changePassword(credentials: any): angular.IPromise<any>,
    checkRemoteSession(),
    setUniqueApiID(credentials: any): angular.IPromise<any>;
    updateCredentials(credentials: any)
}

export default class AuthService implements IAuthService {
    static $inject: Array<string> = ['$http', 'SessionService', '$state', '$q', "$window", "$rootScope", "OAuth", "OAuthToken", "NETWORK_ID", "$cookies"];

    constructor(
        private $http: angular.IHttpService, private SessionService: ISessionService,
        private $state: StateService, private $q: angular.IQService, private $window, private $rootScope,
        private OAuth: angular.oauth2.OAuth, private OAuthToken, private NETWORK_ID, private $cookies
    ) {
    }

    public logIn(credentials: any) {
        return this.OAuth.getAccessToken({username: credentials.email, password: credentials.pwd}).then((response) => {

            return this.$http.get("/api/insights/me").then((response) => {
                let data: any = response.data;
                this.SessionService.setUser(data.user);
                this.$window.uuid = data.user.gubid;
                //Gibt an ob zur Firmenauswahl gewechselt wird
                return data.user.agency || data.user.masteruser;
            });
        });
    }

    public logOut() {
        this.OAuthToken.removeToken();
        this.SessionService.destroy();
        this.$state.go("login");

        //Revoke muss im Backend noch implentiert werden
        /*return this.OAuth.revokeToken().then((response)=>{
            this.SessionService.destroy();
            this.$state.go("login");
        });*/
    }

    public isLoggedIn() {
        return this.SessionService.getUser() !== null;
    }

    public sendPassword(credentials, lang: string) {
        return this.$http.post("/api/insights/auth/sendpw?network_id=" + this.NETWORK_ID + "&lang=" + lang, credentials)
            .then((response) => {
            let data: any = response.data;
            return data.success;
        });
    }

    public changePassword(credentials) {

        return this.$http.post("/api/insights/auth/changepw?network_id=" + this.NETWORK_ID, credentials)
            .then((response) => {
            let data: any = response.data;
            return data.success;

        });
    }

    public setUniqueApiID(credentials) {

        return this.$http.post("/api/insights/auth/setuniqueApiID?network_id=" + this.NETWORK_ID, credentials)
            .then((response) => {
            let data: any = response.data;
            return data.success;

        });
    }

    public checkRemoteSession() {

        let deferred = this.$q.defer();

        if (this.OAuth.isAuthenticated()) {
            deferred.resolve();
        } else {
            deferred.reject({"status": 401});
        }
        return deferred.promise;


    }

    updateCredentials(credentials: any) {
        if (this.isLoggedIn()) {
            return this.$http.patch("/api/insights/me", credentials)
                .then((response) => {
                let data: any = response.data;
                this.SessionService.setUser(data.user);
                this.$window.uuid = data.user.gubid;
                return true;
            });
        } else {
            let deferred = this.$q.defer();
            deferred.resolve(false);
            return deferred.promise;
        }
    }

}