"use strict"
var angular = require('angular');

import AuthService from "./Auth.service";
import PasswordService from "./password.service";
import SessionService from "./Session.service";
import BannerService from "./banner.service";
import CampaignService from "./campaign.service";
import CompaniesService from "./companies.service";
import CompanyentryService from "./companyentries.service";
import ContentService from "./content.service";
import DashboardService from "./dashboard.service";
import FeedService from "./feed.service";
import LeadsService from "./leads.service";
import RegService from "./reg.service";
import StatisticsService from "./stats.service";
import FeedbackService from "../components/viFeedback/feedback.service";

angular.module("insights").service("AuthService", AuthService);
angular.module("insights").service("PasswordService", PasswordService);
angular.module("insights").service("SessionService", SessionService);
angular.module('insights').service('BannerService', BannerService);
angular.module('insights').service('CampaignService', CampaignService);
angular.module('insights').service('CompaniesService', CompaniesService);
angular.module('insights').service('CompanyentryService', CompanyentryService);
angular.module('insights').service('ContentService', ContentService);
angular.module('insights').service('DashboardService', DashboardService);
angular.module('insights').service('FeedService', FeedService);
angular.module('insights').service('LeadsService', LeadsService);
angular.module('insights').service('RegService', RegService);
angular.module('insights').service('StatisticsService', StatisticsService);
angular.module('insights').service('FeedbackService', FeedbackService);