"use strict";


var angular = require("angular");

import clientname from "./clientname.filter";
angular.module('insights').filter('clientname',clientname);

import percentage from "./percentage.filter";
angular.module('insights').filter('percentage',['$filter',percentage]);

import decimalpercentage from "./decimalpercentage.filter";
angular.module('insights').filter('decimalpercentage',['$filter',decimalpercentage]);

import capitalize from "./capitalize.filter";
angular.module('insights').filter('capitalize',capitalize);

import strreplace from "./strreplace.filter";
angular.module('insights').filter('strreplace',strreplace);

