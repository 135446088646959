"use strict";

export default class GeneralTabsComponent implements ng.IComponentOptions
{
    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;
            

    constructor() {
        this.bindings = {};
        this.controllerAs = 'vm';
        this.controller = TabsComponentController;
        this.template = require("./tabs.html");            
        
    }
}

class TabsComponentController implements ng.IComponentController
{
    private tabData = [
        {
            heading: 'Headerbilder',
            route:   'index.companyprofile.general.topimages'
        },
        {
            heading: 'Logos',
            route:   'index.companyprofile.general.logos'
        }
      ];
}

