"use strict";

import { translate, IFilterService } from "angular";

export default class ContactTabsComponent implements ng.IComponentOptions
{
    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;
            

    constructor() {
        this.bindings = {};
        this.controllerAs = 'vm';
        this.controller = TabsComponentController;
        this.template = require("./tabs.html");            
        
    }
}

class TabsComponentController implements ng.IComponentController
{
    static $inject = ["$filter"];
    constructor(private $filter:IFilterService){}

    private tabData = [];
    $onInit(){

        this.tabData = [
            {
                heading: this.$filter('translate')('Kontaktdaten'),
                route:   'index.companyprofile.contact.basedata'
            },
            {
                heading: this.$filter('translate')('Ansprechpartner'),
                route:   'index.companyprofile.contact.contactpersons'
            },
            {
                heading: this.$filter('translate')('Socialmedia'),
                route:   'index.companyprofile.contact.socialmedia'
            }
          ]
    }
    
}

