
    'use strict';

    import * as angular from "angular";
    import SessionService from "./Session.service";
    
    export interface IDashboardService {  
       
    }
    
    export default class DashboardService implements IDashboardService {
        private user;

        static $inject: Array<string> = ['SessionService'];
        
        constructor(SessionService:SessionService) {
            this.user = SessionService.getUser();
        }

       
    }


