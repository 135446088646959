

    'use strict';

    import {ILead,ILeadResult  } from "./leads.service";
    import { StateService } from "@uirouter/core";
    import * as angular from "angular";
    export interface ICampaignServiceResult{
        current:ICampaign[],
        expired:ICampaign[],
        count:number;
    }

    export interface ICampaign {
        title: string;
        description: string;
        ordernumber: string;
        campaigntype: string;
        campaignproduct: string;
        keywords: string[];

        datefrom: string;
        dateto: string;
        leadmax: number;
        leadmin: number;

        positions: IPosition[];

        company: string;
        fkcompanies: number;
        agency: string;
        fkagencies: number;

        advertorialclicks: number;
        advertorialcalls: number;

        exportconfig: string;

        id: string;
        modiuser: number;
        creauser: number;
        creadate: string;
        modidate: string;
        contenttype: string;

        deleted: boolean;


        adviews:number;
        pageviews:number;

        exported_leads:string[];
        exported_leads_logging: any[];

        leadcount:number;
        leads_loading:boolean;
        clicks_loading:boolean;

        last_update:number;
        leadcount_updated:number;
    }

    export interface IPosition {
        id: number;
        fkstatus: number;
        contentid: number;
        contenttype: string;
        application_id:number;
        dateto: string;
        datefrom: string;
        source: string;
        title: string;
        _id:string,
        adviews:number;
        pageviews:number;
        deleted:boolean;


    }

    export interface IPositionClient {
        title: string;
        pk: number;
    }


    export interface ICampaignService {
        getCampaigns(companyid: string,agencyid?): angular.IPromise<ICampaignServiceResult>;
        getAdvertorials(companyid: string): angular.IPromise<ICampaignServiceResult>;
        getCampaign(id: string): angular.IHttpPromise<ICampaign>;
        getClicks(id: string): angular.IHttpPromise<number>;
        markLeadsAsExported(campaignid:string,leads:ILead[]);
    }

    export default class CampaignService implements ICampaignService {

        static URL: string = "/api/insights/campaigns";
        static $inject: Array<string> = ['$http','$state'];
        constructor(private $http: angular.IHttpService,private $state:StateService) { }

        public getCampaigns(companyid: string,agencyid?:string): any {

           return this.$http.get(CampaignService.URL, { params: { cid: companyid} }).then((response)=>{
                 return response.data;
            });


        }

        public getAdvertorials(companyid: string,agencyid?:string): any {

           return this.$http.get(CampaignService.URL, { params: { cid: companyid ,product:"reach"} }).then((response)=>{
                 return response.data;
            });


        }

        public getCampaign(id: string,companyid?: string): angular.IHttpPromise<ICampaign> {
            return this.$http.get(CampaignService.URL + "/"+id);
        }


        public getClicks(id: string):angular.IHttpPromise<number>
        {
            return this.$http.get(CampaignService.URL+"/"+id+"/clicks");
        }

        /**
         * markLeadsAsExported
         *
         */
        public markLeadsAsExported(campaignid:string,leads:ILead[]) {

            let data:any =  {};

            data.lead_ids = [];
            data.user_ids = [];

            leads.forEach(element => {
                data.lead_ids.push(element.event_id);
                data.user_ids.push(element.user_id);
            });

            return this.$http.put(CampaignService.URL+"/"+campaignid+"/markleads",data);
        }




    }

  

