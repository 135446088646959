    import { IAuthService } from "./../../services/Auth.service";
    import { StateService } from "@uirouter/core";
    import * as angular from "angular";
    export default class ResendpasswordComponent implements ng.IComponentOptions {

        public bindings: any;
        public controller: any;
        public controllerAs:string;
        //public template: string;
        public template: string;

        constructor() {
            this.bindings = {};
            this.controllerAs= 'vm',
            this.controller = ResendpasswordComponentController;
            this.template = require("./resendpassword.html");
        }



    }

    interface IResendpasswordComponentController  {
        credentials:any;        
        sendPassword(): void;
        closeAlert(index:number):void;
        alerts:any[];
      
    }

    class ResendpasswordComponentController implements IResendpasswordComponentController{
        
        public credentials= {
            email:""
        };        

        alerts = [];

        sent = false;

        static $inject: Array<string> = ['AuthService','$log','$state',"$translate","$timeout","$uibModal"];        

        constructor(private AuthService: IAuthService,private $log: angular.ILogService,private $state:StateService,private $translate:angular.translate.ITranslateService,private $timeout:angular.ITimeoutService,private $uibModal) {}

        sendPassword():void{
                this.sent = true;
                 //Arrow Function, so dass man this an Controller gebunden wird
                this.AuthService.sendPassword(this.credentials,this.$translate.use()).then( 
                (response:any):any =>
                {  
                    if (response == true){
                        this.alerts.push({ type:"success",msg:this.$translate.instant('resendpassword_emailsend')});
                        this.$timeout(()=>{
                            this.alerts = [];
                            this.$state.go("login");
                        },5000);
                    }
                    else{
                        this.alerts.push({ type:"danger",msg:this.$translate.instant('resendpassword_error')});
                        this.sent = false;
                        this.$timeout(()=>{
                            this.alerts = [];
                        },5000);
                    }              
                   
                },
                (responseError:any):any =>
                {    
                   
                    this.alerts.push({ type:"danger",msg:this.$translate.instant('resendpassword_error')});
                    this.$timeout(()=>{
                        this.alerts = [];
                    },5000);
                    this.sent = false;
                }); 

           
        }

        closeAlert(index:number):void{
            this.alerts.splice(index,1);
        }

        
        openImprint(){
            this.$uibModal.open({
                controllerAs:'vm',
                  controller:ModalInstanceControl,
                  template: require('./../../components/common/imprint.html') 
            });
        }
         
         openContact(){
            this.$uibModal.open({
                  controllerAs:'vm',
                  controller:ModalInstanceControl,        
                  template: require('./../../components/common/contact.html') 
            });
        }



    }
     class ModalInstanceControl{
        static $inject: Array<string> = ["$uibModalInstance"]
        constructor(private $uibModalInstance:angular.ui.bootstrap.IModalServiceInstance){
        }

        close(){
            this.$uibModalInstance.dismiss();
        }
    }

   
