//Custom Variablen
import {ICompanyentryService} from "./services/companyentries.service";

declare var ga, adt, _gscq;


'use strict';

import AuthService, { IAuthService } from './services/Auth.service';
import SessionService, { ISessionService } from './services/Session.service';
import { StateService, TransitionService } from '@uirouter/core';
import * as angular from "angular";
import { OAuthTokenConfig } from './oauth.config';
import { IToasterService } from 'ngtoaster';
import {IHttpResponse} from "angular";

export interface IInsightsRootScopeService extends angular.IRootScopeService {
    Auth: IAuthService;
    Session: ISessionService;
    $state: StateService;
    locale: string;
    $translate: angular.translate.ITranslateService,
    feedbackSent: boolean;
}

export default class RunBlock {

    static $inject = ["$log", "$rootScope", "$state", "SessionService", "AuthService", "tmhDynamicLocale", "$translate", "$localStorage", "$window", "OAuth", "$transitions", "$interval", "OAuthToken", "NETWORK_ID","toaster","$sessionStorage","CompanyentryService"];

    constructor($log, $rootScope: IInsightsRootScopeService, $state: StateService, SessionService: SessionService, AuthService: IAuthService, tmhDynamicLocale, $translate: angular.translate.ITranslateService, $localStorage,
         $window, OAuth: angular.oauth2.OAuth, $transitions: TransitionService, $interval: angular.IIntervalService, OAuthToken, NETWORK_ID: number,toaster:IToasterService,$sessionStorage,CompanyentryService:ICompanyentryService) {

        $rootScope.$state = $state;
        $rootScope.feedbackSent = false;
        if ($localStorage.locale) {
            $rootScope.locale = <string>$localStorage.locale;
        }
        else if (NETWORK_ID == 1 || NETWORK_ID == 6 || NETWORK_ID == 8) {
            $rootScope.locale = "en";
        }
        else {
            let lang = $window.navigator.language || $window.navigator.userLanguage;
            if (lang.indexOf("en") > -1) {

                $rootScope.locale = "en";
            }
            else {
                $rootScope.locale = "de";
            }

        }
        $rootScope.$translate = $translate;

        $translate.use($rootScope.locale);

        $transitions.onBefore({ to: 'index.**' }, (trans) => {
            if (!OAuth.isAuthenticated() || SessionService.getUser() == null) {
                // User isn't authenticated. Redirect to a new Target State
                return trans.router.stateService.target('login');
            }

            //Beim Öffnen eines neuen Tabs ist der SessionStorage leer
            if(!$sessionStorage.active_company)
            {
                $sessionStorage.active_company = SessionService.getUserCompany();

                CompanyentryService.getCompanyentries( $sessionStorage.active_company.origid).then((success)=>{
                    SessionService.setCompanyentries(success.data);
                });

                if(SessionService.getUser().master ||SessionService.getUser().agency)
                {
                    return trans.router.stateService.target('index.usercompanies');
                }
                else
                {
                    return trans.router.stateService.target('index.dashboard');
                }
            }
        });

        $transitions.onFinish({ to: 'index.**' }, (trans) => {
            if(_gscq)
            {
                _gscq.push(['trackPage',$state.href(trans.targetState().name())]);
            }


        })

        if (OAuth.isAuthenticated()) {
            OAuth.getRefreshToken()
                .then((success) => {/*do nothing*/ },
                    (error:IHttpResponse<any>) => {
                        if(error.status === 400 || error.status === 403 || error.status === 401)
                        {
                            SessionService.destroy();
                            OAuthToken.removeToken();
                            $state.go('login');
                        }
                    });
        }
        //Alle 10 Minuten Refreshtoken holen
        $interval(() => {

            if (OAuth.isAuthenticated()) {
                OAuth.getRefreshToken()
                    .then((success) => {/*do nothing*/ },
                        (error:IHttpResponse<any>) => {
                            //Bei ServerFehler nicht sofort rauskicken
                            if(error.status === 400 || error.status === 403 || error.status === 401)
                            {
                                SessionService.destroy();
                                OAuthToken.removeToken();
                                $state.go('login');
                            }
                        });
            }

        }, 600000);

        $state.defaultErrorHandler((error) => {

            if (!error.sucess && error.detail.status) {
                switch (error.detail.status) {

                    case 500: {
                        toaster.error($translate.instant("Fehler"),$translate.instant('Es ist ein Fehler aufgetreten! Bitte versuchen Sie es erneut!'));
                        break;
                    }

                    case 403: {
                        $state.go("index.forbidden");
                        break;
                    }

                    case 404:
                    default:
                        {
                            $state.go("404");
                            break;
                        }



                }
            }

        });


        $rootScope.$on('$translateChangeSuccess', () => {
            tmhDynamicLocale.set($translate.use());
            $localStorage.locale = $translate.use();
        });



    }
}