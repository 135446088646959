"use strict"

import SessionService from "../../services/Session.service";
import AuthService from "../../services/Auth.service";
import {IToasterService} from "ngtoaster";
import {IPasswordService} from "../../services/password.service";

export default class AccountComponent implements ng.IComponentOptions {

    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;


    constructor() {
        this.bindings = {
            person: '<',
            id: '<'

        };
        this.controllerAs = 'vm';
        this.controller = ComponentController;
        this.template = require("./account.html");

    }

}

class ComponentController {

    public static $inject = [
        "SessionService",
        "AuthService",
        "PasswordService",
        "toaster",
        "$translate"
    ];
    public credentials: any = {};
    public salutations = [
        "Herr", "Frau"
    ];
    public isSaving: boolean = false;
    public messages: any = {
        email: {}
    }
    public passwordStrength = /^$|^(?=.*?[ÄÖÜA-Z])(?=.*?[äöüa-zß])(?=.*?[0-9])(?=.*?[@#$%^£\_*?!"§$%&/()=?+-.,\]\[\{\}]).{8,}$/;

    constructor(
        private SessionService: SessionService, private AuthService: AuthService, private PasswordService: IPasswordService,
        private toaster: IToasterService, private $translate: angular.translate.ITranslateService
    ) {

    }

    $onChanges() {
        let user = this.SessionService.getUser();

        this.credentials.firstname = user.firstname;
        this.credentials.lastname = user.lastname;
        this.credentials.salutation = user.salutation;
        this.credentials.email = user.email;
        this.credentials.password = this.credentials.password_repeat = "";
        this.credentials.phone = user.phone;
        this.credentials.country = user.country;

    }

    save() {
        this.messages.email = {};
        this.isSaving = true;
        this.AuthService.updateCredentials(this.credentials)
            .then((success) => {
                this.toaster.success(this.$translate.instant('Erfolgreich gespeichert!'));
            })
            .catch((reason) => {
                if (reason.status == 422) {
                    this.messages.email.callbackValue = "Email wird bereits verwendet";
                }
                this.toaster.error(this.$translate.instant('Es ist ein Fehler aufgetreten!'));
            })
            .finally(() => {
                this.isSaving = false;
            });

    }

    showPassword(input: any): any {
        this.PasswordService.showPassword(input);
    }

    showRequirements(input: any): any {
        this.PasswordService.showRequirements(input);
    }

}