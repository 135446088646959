    'use strict';

    import * as moment from 'moment';
    let now = moment().format('LLLL');
    import $ = require("jquery");
    import angular = require('angular');
import BannerService, { IBanner } from '../services/banner.service';
import SessionService from '../services/Session.service';
    

    export default class BannerController {
        static $inject: Array<string> = ['BannerService',"SessionService","$translate","DTOptionsBuilder","DTColumnBuilder","$filter","$q","$compile","$scope"];

       
        public bannerid:number=0;
        public activeLanguage:string= "";
       
       loading = false;
       
       dtOptions:any;
        dtColumns:any;
        dtInstance:any={};
        banner:IBanner[];

        banners:IBanner[];

        bannerstatobject:any=0;
        bookings = {
            options: ["current","expired"],
            selected: "current"
        };
        
       

        private getStats(object:any){
           
            this.bannerstatobject = object;
            this.bannerstatobject.dateto = moment().format('YYYY-MM-DD');
            return true;
        }

        private newStats(value) {
            angular.forEach(this.banners,(banner:any)=>{
                if(banner.id == value){
                    this.getStats(banner);
                    return;
                }
            });
        }

        public updateSelection(position, entities){
            angular.forEach(entities, (subscription, index) => {
                if (position != index) 
                subscription.checked = false;
            });
        }

        private getBanners(){
            this.loading = true;
            let banners_promise = this.BannerService.getbanners(this.SessionService.getActiveCompany().companyid,this.bookings.selected=="expired");                   
           
            return banners_promise.then((result:any) =>{
                
                let ARR_return = [];

                result.forEach(element => { 
                   
                    let obj:any = {};
                    var displayDate = new Date();
                    obj.adviews = element.adviews;
                    if(element.adviews > 0)
                    {
                        obj.ctr = element.pageviews / element.adviews * 100 ;
                    }
                    else
                    {
                        obj.ctr = 0;
                    }

                    if(this.$translate.use() == "de")
                    {
                        let start = element.title.indexOf("<de>");
                        let end = element.title.indexOf("</de>");
                        if(start > -1 && end > -1)
                        {
                            element.title = element.title.substring(start,end);
                        }

                         obj.title = (element.format!=null?element.format:" ") + (((element.format!=null&&element.bannerinfo_de!=null&&element.format!=""&&element.bannerinfo_de!=""))?" / ":" ") 
                         + (element.bannerinfo_de!=null?element.bannerinfo_de:" " ) +((element.title!=null&&element.title!="")?" / "+element.title:" ");
                         obj.title = obj.title + "<br/>Auftrags-Nr.: " +  element.sapposid;
                         if (element.datefrom > displayDate.toISOString())
                         {
                             obj.ctr = "-";
                             obj.adviews = '<i class="fa fa-asterisk" aria-hidden="true"></i> ' + " Auftrag noch nicht gestartet";
                         }
                    }
                    else
                    {
                        let start = element.title.indexOf("<en>");
                        let end = element.title.indexOf("</en>");
                        if(start > -1 && end > -1)
                        {
                            element.title = element.title.substring(start,end);
                        }
                         obj.title = (element.format!=null?element.format:" ") + (((element.format!=null&&element.bannerinfo_en!=null&&element.format!=""&&element.bannerinfo_en!=""))?" / ":" ") 
                         + (element.bannerinfo_en!=null?element.bannerinfo_en:" " ) +((element.title!=null&&element.title!="")?" / "+element.title:" ");
                         obj.title = obj.title + "<br/>Ordernumber: " +  element.sapposid;
                         if (element.datefrom > displayDate.toISOString())
                         {
                              obj.adviews = '<i class="fa  fa-asterisk" aria-hidden="true"></i> ' + " Order has not started yet";
                              obj.ctr = "-";
                         }
                    }              
                  
                    obj.datefrom_orig = element.datefrom;
                    obj.dateto_orig = element.dateto;
                    obj.datefrom = element.datefrom;                          
                    obj.dateto = element.dateto;
                    obj.adclicks = element.pageviews;                    
                    obj.clientname = element.application;
                    obj.fkclients = element.application_id;
                    if(obj.title.indexOf("Banner im Newsletter") > -1 && element.id.indexOf("_") < 0)
                    {
                        obj.id = element.id;
                    }
                    else
                    {
                        obj.id = element.sapposid;
                    }                    
                    obj.creadate = element.creadate;
                    obj.type = element.bookingtype;
                    if(obj.type !== "wcms_banner")
                    {
                        obj.type = "banner";
                    }
                    ARR_return.push(obj);
                   
                });

                this.banners = ARR_return;
                this.loading = false;
               return ARR_return; 

                
            });

        }       

        public updateData(){
                        
            this.dtInstance.changeData(this.getBanners());            

        }


        constructor(private BannerService: BannerService,private SessionService:SessionService, private $translate,private DTOptionsBuilder,private DTColumnBuilder ,private $filter,private $q,$compile,$scope) {
            this.activeLanguage = this.$translate.use();
            if(typeof this.activeLanguage == "undefined")
            {
                this.activeLanguage = "de";
            }
            this.banner = [];
            
            //this.newStats(this.bannerid);
            //Plugin "Utlimate Date / Time Sorting" konfigurieren

            //@ts-ignore
            $.fn.dataTable.moment("DD.MM.YYYY");

            this.dtColumns = [               
                    this.DTColumnBuilder.newColumn('select').renderWith((data,type,full)=>{

                        if (full.adviews!== null && typeof full.adviews == "string" && full.adviews.indexOf("<i") === 0){return ""}
                        else{
                            return `<div style="position:relative;"><input id="getbannerstats" icheck type="radio" ng-model="vm.getbannerstats"  ng-change="vm.newStats('${full.id}')"  value="${full.id}"/></div>`;
                        }
                    }).notSortable() ,
                    this.DTColumnBuilder.newColumn('title'),
                    this.DTColumnBuilder.newColumn('datefrom_orig').renderWith((data,type,full)=>{
                        return this.$filter("date")(data, "dd.MM.yyyy");

                    }),
                    this.DTColumnBuilder.newColumn('dateto_orig').renderWith((data,type,full)=>{
                        return this.$filter("date")(data, "dd.MM.yyyy");
                    }),
                    this.DTColumnBuilder.newColumn('adviews'),
                    this.DTColumnBuilder.newColumn('adclicks'),
                    
                    this.DTColumnBuilder.newColumn('ctr').renderWith((data,type,full)=>{
                         if (data=="-"){return ""}
                         else{
                         return  this.$filter("decimalpercentage")(data,2);
                         }
                    }),
                    this.DTColumnBuilder.newColumn('clientname').notVisible(),
                    this.DTColumnBuilder.newColumn('portal').renderWith((data,type,full)=>{

                            return '<span style="display:none">'+full.clientname+'</span><img class="pull-left" style="max-height:25px;" ng-src="assets/images/applications/'+((full.fkclients==0)?70:full.fkclients)+'/img/logo_white.svg" >';

                    })
                ];

                        
            this.dtOptions =  DTOptionsBuilder
            .fromFnPromise(this.getBanners())
            .withOption('createdRow', (row, data, dataIndex)=> {
                // Recompiling so we can bind Angular directive to the DT
                $compile(angular.element(row).contents())($scope);
            })
         
            .withDOM('<"html5buttons"B>lTfgitp')
            .withLanguageSource('assets/localization/dt-'+ this.activeLanguage+".json")
            .withButtons([
                {extend: 'copy'},
                {extend: 'csv',exportOptions: {columns: '.export'}},
                {extend: 'excel',exportOptions: {columns: '.export'}},
                {extend: 'pdf', title: 'Bannerbuchungen ',orientation:'landscape',
                exportOptions: {columns: '.export'}},

                {extend: 'print',
                    customize:(win) =>{
                        $(win.document.body).addClass('white-bg');
                        $(win.document.body).css('font-size', '10px');

                        $(win.document.body).find('table')
                            .addClass('compact')
                            .css('font-size', 'inherit');
                    }
                }
            ])
            .withOption('order', [2, 'desc']).withOption('lengthMenu', [25, 50, 100]);           
             
        }

    }









