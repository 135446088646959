import SessionService from "../../../services/Session.service";
import {  IPromise, angularFileUpload } from "angular";


"use strict";

export default class ImagesService {
    static URL: string = "/api/insights/companies/:companyid/images/:id";
    static $inject: Array<string> = ["Upload","SessionService","$http"];

    constructor(private Upload: angularFileUpload.IUploadService,private SessionService:SessionService,private $http:angular.IHttpService) { 
    
    }

    public upload(image:any,source:string="",comment:string="",type:string=""):angularFileUpload.IUploadPromise<any>{
            if(source === "")
            {
                source = this.SessionService.getActiveCompany().name;
            }
            return this.Upload.upload(
                {
                    url: "/api/insights/companies/"+this.SessionService.getActiveCompany().origid+"/images",
                    data: {image: image, name:image.name,source:source,type:type},
                    method:'POST'}
                );
       
    }



    public update(imageid:string,content:Object):IPromise<Object>
    {

        const content_string = angular.toJson(content);
        content = angular.fromJson(content_string);
        return this.$http.put("/api/insights/companies/"+this.SessionService.getActiveCompany().origid+"/images/"+imageid,content);

    }
    
    public delete(imageid:any):IPromise<any>{
        return this.$http.delete("/api/insights/companies/"+this.SessionService.getActiveCompany().origid+"/images/"+imageid);
    }

    public checkRatio(file)
    {
        return this.Upload.imageDimensions(file).then((dimensions)=>{

            let ratio = dimensions.width / dimensions.height;

            if(ratio < 1.7 || ratio > 1.8)
            {
                return "Bitte beachten Sie, dass wir auf der Startseite Bilder im Format 16:9 ausspielen. Andere Formate werden mit Weißraum aufgefüllt.";
            }
            return "";

        });
    }

    public changeImageSource(source, images)
    {
        if (images.length > 0 && images[0] !== null){
            images[0].source = source;
            return this.$http.put("/api/insights/companies/"+this.SessionService.getActiveCompany().origid+"/images/"+images[0]._id,images[0]);
        }
    }


}
