'use strict';
import {IRegService} from "./../../services/reg.service";
import {StateService} from "@uirouter/core";
import * as angular from "angular";
import {IToasterService} from "ngtoaster";
import $ = require("jquery");
import {IPasswordService} from "../../services/password.service";

export default class RegisterController {

    static $inject: Array<string> = [
        '$log',
        'RegService',
        "PasswordService",
        '$uibModal',
        '$translate',
        '$timeout',
        '$http',
        "$state",
        "NETWORK_ID",
        "toaster",
        "$filter",
        "NETWORK_CONFIG"
    ];

    constructor(
        private $log: ng.ILogService, private RegService: IRegService, private PasswordService: IPasswordService, private $uibModal,
        private $translate: angular.translate.ITranslateService, private $timeout: angular.ITimeoutService,
        private $http: ng.IHttpService, private $state: StateService, private NETWORK_ID: number,
        private toaster: IToasterService, private $filter: angular.IFilterService, private NETWORK_CONFIG
    ) {
        this.phonecodes();
        this.credentials.language = this.$translate.use();
    }

    dataLoading: boolean;
    setinfo_header: string;
    setinfo_email: string;
    messages: any;
    alerts: any[] = [];
    regsuccess = false;
    regfail = false;

    public credentials = {
        fksalutations: 0,
        firstname: "",
        lastname: "",
        email: "",
        tel1: "",
        tel2: "",
        tel3: "",
        origid: "",
        bookingid: "",
        ewe: 0,
        language: ""
    };

    public passwordStrength = /^(?=.*?[ÄÖÜA-Z])(?=.*?[äöüa-zß])(?=.*?[0-9])(?=.*?[@#$%^£\_*?!"§$%&/()=?+-.,\]\[\{\}]).{8,}$/;

    salutations = [
        {id: 1, name: 'reg_1_fksalutations'},
        {id: 2, name: 'reg_2_fksalutations'}
    ];

    telcountry: any;

    public phonecodes() {
        this.telcountry = require("./../../../assets/localization/phonecodes.json");
    }

    public register() {
        this.credentials.language = this.$translate.use();

        this.dataLoading = true;
        //Arrow Function, so dass man this an Controller gebunden wird
        this.RegService.register(this.credentials).then(
            (data: any): any => {
                this.dataLoading = true;

                if (data.success) {
                    if (data['forgotpwd']) {
                        this.$uibModal.open({
                            template: require("../register/registerforgotpwd.html"),
                            controllerAs: 'vm',
                            controller: RegisterFailedModalInstanceControl
                        });
                        this.dataLoading = false;
                    } else {
                        this.setinfo_header = this.$filter("translate")('reg_successtext_header');
                        this.setinfo_email = this.credentials.email;
                        $(".setinfo").show();
                        $(".origidform").hide();
                        this.regsuccess = true;
                        this.dataLoading = false;
                    }
                } else {
                    this.messages = data.messages;
                    this.alerts.push({type: "danger", msg: this.$filter("translate")('reg_error_text')});
                    this.$timeout(() => {
                        this.alerts = [];
                    }, 5000);
                    this.regfail = true;
                    this.dataLoading = false;
                }

            },
            (responseError: any): any => {
                this.alerts.push({type: "danger", msg: this.$filter("translate")('reg_error_text')});
                this.$timeout(() => {
                    this.alerts = [];
                }, 5000);
                this.regfail = true;
                this.dataLoading = false;
            });

    }


    openImprint() {
        this.$uibModal.open({
            controllerAs: 'vm',
            controller: ModalInstanceControl,
            template: require("./../../components/common/imprint.html"),
        });
    }

    openContact() {
        this.$uibModal.open({
            controllerAs: 'vm',
            controller: ModalInstanceControl,
            template: require("./../../components/common/contact.html"),
        });
    }

    closeAlert(index: number): void {
        this.alerts.splice(index, 1);
    }

    showPassword(input: any): any {
        this.PasswordService.showPassword(input);
    }

    showRequirements(input: any): any {
        this.PasswordService.showRequirements(input);
    }
}

class ModalInstanceControl {
    static $inject: Array<string> = ["$uibModalInstance"]

    constructor(private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance) {
    }

    close() {
        this.$uibModalInstance.dismiss();
    }
}

class RegisterFailedModalInstanceControl {
    static $inject: Array<string> = ["$uibModalInstance", "$state"]

    constructor(private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance, private $state: StateService) {
    }

    login() {
        this.$uibModalInstance.dismiss();
        this.$state.go('login');
    }

    password() {
        this.$uibModalInstance.dismiss();
        this.$state.go('forgot-password');
    }

    close() {
        this.$uibModalInstance.dismiss();
    }
}