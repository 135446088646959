import { IToasterService } from "ngtoaster";
import { translate, IScope } from "angular";
import LogosService from "../../../../../services/logos.service";
import ICompanyContentItemComponentController from "../../../../../interfaces/ICompanyContentItemComponentController";


"use strict";


export default class LogosItemComponent implements ng.IComponentOptions{
    
    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;
            

    constructor() {
        this.bindings = {                            
            content: '<'  ,
            onDelete:'&' ,
            onChange: '&'
        };
        this.controllerAs = 'vm';
        this.controller = ItemComponentController;
        this.template = require("./item.html");                    
    }


}

class ItemComponentController implements ng.IComponentController,ICompanyContentItemComponentController
{
    private content:any;

    private isDeleting:boolean =false;
    private isSaving:boolean =false;
    private dirty:boolean = false;

    static $inject = ["LogosService","toaster","$translate","$ngConfirm","$scope"]

    constructor(private LogosService:LogosService,private toaster:IToasterService,private $translate:translate.ITranslateService,private $ngConfirm,private $scope:IScope){
        this.$scope.$on("updatePublications",()=>{
            if(this.dirty)
            {
                this.setPublications();
            }            
        });

        this.$scope.$watch("vm.dirty",(newValue,oldValue)=>{
            if(newValue)
            {
                this.onChange();
            }            
        });
    }

    onDelete():void {}

    onChange(): void {}

    $onInit(){}

    setPublications()
    { 
        this.isSaving = true;
        this.LogosService.patch(this.content.id,{"positions":this.content.positions})
        .then(() => {
                this.toaster.success(this.$translate.instant("Speichern erfolgreich"));
                this.dirty = false;
            },() => {
                this.toaster.error(this.$translate.instant("Speichern fehlgeschlagen"));
            }).finally(()=>{
              this.isSaving = false;
        })
    }

    delete(){       
       
        

        this.$ngConfirm({
            title: this.$translate.instant("Löschen"),                
            autoClose: 'cancel|8000',
            content: this.$translate.instant("Inhalt wirklich löschen?"),
            type:"red",
            buttons: {
                delete: {
                    text: this.$translate.instant("Löschen"),
                    btnClass: 'btn-danger',
                    action: () => {
                        this.isDeleting = true;
                        this.LogosService.delete(this.content.id)
                        .then((sucess)=>{
                            this.toaster.success(this.$translate.instant("Löschen erfolgreich!"));
                            this.onDelete();
                            this.content = null;                           
                          
                        },
                        (error)=>{
                            this.toaster.error(this.$translate.instant("Löschen fehlgeschlagen!"));                           
                        }).finally(()=>{
                            this.isDeleting = false;
                        });
                        
                    }
                },
                cancel: {
                    text: this.$translate.instant("Abbrechen"),
                    action: () => {                 
                        this.isDeleting = false;                                  
                        return true;
                    }
                    
                }
            }
        });

           
        
        
    }

}