import { StateService } from "@uirouter/core";
import { resource } from "angular";
import CompanyentryService from "../../../../../../../services/companyentries.service";
import SessionService from "../../../../../../../services/Session.service";
import angular = require("angular");

"use strict";


export default class NewsArticlesComponent implements ng.IComponentOptions{
    
    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;
            

    constructor() {
        this.bindings = {                            
            page:'<'
        };
        this.controllerAs = 'vm';
        this.controller = ComponentController;
        this.template = require("./news.html");            
        
    }


}

class ComponentController implements ng.IComponentController{

    static $inject = ["$state","CompanyentryService","SessionService"];

    private page;

    private articles:any[]=[];

    private articlesLoading= true;

    constructor(private $state:StateService,private CompanyentryService:CompanyentryService, private SessionService:SessionService){}

    $onInit(){        
        this.CompanyentryService.getCompanyArticles(this.SessionService.getActiveCompany().origid,10,this.page* 10 -10).then((success:any)=>{
            this.articles = success.data.data;  
            angular.forEach(this.articles,(articles)=>{
                let articletitle = encodeURIComponent(articles.title.replace(/\s*\W/g,'-')).toLowerCase();
                articles.showonpageurl = articles.clienturl + '/'+articletitle+'-a-'+articles.id;
            });
        }).finally(()=>{
            this.articlesLoading = false;
        });
        
    }

    getData(page:number){
        this.$state.transitionTo(this.$state.$current.name,{page:page},{notify:false});
    }

}