
    'use strict';

    import * as angular from "angular";
     export interface IBanner
    {
        application_id: number;
        status: number;
        entrytype: string;
        application: string;
        fkentrytypes: number;
        datefrom: string;
        dateto: string;   

        clienturl:string;

        title:string;

        agencyid:string;
    }

   
    export interface IBannerService
    {
         getbanners(companyid:string,boo_expired?:boolean,boo_onlycount?:boolean):angular.IPromise<IBanner[]>;
        
    }

    export default class BannerService implements IBannerService 
    {        
        static URL:string = "/api/insights/companies/";
        static $inject: Array<string> = ['$http'];
        constructor(private $http: angular.IHttpService) {}

		public getbanners(companyid:string,boo_expired?:boolean,boo_onlycount?:boolean):angular.IPromise<IBanner[]>{
              return this.$http.get(BannerService.URL+companyid+'/orders',{params:{type:"Banner",active:!boo_expired?1:0,count:boo_onlycount?1:0}}).then((success:angular.IHttpResponse<IBanner[]>)=>{
                  return success.data;
              });
        }   

               
                 
    }

