import * as angular from "angular";
declare var __cmp:any;

export default class FooterComponent implements ng.IComponentOptions {

    public bindings: any;
    public controller: any;
    public controllerAs: string;
    //public template: string;
    public template: string;


    constructor() {
        this.bindings = {
            ibox: '<'
        };
        this.controllerAs = 'vm',
            this.controller = FooterComponentController;
        this.template = require("./footer.html")
    }
}


class FooterComponentController {

    date: Date;
    ibox: boolean;

    static $inject: Array<string> = ['$uibModal', "NETWORK_ID", "NETWORK_CONFIG"];

    constructor(private $uibModal: angular.ui.bootstrap.IModalService, private NETWORK_ID: number, private NETWORK_CONFIG: any) {
        this.date = new Date();
    }

    openImprint() {
        this.$uibModal.open({
            template: require("./../common/imprint.html"),
            controllerAs: 'vm',
            controller: ModalInstanceControl
        });
    }

    openContact() {
        this.$uibModal.open({
            controllerAs: 'vm',
            controller: ModalInstanceControl,
            template: require("./../common/contact.html"),
        });
    }

    openCmpSettings() {
        if (typeof __cmp != "undefined")
        {
            __cmp("showGDPRScreenVendors")
        }
    }

}

class ModalInstanceControl {
    static $inject: Array<string> = ["$uibModalInstance", "NETWORK_ID", "NETWORK_CONFIG"]

    constructor(private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance, private NETWORK_ID: number, private NETWORK_CONFIG: any) {
    }

    close() {
        this.$uibModalInstance.dismiss();
    }
}