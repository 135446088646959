import angular = require("angular");

"use strict"

export default class QConfig{
    static $inject = ["$qProvider"];

    constructor($qProvider:angular.IQProvider)
    {
         $qProvider.errorOnUnhandledRejections(false);
    }
}