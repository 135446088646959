    import { IAuthService } from "./../../services/Auth.service";
    import { ISessionService } from "./../../services/Session.service";
    import { StateService } from "@uirouter/core";
    import $ = require("jquery");
    import * as angular from "angular";
   export default class SetuniqueidComponent implements ng.IComponentOptions {

        public bindings: any;
        public controller: any;
        public controllerAs:string;
        //public template: string;
        public templateUrl: string;

        constructor() {
            this.bindings = {};
            this.controllerAs= 'vm',
            this.controller = SetuniqueidComponentController;
            this.templateUrl = 'app/login/setuniqueid/setuniqueid.html';
        }



    }

    interface ISetuniqueidComponentController  {
        credentials:any;        
        setuniqueid(): void;
        getuniqueid(): void;
        closeAlert(index:number):void;
        alerts:any[];
      
    }

    class SetuniqueidComponentController implements ISetuniqueidComponentController{
        
        public credentials= {
            uniqueid:"",
            pkglobalusers:0
        };        

        alerts = [];

        static $inject: Array<string> = ['AuthService','SessionService','$log','$state',"$translate","$timeout","$uibModal"];        

        constructor(private AuthService: IAuthService,private SessionService: ISessionService,private $log: angular.ILogService,private $state:StateService,private $translate:angular.translate.ITranslateService,private $timeout:angular.ITimeoutService,private $uibModal) { this.getuniqueid()}

        generateID():void{

            var id = '';
            var charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-abcdefghijklmnopqrstuvwxyz';
            var charSetSize = charSet.length;
            var charCount = 25;
            for (var i = 1; i <= charCount; i++) {
                var randPos = Math.floor(Math.random() * charSetSize);
                id += charSet[randPos];
            }           
             $('#uniqueid').val(id);         

        }

        getuniqueid():void{

            let uniqueid = this.SessionService.getUser().apiuniqueid; 
            if (uniqueid.length > 10)
            {
               this.credentials.uniqueid = uniqueid; 
            }
        }

        
        setuniqueid():void{
          
            this.credentials.uniqueid =  $('#uniqueid').val().toString();         
            if (this.credentials.uniqueid != "" )
            {
           
              this.credentials.pkglobalusers = this.SessionService.getUser().pkglobalusers;           
            
                 //Arrow Function, so dass man this an Controller gebunden wird
                this.AuthService.setUniqueApiID(this.credentials).then( 
                (response:any):any =>
                {  
                    if (response == true){
                        this.alerts.push({ type:"success",msg:this.$translate.instant('setuniqueid_success')});
                        this.SessionService.setUniqueid(this.credentials.uniqueid);                     
                    }
                    else{
                        this.alerts.push({ type:"notvalid",msg:this.$translate.instant('setuniqueid_error')});
                    }
                    this.$timeout(()=>{
                        this.alerts = [];
                      //  this.$state.go('login');
                    },5000);                 
                   
                },
                (responseError:any):any =>
                {    
                   
                    this.alerts.push({ type:"danger",msg:this.$translate.instant('setuniqueid_error')});
                    this.$timeout(()=>{
                        this.alerts = [];
                    },5000);
                }); 

           }   
        }

        closeAlert(index:number):void{
            this.alerts.splice(index,1);
        }

        
        openImprint(){
            this.$uibModal.open({
                controllerAs:'vm',
                  controller:ModalInstanceControl,
                  templateUrl: 'app/components/common/imprint.html'
            });
        }
         
         openContact(){
            this.$uibModal.open({
                  controllerAs:'vm',
                  controller:ModalInstanceControl,        
                  templateUrl: 'app/components/common/contact.html'
            });
        }



    }
     class ModalInstanceControl{
        static $inject: Array<string> = ["$uibModalInstance"]
        constructor(private $uibModalInstance:angular.ui.bootstrap.IModalServiceInstance){
        }

        close(){
            this.$uibModalInstance.dismiss();
        }
    }

   
