
    "use strict";
    import * as angular from "angular";
    
    export default class LanguageSelectDirective implements ng.IDirective {
        public link;

        public restrict: string ="A";
        public controller: any;
        public controllerAs:string;
        public scope: any;
        public template: string;

        constructor() {
            this.scope = {
                languages : "<"
            };
            this.controllerAs= 'vm',
            this.controller = LanguageSelectDirectiveController;
            this.template = `<a class="count-info" href uib-dropdown-toggle>
                                    <i class="fa fa-globe" aria-hidden="true"></i> {{'LANGUAGE' | translate}}
                            </a>
                            <ul uib-dropdown-menu class="animated fadeIn m-t-xs">
                                <li class="uib-dropdown-menu" ng-repeat="language in vm.languages"><a ng-click="vm.changeLanguage(language)"><img ng-src="assets/images/flags/{{language}}.png" style="padding-right: 5px;"> {{'LANG_'+language | translate}}</a></li>                                
                            </ul>
                             `;
        }

        static factory(): ng.IDirectiveFactory{
            let directive = () =>new LanguageSelectDirective();          
            return directive;
        }


    }

    
    interface ILanguageSelectDirectiveController  {
        changeLanguage(lang:string): void;
    }

    interface ILanguage{
        shortcut:string,
        title:string
    }

    interface ILanguageSelectScope extends angular.IScope{
        languages:ILanguage[];
    }

    class LanguageSelectDirectiveController implements ILanguageSelectDirectiveController{
        
        static $inject: Array<string> = ['$scope','$element','$translate','$log'];  

        languages:ILanguage[];      

        constructor(private $scope:ILanguageSelectScope,private $element:JQuery,private $translate: angular.translate.ITranslateService,private $log: angular.ILogService) {          
            this.languages = $scope.languages;          
        }

        changeLanguage(lang:string):void{            
            this.$translate.use(lang);           
        }

    }

   