
   

    export default function clientname (){
        return (clientname) => {
            if(clientname == 'Maschinenmarkt'){
                return "MM Maschinenmarkt";
            }
            if(clientname == "Blechnet"){
                return "blechnet"
            }

            return clientname;
        }
    };

