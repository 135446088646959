   'use strict';

    import CampaignService, {  ICampaignServiceResult, ICampaign} from "./../services/campaign.service";
import { IStatisticsService } from "../services/stats.service";
import SessionService from "../services/Session.service";
import { StateService } from "@uirouter/core";
import { IQService, translate } from "angular";
import { IToasterService } from "ngtoaster";
   import * as moment from 'moment';
    export default class AdvertorialsController {
        
        public pos:string[] = ["current","expired"];

        static $inject: Array<string> = ['CampaignService',"StatisticsService","SessionService","$state","$q","toaster","$translate"];
        clients: any;

        isLoading:boolean;
        campaigns:ICampaignServiceResult;

        constructor(CampaignService:CampaignService,private StatisticsService:IStatisticsService,SessionService:SessionService,$state:StateService,private $q:IQService,private toaster:IToasterService,private $translate:translate.ITranslateService) {
            //Logos der Kampagnen werden hier gesammelt,
            this.clients = {};
            this.isLoading = true;

            CampaignService.getAdvertorials(SessionService.getActiveCompany().companyid)
                .then((response:ICampaignServiceResult) => {

                  if(response.current.length == 1 && response.expired.length == 0){
                      $state.go("index.campaign."+response.current[0].campaignproduct,{id:response.current[0].id,hideOverview:true});
                      return( this.$q.reject( new Error( "goDetail" ) ) );
                  }
                  else
                  {
                    return response;
                  }
                }).then((result)=>
                {
                    this.campaigns = result;

                    //Clientids auslesen (aktuelle Kampagnen)
                    this.campaigns.current.forEach(campaign => {
                        this.clients[campaign.id] = [];
                        campaign.positions.forEach(position => {
                            //Wenn Clientid noch nicht im Array, dann hinzufügen
                            if(this.clients[campaign.id].indexOf(position.application_id) < 0){
                                this.clients[campaign.id].push(position.application_id);
                            }
                        });
                        campaign.clicks_loading = true;
                        let ARR_advertorials = [];
                        campaign.positions.forEach((position)=>{
                        if(position.contenttype == "advertorial" && !position.deleted)
                        {
                            ARR_advertorials.push(position._id);
                        }
                        });
                        this.StatisticsService.getTotalTrafficStatsByType(campaign.id,"campaign",0,campaign.datefrom,campaign.dateto,ARR_advertorials.join(",")).then((stats:any)=>{
                            campaign.clicks_loading = false;
                            campaign.pageviews = stats.data.metadata.pageviews_total;
                        });
                    });

                    //Clientids auslesen (abgelaufene Kampagnen)
                    this.campaigns.expired.forEach(campaign => {
                        this.clients[campaign.id] = [];
                        campaign.positions.forEach(position => {
                            //Wenn Clientid noch nicht im Array, dann hinzufügen
                            if(this.clients[campaign.id].indexOf(position.application_id) < 0){
                                this.clients[campaign.id].push(position.application_id);
                            }
                        });
                        campaign.clicks_loading = true;
                        let ARR_advertorials = [];
                        campaign.positions.forEach((position)=>{
                        if(position.contenttype == "advertorial" && !position.deleted)
                        {
                            ARR_advertorials.push(position._id);
                        }
                        });
                        this.StatisticsService.getTotalTrafficStatsByType(campaign.id,"campaign",0,campaign.datefrom,campaign.dateto,ARR_advertorials.join(",")).then((stats:any)=>{
                            campaign.clicks_loading = false;
                            campaign.pageviews = stats.data.metadata.pageviews_total;
                        });
                    });
                }).finally(()=>{
                    this.isLoading = false;
                }).catch((reason)=>{
                
                    if(reason.message == "goDetail")
                    {
                        //Do Nothing
                    }
                    else{
                        this.toaster.error(this.$translate.instant('Es ist ein Fehler aufgetreten!'))
                    }
                });

        
          
        }

    }

   
