

    import { IStatisticsService } from "./../../services/stats.service";
    import * as moment from 'moment';
    let now = moment().format('LLLL');
    import * as Chartist from 'chartist';
    import 'chartist-plugin-tooltips';
    require('chartist-plugin-tooltips/dist/chartist-plugin-tooltip.css');
    import 'chartist-plugin-fill-donut';
    import * as ngtoaster from "ngtoaster";
import angular = require("angular");
import CompanyentryService, { ICompanyentryService } from "../../services/companyentries.service";
import { arrayTuples } from "@uirouter/core";
    
    export default class CompanyentryStatsComponent implements ng.IComponentOptions {

        public bindings: any;
        public controller: any;
        public controllerAs: string;
        public template: string;
                


        constructor() {
            this.bindings = {                
                contentid: '<',
                client:'<',       
                datefrom:'<',
                dateto:'<' ,
                datefixed:'<',      
                origid: '<'
            };
            this.controllerAs = 'vm';
            this.controller = CompanyentryStatsComponentController;
            this.template = require("./companyentrystats.html");            
            
        }



    }

    export interface ICompanyentryStatsComponentBindings{
         contentid:string;
         contenttype:string;
         client:number;
         datefrom:string;
         dateto:string;
         datefixed:boolean;
         origid:string;
    }
   

    export class CompanyentryStatsComponentController{

        static $inject: Array<string> = ['StatisticsService', '$log',"$translate","toaster","CompanyentryService","$http","FileSaver","Blob"];
        
        
        contentid:string;
        contenttype:string;
        client:number;
        datefrom:string;
        dateto:string;
        datefixed:boolean;
        positions:string;
        campaignproduct:string;
        periods =  [{"value":"month"}, { "value":"day"}];
        changeperiod:boolean;

        history:any[] = [];
        origid:string;
        
        public hasBasic:boolean = false;

        public stats:any;
        public trafficData:any;
        
        public chartData = [[]];
        public chartLabels= [];
        public activeButton = 1;

        public adviews_total:number;
        public pageviews_total:number;
        public visits_total:number;
        public adviews_period:number;
        public pageviews_period:number;
        public visits_period:number;  
        public ctr_period:string;   
        public ctr_total:string; 
        
        public startDate:Date;
        public endDate:Date;
        
        public isOpen1 = false;
        public isOpen2 = false;

        public chartist:any={};

        private selectedType:string;

        selectedPeriod = "day";

        public trafficLoading = true;

        private arr_alphabet = ['a','b','c','d','e','f','g','h','i','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];

        constructor(private StatisticsService: IStatisticsService, private $log: angular.ILogService,private $translate:angular.translate.ITranslateService,private toaster:ngtoaster.IToasterService,private CompanyentryService: CompanyentryService, private $http:angular.IHttpService,private FileSaver,private Blob) {
            

             this.chartist.LineOptions = {
                 lineSmooth: false,
                height: 250      ,
               plugins: [
                    Chartist.plugins.tooltip()
                ]          
            }
            this.chartist.lineData = {};
            this.selectedType = "adviews";
            this.positions = "";            
           
        }

        public updateStats(){
            
            this.trafficLoading = true;

            let datefrom = moment(this.startDate).format("YYYY-MM-DD");
            let dateto = moment(this.endDate).format("YYYY-MM-DD");
            let duration = moment.duration(moment(this.endDate).diff(moment(this.startDate)));
            let days = duration.asDays();

            let responsePromise  = null;
            
            if (this.changeperiod != true)
            {
                if(days > 30){
                    this.selectedPeriod = "month"
                }else{
                    this.selectedPeriod = "day"
                }
            }

            responsePromise =  this.StatisticsService.getTrafficStatsByType(this.contentid,'company',this.client,datefrom,dateto,this.selectedPeriod,"");
            this.changeperiod = false;
            this.trafficData = {                   
                    adviews:[],
                    pageviews:[],
                    visits:[],
                    ctr:[]
            };        

            let index_basic = 0;

            //Ein Array für Basicbuchungen
            this.trafficData.adviews.push([]);
            this.trafficData.pageviews.push([]);
            this.trafficData.visits.push([]);
            this.trafficData.ctr.push([]);

            //Für jede Premiumbuchung eigenes Array anlegen (+ 10 ist ein Hack damit der Graph eine feste Farbe Gelb bekommt, die per CSS zugewiesen wird)
            for(let x = 1;x < this.history.length + 1; x++){
                this.trafficData.adviews.push([]);
                this.trafficData.pageviews.push([]);
                this.trafficData.visits.push([]);
                this.trafficData.ctr.push([]);
            }
            

            if(responsePromise){
                responsePromise.then((result)=>{
                    if(result.data.metadata && result.data.metadata.success){
 
                        this.stats = result.data.data;
                        
                        this.chartLabels = [];


                        angular.forEach(this.stats,(item)=>{
                            if(this.selectedPeriod == "month"){
                                this.chartLabels.push(moment(item.date).format('MM.YYYY'));
                            }
                            else{
                                this.chartLabels.push(moment(item.date).format('DD.MM.YYYY'));
                            }
                            let BOO_premiumentryFound = false;
                            for(let x = 0;x < this.history.length; x++){
                                //Index für Graph
                                let index_history = x +1 ;
                                let unit:moment.unitOfTime.DurationConstructor = "month";

                                if(this.selectedPeriod == "day")
                                {
                                    unit  = "day";
                                }
                                else if(this.selectedPeriod == "month")
                                {
                                    unit = "month";
                                }
                                else if(this.selectedPeriod == "year")
                                {
                                    unit = "year";
                                }
                                

                                if(
                                                                      
                                    //Addiert + 1 Einheit, damit Kurve nach links und rechts verlängert wird
                                     moment(item.date).isBetween(moment(this.history[x].datefrom).subtract(1,unit),moment(this.history[x].dateto).add(1,unit),unit, '(]')
                                     || 
                                     this.history[x].dateto === 0
                                ){
                                    
                                    this.trafficData.adviews[index_history].push(item.adviews);
                                    this.trafficData.pageviews[index_history].push(item.pageviews);
                                    this.trafficData.visits[index_history].push(item.visits);
                                    if(item.adviews > 0){
                                        this.trafficData.ctr[index_history].push(item.pageviews/item.adviews);
                                    }   
                                    else {
                                        this.trafficData.ctr[index_history].push(0);                   
                                    }

                                    //Nur wenn Premium direkt im Zeitraum liegt, Basiceintrag nicht einzeichnen (für durchgehende Linie)
                                    if(moment(item.date).isBetween(moment(this.history[x].datefrom),moment(this.history[x].dateto),unit, '(]'))
                                    {
                                        BOO_premiumentryFound = true;
                                    }
                                }
                                else
                                {
                                    this.trafficData.adviews[index_history].push(null);
                                    this.trafficData.pageviews[index_history].push(null);
                                    this.trafficData.visits[index_history].push(null);
                                    this.trafficData.ctr[index_history].push(null);                                                       
                                }
                            };

                            if(!BOO_premiumentryFound){
                                this.trafficData.adviews[index_basic].push(item.adviews);
                                this.trafficData.pageviews[index_basic].push(item.pageviews);
                                this.trafficData.visits[index_basic].push(item.visits);
                                if(item.adviews > 0){
                                    this.trafficData.ctr[index_basic].push(item.pageviews/item.adviews);
                                }   
                                else {
                                    this.trafficData.ctr[index_basic].push(0);                   
                                }
                            }
                            else
                            {
                                this.trafficData.adviews[index_basic].push(null);
                                this.trafficData.pageviews[index_basic].push(null);
                                this.trafficData.visits[index_basic].push(null);
                                this.trafficData.ctr[index_basic].push(null);                                                   
                            }
                           
                            
                        });

                        if(this.stats.length > 0)
                        {
                            this.adviews_period = this.stats[this.stats.length - 1].adviews_total;
                            this.pageviews_period = this.stats[this.stats.length - 1].pageviews_total;
                            this.visits_period = this.stats[this.stats.length - 1].visits_total;
                        }
                        else
                        {
                            this.adviews_period = 0;  
                            this.pageviews_period = 0;
                            this.visits_period = 0;
                        }

                        if(this.adviews_period > 0){
                            this.ctr_period = (this.pageviews_period /this.adviews_period * 100).toFixed(2) ;
                        }
                        else{
                            this.ctr_period = "0";
                        }

                        this.adviews_total = result.data.metadata.adviews_total;
                        this.pageviews_total = result.data.metadata.pageviews_total;
                        this.visits_total = result.data.metadata.visits_total;

                        if(this.adviews_total > 0){
                            this.ctr_total = (this.pageviews_total /this.adviews_total * 100).toFixed(2);
                        }
                        else{
                            this.ctr_total = "0";
                        }

                        //Labels eintragen
                        this.chartist.lineData.labels = this.chartLabels;

                        //View aktualisieren
                        this.show(this.selectedType);
                    }
                    else{
                         this.toaster.pop('error',this.$translate.instant('stats_error_title'),this.$translate.instant('stats_error_text'));
                    }
                   
                },
                (error)=>{
                        this.toaster.pop('error',this.$translate.instant('stats_error_title'),this.$translate.instant('stats_error_text'));
                }).finally(()=>{
                    this.trafficLoading = false;
                });
            }
                                    
        }

        $onInit() {
            if(typeof this.datefixed == 'undefined'){
                this.datefixed = false;
            }


        }; 
        
        $onChanges(){


         
            if(this.datefrom)
            {
                this.startDate = moment(this.datefrom).toDate();
            }
            else
            {
                this.startDate = moment().subtract(3,"months").toDate();
            }
            if(this.dateto && moment().isAfter(this.dateto))
            {
                this.endDate = moment(this.dateto).toDate();
                
            }
            else
            {
                this.endDate = moment().toDate();
            }

            this.CompanyentryService.getHistory(this.origid,this.client).then((result)=>{
                result.sort(function(a,b)  {
                    return a.datefrom - b.datefrom 
                });
                result.forEach(element => {                  
                        this.history.push(element);
                });


                this.updateStats();
                this.show(this.selectedType);        
            });   
            

        }

        public open(id:number){
            if(id == 1){
                this.isOpen1 = !this.isOpen1;
            }
            else if(id == 2){
                this.isOpen2 = !this.isOpen2;
            }
        }

        setPeriod(){
            
            this.changeperiod=true;
                this.updateStats();
        }

        show(type){
            
            this.selectedType = type;

            switch (type) {
                case "adviews":                        
                    this.chartist.lineData.series = this.trafficData.adviews;          
                    break;
                case "adclicks":                
                     this.chartist.lineData.series = this.trafficData.pageviews;
                    break;
                case "ctr":
                    this.chartist.lineData.series = this.trafficData.ctr;
                    break;                    
                default:
                    break;
            }            


        }

        getExcel(){
            let datefrom = moment(this.startDate).format('YYYY-MM-DD');
            let dateto = moment(this.endDate).format('YYYY-MM-DD');
            this.$http.get("/api/insights/stats",{params:{type:"company",history:true,output:"excel",application:this.client,id:this.contentid,start_date:datefrom,end_date:dateto,interval:"month",lang:this.$translate.use()},responseType: "arraybuffer"})
                .then((success)=>{
                    this.openSaveAsDialog(success.headers("X-Filename"), success.data, success.headers("Content-Type"));
                },
                    (error)=>{
                        this.toaster.pop('error',this.$translate.instant('stats_error_title'),this.$translate.instant('stats_error_text'));
                    });
        }

        getPdf(){

            let datefrom = moment(this.startDate).format('YYYY-MM-DD');
            let dateto = moment(this.endDate).format('YYYY-MM-DD');
            this.$http.get("/api/insights/stats",{params:{type:"company",history:true,output:"pdf",application:this.client,id:this.contentid,start_date:datefrom,end_date:dateto,interval:"month",lang:this.$translate.use()},responseType: "arraybuffer"})
                .then((success)=>{
                    this.openSaveAsDialog(success.headers("X-Filename"), success.data, success.headers("Content-Type"));
                },
                    (error) => {
                        this.toaster.pop('error',this.$translate.instant('stats_error_title'),this.$translate.instant('stats_error_text'));
                    });

        }

        openSaveAsDialog(filename, content, mediaType) {

            var blob = new Blob([content], {type: mediaType});
            //Kommt aus File-Saver Bibliothek
            this.FileSaver.saveAs(blob, filename);

        }
    }

  
