import { resource, IScope, IWindowService, translate } from "angular";
import angular = require("angular");
import { TransitionService, Transition } from "@uirouter/core";
import {ContentTypes} from "../contact/socialmedia/contentTypes";

"use strict";


export default class ContentlistingComponent implements ng.IComponentOptions{
    
    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;


    constructor() {
        this.bindings = {                            
            result: '<',         
            maxSize:'<',
            page:'<',
            onPageChange:'&'
        };
        this.controllerAs = 'vm';
        this.controller = ContentlistingComponentController;
        this.template = require("./contentlisting.html");            
        
    }


}

export class ContentlistingComponentController implements ng.IComponentController{
    contents:any[];
    isLoading:boolean=true;
    
    totalItems:number = 1000;

    maxSize:number;

    page:number = 1;

    dirty:boolean = false;

    result:resource.IResource<any>;

    public onPageChange:(any) => any;

    static $inject = ["$scope","$transitions","$translate","$ngConfirm","$q","$rootScope"]
    private socialMediaTypes: string[] = [];
    constructor(private $scope:IScope,private $transitions:TransitionService,private $translate:translate.ITranslateService,private $ngConfirm,private $q:angular.IQService,private $rootScope){}

    $onInit()
    {
        this.socialMediaTypes = Object.keys(ContentTypes).map(v => ContentTypes[v as keyof typeof ContentTypes].toLowerCase());

        if(typeof this.maxSize == "undefined")
        {
            this.maxSize = 10;
        }   
        
        this.$transitions.onStart({},(transition:Transition):any=>{
            if(this.dirty)
            {
                return this.showWarning().promise;
            }
            return true;
        });


    }

    $onChanges()
    {   this.isLoading = true;
        this.result.$promise.then((result)=>{
            this.contents = result.data;
            this.totalItems = result.total_count;
            this.isLoading = false;
        });
    }

    updateData()
    {  
        this.onPageChange({page:this.page});                      
    }

   save(){
        this.$scope.$broadcast("updatePublications");
        this.dirty = false;
   }

   showWarning()
   {       
       let promise = this.$q.defer();
        this.$ngConfirm({
            title: this.$translate.instant("Achtung"),                      
            content: this.$translate.instant("Änderungen verwerfen?"),
            type:"yellow",
            buttons: {
                ok: {
                    text: this.$translate.instant("Ok"),
                    btnClass: 'btn-primary',
                    action: () => {          
                        this.dirty = false;
                        promise.resolve();                                               
                    }
                },
                cancel: {
                    text: this.$translate.instant("Abbrechen"),
                    action: () => {                                                             
                        promise.reject();
                        //ui-router-tabs still listens to old stateEvents, emit event for updating tabs
                        this.$rootScope.$broadcast('$stateChangeCancel');
                        return true;
                    }
                    
                }
            }
        });
        return promise;
   }

   isSocialMedia(contentType: string) {
       return this.socialMediaTypes.includes(contentType.toLowerCase());
   }
    
}