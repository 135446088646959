import { IToasterService } from "ngtoaster";
import { translate, IScope } from "angular";
import ProductsService from "../../../../services/products.service";
import ICompanyContentItemComponentController from "../../../../interfaces/ICompanyContentItemComponentController";
import { StateService } from "@uirouter/core";

"use strict";


export default class ProductsItemComponent implements ng.IComponentOptions{
    
    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;
            

    constructor() {
        this.bindings = {                            
            content: '<',            
            onDelete: '&',
            onChange: '&'
        };
        this.controllerAs = 'vm';
        this.controller = ProductsItemComponentController;
        this.template = require("./item.html");                    
    }


}

class ProductsItemComponentController implements ng.IComponentController,ICompanyContentItemComponentController
{
    private content:any;

    static $inject = ["ProductsService","toaster","$translate","$ngConfirm","$scope","$state"];

    private isDeleting:boolean = false;

    private isSaving:boolean = false;

    private dirty:boolean = false;

    constructor(private ProductsService:ProductsService,private toaster:IToasterService,private $translate:translate.ITranslateService,private $ngConfirm,private $scope:IScope,private $state:StateService){
        this.$scope.$on("updatePublications",()=>{           
            if(this.dirty)
            {
                this.setPublications();
            }
        });
        this.$scope.$watch("vm.dirty",(newValue,oldValue)=>{
            if(newValue)
            {
                this.onChange();
            }            
        });
    }

    $onInit(){}

    onDelete(){}

    onChange(){}

    setPublications()
    { 
        this.isSaving = true;
        this.ProductsService.patch(this.content.id,{"positions":this.content.positions})
        .then(() => {
                this.toaster.success(this.$translate.instant("Speichern erfolgreich"));
                this.dirty = false;
            },() => {
                this.toaster.error(this.$translate.instant("Speichern fehlgeschlagen"));
            }).finally(()=>{
                this.isSaving = false;
        })
    }

    delete(){       
       
        

        this.$ngConfirm({
            title: this.$translate.instant("Löschen"),                
            autoClose: 'cancel|8000',
            content: this.$translate.instant("Inhalt wirklich löschen?"),
            type:"red",
            buttons: {
                delete: {
                    text: this.$translate.instant("Löschen"),
                    btnClass: 'btn-danger',
                    action: () => {
                        this.isDeleting = true;
                        this.ProductsService.delete(this.content.id)
                        .then((sucess)=>{
                            this.toaster.success(this.$translate.instant("Löschen erfolgreich!"));
                            this.onDelete();
                            this.content = null;                           
                          
                        },
                        (error)=>{
                            this.toaster.error(this.$translate.instant("Löschen fehlgeschlagen!"));                           
                        }).finally(()=>{
                            this.isDeleting = false;
                        });
                        
                    }
                },
                cancel: {
                    text: this.$translate.instant("Abbrechen"),
                    action: () => {                 
                        this.isDeleting = false;                                  
                        return true;
                    }
                    
                }
            }
        });

           
        
        
    }

    goDetail()
    {
        this.isSaving = true;
        this.$state.go("index.companyprofile."+this.content.contenttype+".detail",{id:this.content.id});
    }
}