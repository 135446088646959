"use strict";

import * as angular from "angular";
import RouterConfig from "./profile.route";

require("./../../common.module");

let profile = angular.module(
    "insights.profile",
    [
        "insights.common"
    ]);

require("./components/index");
require("./services/index");

profile.config(RouterConfig)