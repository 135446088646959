import { StateService } from "@uirouter/core";
import SessionService from "../../services/Session.service";

export default class NavigationComponent implements ng.IComponentOptions{

    public bindings: any;
    public controller: any;
    public controllerAs:string;
    //public template: string;
    public template: string;
    

    constructor() {
        this.bindings = {};
        this.controllerAs= 'vm',
        this.controller = NavigationComponentController;
        this.template = require("./navigation.html")
    }
}

class NavigationComponentController implements ng.IComponentController
{
    static $inject = ["$state","PRODUCTION","SessionService","NETWORK_ID","NETWORK_CONFIG"];

    private showProfile = false;
    $state:StateService;
    constructor($state:StateService,PRODUCTION,SessionService:SessionService,private NETWORK_ID:number,private NETWORK_CONFIG:string)
    {
        this.$state = $state;
        
        this.showProfile =true;        
        
    }

}