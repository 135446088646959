    'use strict';

    import * as angular from "angular";
import { ISessionService } from "./Session.service";
    export interface ICompanyentry
    {
        application_id: number;
        fkstatus: number;
        entrytype: string;
        application: string;
        datefrom: string;
        dateto: string;   
        companyid:number;

        name:string;
    }

    export interface ICompanyrequests
    {
        form:{
            email:string;
            emailto:string;
            name:string;
            subject:string;
            text:string;
            elements:any[]
        };
        company_id:string;
        timestamp:Date;
        active: boolean;
        _id: number;
    }

    export interface ICompanyarticles
    {
        fkarticles: number;
        fkcompanies: number;
        imgdir: string;
        topline: string;
        title: string;
        link: string;
        datefrom: string;
        dateto: string;
        fkclients: number;
        clienturl:string;
    }

    export interface ICompanyrequestCounter
    {
        application_id:number;
        count:number;
    }

   
    export interface ICompanyentryService
    {
         getCompanyentries(companyid:string):angular.IHttpPromise<ICompanyentry[]>;
         getCompanyentry(companyid:string,application:number):angular.IHttpPromise<ICompanyentry>;
         getCompanyRequests(companyid:string,clientid:number,active?:boolean,count?:number,page?:number):angular.IHttpPromise<any>;
         getCompanyArticles(companyid:string,count?:number,skip?:number):angular.IHttpPromise<ICompanyarticles[]>;
         archiveCompanyRequest(companyid:string,id:number):angular.IHttpPromise<any>;
         getCompanyentriesCount(companyid:string):angular.IPromise<number>;
         getCompanyrequestsCount(companyid:string,application_id?:number,active?:boolean):angular.IPromise<number>;
         getCompanyrequestsByClient(companyid:string):angular.IPromise<ICompanyrequestCounter[]>;
         getHistory(companyid:string,clientid:number);

    }

    export default class CompanyentryService implements ICompanyentryService 
    {        
        static URL:string = "/api/insights/companies/";
        static $inject: Array<string> = ['$http','$q',"SessionService"];
        constructor(private $http: angular.IHttpService,private $q:angular.IQService,private SessionService:ISessionService) {}

        public getCompanyentries(companyid:string):angular.IHttpPromise<ICompanyentry[]>{
              return this.$http.get(CompanyentryService.URL + companyid + '/orders',{params:{type:"Firmenprofil",active:true}});
        }    
        
        public getCompanyentry(companyid:string,application:number):angular.IHttpPromise<ICompanyentry>{
              return this.$http.get(CompanyentryService.URL + companyid +'/orders',{params:{application_id:application,active:true,type:"Firmenprofil"}}).then((response:angular.IHttpResponse<any>)=>{
                  let ARR_entries:ICompanyentry[] = response.data;
                  let entry = response.data[0];
                  if(ARR_entries.length > 0)
                  {
                    //Wenn möglich Premium zurückliefern (Basic und Premium können gleichzeitig aktiv sein)
                    ARR_entries.some(element => {
                     
                        if(element.entrytype == 'Business' || element.entrytype == 'Premium'){
                            entry = element;
                            return true;
                        }
                        return false;
                    });
                  }
                  return entry;
              });
        }   

        public getCompanyRequests(companyid:string,clientid:number,active=true,count=10,page=1):angular.IHttpPromise<any>{
              return this.$http.get(CompanyentryService.URL + companyid + '/requests',{params:{id:companyid,application_id:clientid,active:active,limit:count,skip:page*count - count}});
        }     

        public getCompanyArticles(companyid:string,count=10,skip=0):angular.IHttpPromise<ICompanyarticles[]>{
              return this.$http.get("/api/insights/companynews",{params:{companyid:companyid,count:count}});
        } 

        public getCompanyentriesCount(companyid:string):angular.IPromise<any>{
              return this.$http.get(CompanyentryService.URL + companyid + '/orders',{params:{type:"Firmenprofil",entrytypes:"Premium"}}).then((success:any)=> {
                  return success.data.length;
              });
        }     

        public getCompanyrequestsCount(companyid:string,application_id=0,active=true):angular.IPromise<number>{
            return this.$http.get(CompanyentryService.URL + companyid + '/requests',{params:{application_id:application_id,active:active,limit:0}}).then((success:any)=> {
                return success.data.total_count;
            });
        } 
        
        public archiveCompanyRequest(companyid:string,id:number):angular.IHttpPromise<any>{
              return this.$http.patch(CompanyentryService.URL + companyid + '/requests/'+id,{active:false});
        } 

        public getCompanyrequestsByClient(companyid:string):angular.IPromise<ICompanyrequestCounter[]>{
            
            let arr_clients= [];
            let promises = [];
            this.SessionService.getCompanyentries().forEach((companyentry)=>{
                if(companyentry.entrytype == "Premium")
                {
                    let obj = {
                      application_id:companyentry.application_id,
                      count:0
                    };
                    promises.push(this.getCompanyrequestsCount(companyid,companyentry.application_id,true).then((result:any) => {
                        if(result>0)
                        {
                            obj.count = result;
                            arr_clients.push(obj);
                        }

                    }));
                }
            });
            return this.$q.all(promises).then(()=>{
                return arr_clients;
            });
        } 

        public getHistory(companyid:string,clientid:number):angular.IPromise<any>{
            return this.$http.get(CompanyentryService.URL + companyid +'/orders',{params:{application_id:clientid,type:"Firmenprofil",entrytypes:"Business,Premium"}}).then((result)=>{
                  return result.data;
              });
        }
        
    }


