
    'use strict';

    import { ICampaign, ICampaignService } from "./../services/campaign.service";
    import { ISessionService } from "./../services/Session.service";
    import { IFeedService } from "./../services/feed.service";
    import CompanyentryService, { ICompanyentryService } from "./../services/companyentries.service";
    import {StateService} from '@uirouter/core';
    import * as angular from 'angular';
    import { IBannerService, IBanner } from "../services/banner.service";
    import {IWindowService} from "angular";

    declare var gsc:any;

    export default class DashboardController {
        
      

       dashboard:any;
       user:any;
       companyentries: any;
       companyarticles: any;
       companyrequests:any;
       banner:any;
       campaign:ICampaign[];
       reachadvertorials:ICampaign[];
       bookings:number;
       infoboxLoading:boolean = true;
       companyarticlesLoading:boolean = true;
       feeds:any = "";
     


       static $inject: Array<string> = ['CampaignService','BannerService','CompanyentryService','SessionService',"$translate","$q",'FeedService',"$scope","NETWORK_ID","$window"];
        constructor(CampaignService:ICampaignService,BannerService:IBannerService,CompanyentryService: CompanyentryService,private SessionService:ISessionService,
            private $translate:angular.translate.ITranslateService,private $q:angular.IQService,private FeedService:IFeedService,public $scope: angular.IScope,private NETWORK_ID:number,private $window:IWindowService)
            
            {
            let activeCompany = this.SessionService.getActiveCompany();
            this.user = this.SessionService.getUser();          
            this.bookings = 0;

            this.getb2bfeed(); 

            let promises:angular.IPromise<any>[] = [];        
            
            let p1 = <angular.IPromise<any>>CampaignService.getCampaigns(activeCompany.companyid).then(
                (result) => {
                    this.campaign = result.current;                    
                   
                }
            );
            
            promises.push(p1);


             // ich brauche nur aktuell
            let p2 =BannerService.getbanners(this.SessionService.getActiveCompany().companyid,false,true).then(
                (result:IBanner[]) => {
                    this.banner = 0;
                    result.forEach(element => {
                        if(element.status != 1)
                        {
                            this.banner++;
                        }
                    });
                    
                }
            );
            promises.push(p2);
            
            this.companyentries = 0;
            let applications = [];
            SessionService.getCompanyentries().forEach((entry)=>{                
                //Doppelbuchungen (Zukunftsbuchungen) ignorieren
                if(entry.entrytype == "Premium" && applications.indexOf(entry.application_id) < 0)
                {                    
                    applications.push(entry.application_id);
                    this.companyentries++;
                }
            });            

            let p4 = <angular.IPromise<any>>CampaignService.getAdvertorials(activeCompany.companyid).then(
                (result) => {
                    this.reachadvertorials = result.current;                                       
                }
            );

            
            promises.push(p4);

            this.$q.all(promises).then((success)=>{
                this.bookings = this.banner + this.companyentries;
            },
            (error)=>{

            })
            .finally(()=>{
                this.infoboxLoading = false;
            });
            
            CompanyentryService.getCompanyArticles(activeCompany.companyid,10).then(
                (result:any) => {

                    this.companyarticles = result.data.data;
                    angular.forEach(this.companyarticles,(articles)=>{
                        let articletitle = encodeURIComponent(articles.title.replace(/\s*\W/g,'-')).toLowerCase();
                        articles.showonpageurl = articles.clienturl + '/'+articletitle+'-a-'+articles.id;
                    });
                }
            ).finally(()=>{
                this.companyarticlesLoading = false;
            });          


            CompanyentryService.getCompanyrequestsCount(activeCompany.companyid).then(
                (result) => {
                    this.companyrequests = result;
                }
            );    

           
        }

        getb2bfeed(){
            if(this.NETWORK_ID === 6 || this.NETWORK_ID === 8)
            {
                this.feeds = "";
            }
            else
            {
                this.$translate("dashboard_feedb2bmarketing").then((translation)=> {

                        if (translation != "" )
                        {
                            this.FeedService.getb2bfeed(translation).then((result)  => {
                                this.feeds = result.data.channel;
                            });
                        }
                        else{
                            this.feeds = "";
                        }
                    },
                ).catch((reason)=> {
                    this.feeds = "";
                })
            }

            
            
        }

            

    }