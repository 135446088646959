
    import * as angular from "angular";
    export interface IFeedbackMessage{
        text:string;
        rating:number;
    }
    export interface IFeedbackService {
        sendFeedback(message:IFeedbackMessage):angular.IHttpPromise<any>;
    }
    export default class FeedbackService implements IFeedbackService {
        static $inject: Array<string> = ['$http'];
        constructor(private $http: angular.IHttpService) {}

        sendFeedback(message){
            return this.$http.post("/api/insights/feedback",message);
        }
    }

