export enum ContentTypes {
    FACEBOOK = "facebook",
    TWITTER = "Twitter",
    XING = "XING",
    YOUTUBE = "youtube",
    LINKEDIN = "linkedin",
    INSTAGRAM = "Instagram",
    TIKTOK = "TikTok",
    PINTEREST = "Pinterest",
    WECHAT = "WeChat",
    SNAPCHAT = "Snapchat",
    KUNUNU = "Kununu"
}