import FeedsService from "../../../services/feeds.service";
import {IToasterService} from "ngtoaster";
import {translate} from "angular";
import {ISessionService} from "../../../../../services/Session.service";

"use strict";

export default class FeedComponent implements ng.IComponentOptions {

    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;


    constructor() {
        this.bindings = {
            feed: "<",
            id: "<",
            onDelete: "&"
        };
        this.controllerAs = "vm";
        this.controller = FeedComponentController;
        this.template = require("./feed.html");
    }
}


class FeedComponentController {

    private id;
    private feed;
    private dirty: boolean = false;
    private invalid_feed_url: boolean = false;
    private isSaving = false;
    private isDeleting = false;


    public onDelete: () => any;

    static $inject = ["FeedsService", "toaster", "$translate", "SessionService", "$ngConfirm"]

    constructor(
        private feeds_service: FeedsService,
        private toaster: IToasterService,
        private $translate: translate.ITranslateService,
        private session_service: ISessionService,
        private $ngConfirm
    ) {
    }

    publish() {
        this.isSaving = true;
        this.feeds_service.validateFeedUrl(this.feed.description).then(
            (response) => {
                let insights_response = response.data;
                if (insights_response.status === 200) {
                    this.invalid_feed_url = false;
                    if (this.id !== "new") {
                        this.feeds_service.update(this.id, this.feed).then((success) => {
                                this.toaster.success(this.$translate.instant("Speichern erfolgreich."));
                                this.feed = success;
                            },
                            (error) => {
                                this.toaster.error(this.$translate.instant("Speichern fehlgeschlagen."));
                            }).finally(() => {
                            this.isSaving = false;
                            this.dirty = true;
                        });
                    } else {
                        this.feed.companyid = this.session_service.getActiveCompany().companyid;
                        this.feed.status = 5;
                        this.feed.published = true;

                        this.feeds_service.create(this.feed).then((success) => {
                                this.feed = success;
                                this.toaster.success(this.$translate.instant("oMessages.sSuccessfullySaved"));
                            },
                            (error) => {
                                this.toaster.error(this.$translate.instant("oErrors.sSavingFailed"));
                            }).finally(() => {
                            this.isSaving = false;
                            this.dirty = true;
                        });
                    }
                } else {
                    this.invalid_feed_url = true;
                    this.toaster.error(this.$translate.instant("oErrors.sSavingInvalidFeedUrl"));
                    this.isSaving = false;
                    this.dirty = true;
                }
            },
            (error) => {
                this.toaster.error(this.$translate.instant("oErrors.sSavingFailed"));
                this.invalid_feed_url = true;
                this.isSaving = false;
                this.dirty = true;
            }).finally(() => {
        });
    };

    delete() {
        this.isDeleting = true;

        if (this.id == "new") {
            this.onDelete();
            this.isDeleting = false;
        } else {
            this.$ngConfirm({
                title: this.$translate.instant("delete"),
                autoClose: "cancel|8000",
                content: this.$translate.instant("oMessages.sDeleteContent"),
                type: "red",
                buttons: {
                    delete: {
                        text: this.$translate.instant("delete"),
                        btnClass: "btn-danger",
                        action: () => {
                            this.feeds_service.delete(this.feed.id).then((success) => {
                                this.toaster.success(this.$translate.instant("oMessages.sDeleteSuccessful"));
                                this.onDelete();
                                this.isDeleting = false;
                                this.feed = null;
                            });

                        }
                    },
                    cancel: {
                        text: this.$translate.instant("abort"),
                        action: () => {
                            this.isDeleting = false;
                            return true;
                        }

                    }
                }
            });
        }
    }
}