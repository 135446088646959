
    "use strict";

    import { IPosition,ICampaign,ICampaignServiceResult,ICampaignService } from "./../services/campaign.service";
    import {IStatisticsService} from './../services/stats.service';
    import SessionService from "../services/Session.service";
    import { StateService } from "@uirouter/core";
    import { IQService, translate } from "angular";
    import { IToasterService } from "ngtoaster";
        
    export default class CampaignsController {
        static $inject: Array<string> = ['CampaignService','StatisticsService',"SessionService","$state","$q","toaster","$translate"];

        pos = ["current","expired"];
        isLoading:boolean;

        campaigns:ICampaignServiceResult;
        clients: any;
        constructor(private  CampaignService:ICampaignService,private StatisticsService:IStatisticsService,SessionService:SessionService,$state:StateService,private $q:IQService,private toaster:IToasterService,private $translate:translate.ITranslateService) {
            this.isLoading = true;
            CampaignService.getCampaigns(SessionService.getActiveCompany().companyid,null)
            .then( (response:ICampaignServiceResult) => {

                if(response.count == 1)
                {
                    if(response.current.length == 1)
                    {
                        $state.go("index.campaign."+response.current[0].campaignproduct,{id:response.current[0].id,hideOverview:true});
                        return( this.$q.reject( new Error( "goDetail" ) ) );
                    }
                    else if(response.expired.length == 1)
                    {
                        $state.go("index.campaign."+response.expired[0].campaignproduct,{id:response.expired[0].id,hideOverview:true});
                        return( this.$q.reject( new Error( "goDetail" ) ) );
                    }
                    else
                    {
                    //sollte nicht passieren
                        return response;
                    }
                    
                }
                else
                {
                return response;
                }
            }).
            then((result)=>
            {
                this.campaigns = <ICampaignServiceResult>result;
                //Logos der Kampagnen werden hier gesammelt,
                this.clients = {};

                //Clientids auslesen (aktuelle Kampagnen)
                this.campaigns.current.forEach(campaign => {
                    this.clients[campaign.id] = [];
                    campaign.positions.forEach(position => {
                        //Wenn Clientid noch nicht im Array, dann hinzufügen
                        if(position.application_id !== 51 && position.application_id && this.clients[campaign.id].indexOf(position.application_id) < 0){
                            this.clients[campaign.id].push(position.application_id);
                        }
                    });
                    if(campaign.campaignproduct != "transfer" && campaign.campaignproduct != "reach")
                    {
                        campaign.leads_loading = false;
                        campaign.last_update = campaign.leadcount_updated;

                    }
                    if(campaign.campaignproduct == "transfer")
                    {
                        campaign.clicks_loading = true;
                        let ARR_advertorials = [];
                        campaign.positions.forEach((position)=>{
                            if(position.contenttype == "advertorial" && !position.deleted)
                            {
                                ARR_advertorials.push(position._id);
                            }
                        });
                        this.StatisticsService.getTotalTrafficStatsByType(campaign.id,"campaign",0,campaign.datefrom,campaign.dateto,ARR_advertorials.join(",")).then((stats:any)=>{
                            campaign.clicks_loading = false;
                            campaign.advertorialclicks = stats.data.metadata.clicks_total;
                        });
                    }

                });

                //Clientids auslesen (abgelaufene Kampagnen)
                this.campaigns.expired.forEach(campaign => {
                    this.clients[campaign.id] = [];
                    campaign.positions.forEach(position => {

                        //Wenn Clientid noch nicht im Array, dann hinzufügen
                        if(position.application_id !== 51 && position.application_id && this.clients[campaign.id].indexOf(position.application_id) < 0){
                            this.clients[campaign.id].push(position.application_id);
                        }
                    });
                    campaign.leads_loading =false;
                    if(campaign.campaignproduct == "transfer")
                    {
                        campaign.clicks_loading = true;
                        let ARR_advertorials = [];
                        campaign.positions.forEach((position)=>{
                            if(position.contenttype == "advertorial" && !position.deleted)
                            {
                                ARR_advertorials.push(position._id);
                            }
                        });
                        this.StatisticsService.getTotalTrafficStatsByType(campaign.id,"campaign",0,campaign.datefrom,campaign.dateto,ARR_advertorials.join(",")).then((stats:any)=>{
                            campaign.clicks_loading = false;
                            campaign.advertorialclicks = stats.data.metadata.clicks_total;
                        });
                    }
                });
            })
            .finally(()=>{
                this.isLoading = false;
            }).catch((reason)=>{
                
                if(reason.message == "goDetail")
                {
                    //Do Nothing
                }
                else{
                    this.toaster.error(this.$translate.instant('Es ist ein Fehler aufgetreten!'))
                }
            });

           
            
        }     
    }


