"use strict";

import { IFilterService } from "angular";

export default class NewsTabsComponent implements ng.IComponentOptions
{
    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;
            

    constructor() {
        this.bindings = {};
        this.controllerAs = 'vm';
        this.controller = TabsComponentController;
        this.template = require("./tabs.html");            
        
    }
}

class TabsComponentController implements ng.IComponentController
{

    static $inject = ["$filter"];
    constructor(private $filter:IFilterService){}
    private tabData = [];
    $onInit(){

        this.tabData = [
            {
                heading: this.$filter('translate')('Nachrichten'),
                route:   'index.companyprofile.contents.news'
            },
            {
                heading: this.$filter('translate')('RSS-Feeds'),
                route:  'index.companyprofile.contents.feeds'
            },
            {
                heading: this.$filter('translate')('Redaktionelle Berichterstattung'),
                route:    'index.companyprofile.contents.articles'
            }
        ]
    }
}

