
import { IToasterService } from "ngtoaster";
import { translate } from "angular";
import { ISessionService } from "../../../../../services/Session.service";
import JobwareService from "../../../services/jobware.service";

"use strict";

export default class JobwareComponent implements ng.IComponentOptions
{

    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;


    constructor() {
        this.bindings = {
            jobwareconfig: '<',
            id: '<',
            onDelete:'&'
            

        };
        this.controllerAs = 'vm';
        this.controller = ComponentController;
        this.template = require("./jobware.html");

    }
}


class ComponentController
{

    private id;
    private jobwareconfig;

    private dirty:boolean = false;
    private isSaving = false;

    private isDeleting = false;

    public onDelete:() => any;

    static $inject = ["JobwareService","toaster","$translate","SessionService","$ngConfirm"]

    constructor(private JobwareService:JobwareService,private toaster:IToasterService,private $translate:translate.ITranslateService,private SessionService:ISessionService,private $ngConfirm){

    }

    publish(){
        this.isSaving =true;
        if(this.id !== "new")
        {
            this.JobwareService.update(this.jobwareconfig).then((success) => {
                    this.toaster.success(this.$translate.instant("Speichern erfolgreich!"));
                    this.jobwareconfig = success;
                },
                (error) => {
                    this.toaster.error(this.$translate.instant("Speichern fehlgeschlagen!"));
                }).
            finally(()=>{
                this.isSaving = false;
                this.dirty = false;
            });
        }
        else
        {
            this.jobwareconfig.companyid = this.SessionService.getActiveCompany().companyid;
            this.jobwareconfig.status = 5;
            this.jobwareconfig.published = true;

            this.JobwareService.create(this.jobwareconfig).then((success)=>{
                this.jobwareconfig = success;
                this.toaster.success(this.$translate.instant("Speichern erfolgreich!"));
            },
            (error)=>{
                this.toaster.error(this.$translate.instant("Speichern fehlgeschlagen!"));
            }).finally(()=>{
                this.isSaving = false;
                this.dirty = false;
            });
        }
        
    };

    delete(){       
        this.isDeleting = true;
      
        if(this.id == "new")
        {           

            this.onDelete();
            this.isDeleting = false;
        }
        else
        {
            this.$ngConfirm({
                title: this.$translate.instant("Löschen"),                
                autoClose: 'cancel|8000',
                content: this.$translate.instant("Inhalt wirklich löschen?"),
                type:"red",
                buttons: {
                    delete: {
                        text: this.$translate.instant("Löschen"),
                        btnClass: 'btn-danger',
                        action: () => {
                            this.JobwareService.delete(this.jobwareconfig._id).then((sucess) => {
                                this.toaster.success(this.$translate.instant("Löschen erfolgreich!"));
                                this.onDelete();
                                this.isDeleting = false;
                                this.jobwareconfig = null;
                            });
                           
                        }
                    },
                    cancel: {
                        text: this.$translate.instant("Abbrechen"),
                        action: () => {                            
                            this.isDeleting = false;                          
                            return true;
                        }
                       
                    }
                }
            });

           
        }
        
    }


}