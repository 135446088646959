

    export class QuillConfig {
        static $inject = ["ngQuillConfigProvider"];        
        
        constructor( ngQuillConfigProvider){
           ngQuillConfigProvider.set({
            /*
             * @NOTE: this config/output is not localizable.
             */
            modules: {
              toolbar: [
                ['bold', 'italic', 'underline'],        // toggled buttons
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['clean'],                                         // remove formatting button          
                ['link']                         // link and image, video
              ]
            },
            theme: 'snow',
            debug: 'warn',
            placeholder: '',
            readOnly: false,
            bounds: document.body,
            scrollContainer: null,
            formats : [
              'bold',
              'italic',            
              'underline',            
              'list',
              'link'
            ]
          });
        }
    }    
