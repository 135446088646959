
    'use strict';

    import {IPosition,ICampaign} from './../../services/campaign.service';
    import {ILead,ILeadResult,ILeadsService} from './../../services/leads.service';
    import angular = require('angular');
    import Chartist = require('chartist');
    import { Transition } from '@uirouter/core';
    
    import * as moment from 'moment';
    let now = moment().format('LLLL');
    import $ = require("jquery");

    interface IPositionSelectable extends IPosition{
        selected:boolean;
    }
    export default class LeadGeneratorController {
        static $inject: Array<string> = ["campaign","leadresult","LeadsService","$translate","$transition$"];


        campaign:ICampaign;
        leads:ILead[];
        leadsFiltered:ILead[]=[];
        headers:string[];
        chartist:any;
        dtOptions:any;
        goalReached:boolean=false;
        hideOverview:boolean=false;
        selectedPositionsLeads:any[] = [];
        leadselect:string="new";

        constructor(campaign:ICampaign,leadresult:ILeadResult,private LeadsService:ILeadsService,$translate:angular.translate.ITranslateService,$transition$:Transition) {
            //Plugin "Utlimate Date / Time Sorting" konfigurieren
            //@ts-ignore
            $.fn.dataTable.moment("DD.MM.YYYY");
            
            this.campaign = campaign;
            this.leads = leadresult.data;
            this.headers = leadresult.headers;
            this.hideOverview = $transition$.params().hideOverview;
            //Leadziel berechnen
            let percent = this.leads.length / campaign.leadmin * 100;
            let percentRounded = Math.round(percent);
            if(percentRounded > 100){
                percentRounded = 100;
            }

            if(percentRounded == 100){
                this.goalReached = true;
            }


            angular.forEach(this.campaign.positions,(position)=>{
                  (<IPositionSelectable>position).selected = true;
            });

            this.chartist = {
                data:{
                    series:[percentRounded,100-percentRounded]
                },
                options:{
                    donut: true,
                    donutWidth: 40,
                    startAngle: 270,
                    height:500,
                    total:200,
                    showLabel: false,
                    plugins: [
                        Chartist.plugins.fillDonut({
                            items: [{
                                content: '<h1 style="font-weight:bold">'+this.leads.length+'</h1><h1 style="font-weight:bold">Leads</h1>',
                                offsetY:-5
                            }]
                        })
                    ]
                }
            };

            this.filterLeads();

        }

        updateData(){
            let arr_selected = [];
            angular.forEach(this.campaign.positions,(position)=>{
                  if((<IPositionSelectable>position).selected){
                      arr_selected.push(position._id);
                  }
            });

            if(arr_selected.length == 0){
                arr_selected.push("no_selection");
            }

            this.selectedPositionsLeads  = arr_selected;

            this.LeadsService.getLeadsByCampaign(this.campaign.id,arr_selected.join()).then((result)=>{

                    this.leads = result.data.data;
                    this.headers = result.data.headers;
                    this.filterLeads();

            });

        }

        filterLeads()
        {
            if(this.leadselect == "all")
            {
                this.leadsFiltered = this.leads;
            }
            else if(this.leadselect == "archive")
            {
                this.leadsFiltered = [];

                this.leads.forEach(lead => {

                    if(lead.exported_leads_date !== null)
                    {
                        this.leadsFiltered.push(lead);
                    }
                });

            }
            else if(this.leadselect == "new")
            {
                this.leadsFiltered = [];
                this.leads.forEach(lead =>
                {
                    if(lead.exported_leads_date === null)
                    {
                        this.leadsFiltered.push(lead);

                    }



                });
            }


        }
    }



