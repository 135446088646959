import { ICompanyentry } from "../../../../../services/companyentries.service";
import { StateService } from "@uirouter/core";

"use strict";

export default class EventsComponent implements ng.IComponentOptions{
    
    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;
            
    constructor() {
        this.bindings = {                            
            result: '<',
            page :'<'
        };
        this.controllerAs = 'vm';
        this.controller = EventsComponentController;
        this.template = require("./events.html");            
        
    }


}
export class EventsComponentController implements ng.IComponentController{

    static $inject = ["$state"];

    private result:Array<Object>;
    private page;

    private maxSize = 5;
    
    constructor(private $state:StateService){}

    $onInit(){        
        
       
    }

    getData(page:number){
        this.$state.transitionTo(this.$state.$current.name,{page:page},{notify:false});
    }
}