    import {IAuthService} from "./../../services/Auth.service";
    import { ISessionService } from "../../services/Session.service";

    import '@uirouter/angularjs';
    import 'angular-ui-bootstrap';
    import {IScope} from "angular";

    export default class UsermenuComponent implements ng.IComponentOptions{

        public bindings: any;
        public controller: any;
        public controllerAs:string;
        //public template: string;
        public template: string;

        constructor() {
            this.bindings = {
                user: '<',
                active_company : '<'
            };
            this.controllerAs= 'vm';
            this.controller = UsermenuComponentController;
            this.template = require("./usermenu.html");
        }
    }


    class UsermenuComponentController{

        user:any;
        active_company:any;
        
        static $inject: Array<string> = ['AuthService',"SessionService","$scope"];

        constructor(private AuthService: IAuthService,private SessionService: ISessionService,private $scope:IScope){}

        $onInit(){       
           this.user = this.SessionService.getUser();
           this.active_company = this.SessionService.getActiveCompany();
           this.$scope.$on("company-changed",(event,args)=>{
               this.active_company = this.SessionService.getActiveCompany();
           });
        }   

        $onChanges(changesObjects){
            this.user = this.SessionService.getUser();
            this.active_company = this.SessionService.getActiveCompany();
        }
    }

    
