import { ICompanyentry } from "../../../../../services/companyentries.service";
import { StateService } from "@uirouter/core";

"use strict";

export default class VideosComponent implements ng.IComponentOptions{
    
    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;
            
    constructor() {
        this.bindings = {                            
            result: '<',
            page :'<'
        };
        this.controllerAs = 'vm';
        this.controller = VideosComponentController;
        this.template = require("./videos.html");            
        
    }


}
export class VideosComponentController implements ng.IComponentController{

    static $inject = ["$state"];

    private result;

    private page;
    
    constructor(private $state:StateService){}

    $onInit(){        
    }

    getData(page:number){
        this.$state.transitionTo(this.$state.$current.name,{page:page},{notify:false});
    }


}