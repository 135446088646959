export default class TopnavbarComponent implements ng.IComponentOptions{

    public bindings: any;
    public controller: any;
    public controllerAs:string;
    //public template: string;
    public template: string;
    

    constructor() {
        this.bindings = {};
        this.controllerAs= 'vm',
        this.controller = function(){};
        this.template = require("./topnavbar.html")
    }
}