"use strict"
    import * as angular from "angular";
    
    export interface IStatisticsService {
        getLeadsStatsByCampaign(campaignid: string, datefrom:string, dateto:string): angular.IHttpPromise<any>,
        getLeadsStatsByDownload(contentid: string,contenttype:string,client:number ,datefrom:string, dateto:string): angular.IHttpPromise<any>
        getTrafficStatsByType(contentid: string,contenttype:string,client:number ,datefrom:string, dateto:string,groupBy?:string,positions?:string,product?:string): angular.IHttpPromise<any>
        getTotalTrafficStatsByType(contentid: string,contenttype:string,client:number ,datefrom:string, dateto:string,groupBy?:string,positions?:string): angular.IHttpPromise<any>
        getBannerStats(contentid: string,client:number ,datefrom:string, dateto:string,groupBy?:string): angular.IHttpPromise<any>
    }
    export default class StatisticsService implements IStatisticsService {
        static $inject: Array<string> = ['$http'];
        static URL = '/api/insights/stats';
        constructor(private $http: angular.IHttpService) { }
        public getLeadsStatsByCampaign(campaignid: string, datefrom = "", dateto = ""): angular.IHttpPromise<any> {
            return this.$http.get(StatisticsService.URL + 'leads/', { params: { id: campaignid, datefrom: datefrom, dateto: dateto,by:'campaign'} });
        }
        public getLeadsStatsByDownload(contentid: string,contenttype:string,client:number , datefrom = "", dateto = ""): angular.IHttpPromise<any> {
            return this.$http.get(StatisticsService.URL + 'leads/', { params: { id: contentid,contenttype:contenttype,client:client, datefrom: datefrom, dateto: dateto,by:'download' } });
        }
        public getTrafficStatsByType(contentid: string,contenttype:string,client:number , datefrom = "", dateto = "",groupBy="day",positions="",product=""): angular.IHttpPromise<any> {
            return this.$http.get(StatisticsService.URL, { params: { id: contentid,type:contenttype,application:client, start_date: datefrom, end_date: dateto,interval:groupBy ,positions:positions,history:true,product:product } });
        }
        public getTotalTrafficStatsByType(contentid: string,contenttype:string,client:number , datefrom = "", dateto = "",positions=""): angular.IHttpPromise<any> {
            return this.$http.get(StatisticsService.URL, { params: { id: contentid,type:contenttype,application:client, start_date: datefrom, end_date: dateto,positions:positions,history:false } });
        }
        public getBannerStats(contentid: string,client:number , datefrom = "", dateto = "",groupBy="day"): angular.IHttpPromise<any> {
            return this.$http.get(StatisticsService.URL +'banner', { params: { id: contentid,client:client, datefrom: datefrom, dateto: dateto,groupby:groupBy } });
        }

    }

   