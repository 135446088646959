import * as angular from 'angular';

import CompanyentryService, {ICompanyentryService} from "./../../services/companyentries.service";
import SessionService, {ISessionService} from "./../../services/Session.service";
import {IAuthService} from "./../../services/Auth.service";

export default class CompanyentriesController {
    static $inject: Array<string> = ['CompanyentryService', 'SessionService', '$log', 'AuthService'];
    companyentries: any;


    constructor(private CompanyentryService: CompanyentryService, private SessionService: SessionService, private $log: angular.ILogService, private AuthService: IAuthService) {
        let activeCompany = SessionService.getActiveCompany();
        let lowerencCompanyname = encodeURIComponent(activeCompany.name.replace(/\s*\W/g, '-')).toLowerCase();

        let applications = [];

        let companyentries: any = {};
        companyentries.basic = [];
        companyentries.premium = [];
        let orders = SessionService.getCompanyentries();
        //Zuerst Premium, dann basic, damit bei Doppelbuchungen Premium bevorzugt wird
        orders.forEach((entry) => {
            if (applications.indexOf(entry.application_id) == -1) {
                if (entry.entrytype == "Business" || entry.entrytype == "Premium") {
                    companyentries.premium.push(entry);
                    applications.push(entry.application_id);
                }

            }

        });

        //Zuerst Premium, dann basic, damit bei Doppelbuchungen Premium bevorzugt wird
        orders.forEach((entry) => {
            if (applications.indexOf(entry.application_id) == -1) {
                if (entry.entrytype == "Basic") {
                    companyentries.basic.push(entry);
                    applications.push(entry.application_id);
                }

            }

        });


        companyentries.premium.forEach((companyentry) => {


            companyentry.profileurl = companyentry.clienturl + '/' + lowerencCompanyname + '-c-' + activeCompany.id;

            companyentry.requests_loading = true;
            this.CompanyentryService.getCompanyrequestsCount(activeCompany.companyid, companyentry.application_id, true).then((count) => {
                companyentry.activerequests = count;
                companyentry.requests_loading = false;
            });


        });

        companyentries.basic.forEach((companyentry) => {

            companyentry.profileurl = companyentry.clienturl + '/' + lowerencCompanyname + '-c-' + activeCompany.id;

            var arrclients_en = [38, 43, 53, 67, 69, 79];
            var arrclients_fr = [34];
            if (arrclients_en.indexOf(companyentry.application_id) != -1) {
                companyentry.bookingurl = 'mailto:sales@vogel.de?subject=Upgrade%20company%20profile&body=Dear%20Mr%20Wahler%2C%0A%0AI%20am%20interested%20in%20a%20Company%20Profile%20Premium%2C%20please%20send%20me%20more%20information.';
            } else if (arrclients_fr.indexOf(companyentry.application_id) != -1) {
                companyentry.bookingurl = 'mailto:sales@vogel.de?subject=Mise%20%C3%A0%20niveau%20du%20profil%20de%20l\'entreprise&body=Cher%20Monsieur%20Wahler%2C%0A%0AJe%20suis%20int%C3%A9ress%C3%A9(e)%20par%20un%20profil%20d\'entreprise%20Premium%2C%20veuillez%20m\'envoyer%20de%20plus%20amples%20informations.';
            } else {
                companyentry.bookingurl = 'mailto:sales@vogel.de?subject=Upgrade%20Firmenprofil&body=Sehr%20geehrter%20Herr%20Wahler%2C%0A%0AIch%20habe%20Interesse%20an%20einem%20Firmenprofil%20Premium%2C%20bitte%20senden%20Sie%20mir%20weitere%20Informationen%20zu.';
            }
            applications.push(companyentry.application_id);


        });

        this.companyentries = companyentries;
    }


}
     

