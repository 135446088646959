import SessionService from "../../../services/Session.service";
import { angularFileUpload, IPromise } from "angular";


"use strict";

export default class FilesService {
  
    static URL: string = "/api/insights/companies/:companyid/files/:id";
    static $inject: Array<string> = ["Upload","SessionService","$http"];

    constructor(private Upload: angularFileUpload.IUploadService,private SessionService:SessionService,private $http:angular.IHttpService) { 
    
    }

    public upload(file:any,source:string=""):IPromise<any>{

        if(source === "")
            {
                source = this.SessionService.getActiveCompany().name;
            }
            return this.Upload.upload(
                {
                    url: "/api/insights/companies/"+this.SessionService.getActiveCompany().origid+"/files",
                    data: {file: file, name:file.name,source:source},
                    method:'POST'}
                );           
       
    }
    public delete(file:any):IPromise<any>{
        return this.$http.delete("/api/insights/companies/"+this.SessionService.getActiveCompany().origid+"/files/"+file.id);
    }



}
