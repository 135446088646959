"use strict";

import * as angular from "angular";
import RouterConfig from "./index.route";
import RunConfig from "./index.run";
import LanguageConfig from "./language/translation.config";
import {OAuthConfig, OAuthTokenConfig} from "./oauth.config";
import ChartConfig from './chart.config';
import LocaleConfig from "./locale.config";
import {QuillConfig} from "./htmleditor.config";
import UiSelectConfig from "./ui.config";
import QConfig from "./q.config"

require("./../app/companies/profile/profile.module");

let app = angular.module(
    "insights",
    [
        "insights.common",
        "insights.profile",
    ]
);

require("./services/index");
require("./controllers");
require("./components/index");
require("./directives");
require("./directives/index.js");
require("./filters");

declare var NETWORK_ID;
app.constant("NETWORK_ID", NETWORK_ID);
declare var PRODUCTION;
app.constant("PRODUCTION", PRODUCTION);
declare var ENVIRONMENT;
app.constant("ENVIRONMENT", ENVIRONMENT);

const config = require("./../../config_network_" + NETWORK_ID + ".json");

app.constant("NETWORK_CONFIG", config);
app.config(RouterConfig);
app.run(RunConfig);
app.config(LanguageConfig);
app.config(OAuthConfig).config(OAuthTokenConfig);
app.config(ChartConfig);
app.config(LocaleConfig);
app.config(QuillConfig);
app.config(UiSelectConfig);
app.config(QConfig);