    "use strict"
    import {ISessionService} from "./Session.service";
    import { StateService } from "@uirouter/core";
    import * as angular from "angular";

    export interface IRegService
    {
       
        register(credentials:any):angular.IPromise<any>,
        
    }

    export default class RegService implements IRegService 
    {       
       
        static $inject: Array<string> = ['$http','SessionService','$state','$q','NETWORK_ID'];
        constructor(private $http: angular.IHttpService,private SessionService:ISessionService,private $state:StateService,private $q:angular.IQService,private NETWORK_ID) {}

        public register(credentials:any){
           

            return this.$http.post("/api/insights/auth/register?network_id="+this.NETWORK_ID,credentials).then((response)=>{
                   return response.data;
            });
            
        }  
        
    }


   

