"use strict";

import * as angular from "angular";

import NewsService from "./news.service";
angular.module("insights.profile").service("NewsService",NewsService);

import ProductsService from "./products.service";
angular.module("insights.profile").service("ProductsService",ProductsService);

import ImagesService from "./images.service";
angular.module("insights.profile").service("ImagesService",ImagesService);

import FilesService from "./files.service";
angular.module("insights.profile").service("FilesService",FilesService);

import EventsService from "./events.service";
angular.module("insights.profile").service("EventsService",EventsService);

import JobsService from "./jobs.service";
angular.module("insights.profile").service("JobsService",JobsService);

import FeedsService from "./feeds.service";
angular.module("insights.profile").service("FeedsService",FeedsService);

import DownloadsService from "./download.service";
angular.module("insights.profile").service("DownloadsService",DownloadsService);

import VideosService from "./videos.service";
angular.module("insights.profile").service("VideosService",VideosService);

import GalleriesService from "./galleries.service";
angular.module("insights.profile").service("GalleriesService",GalleriesService);

import TopimagesService from "./topimages.service";
angular.module("insights.profile").service("TopimagesService",TopimagesService);

import LogosService from "./logos.service";
angular.module("insights.profile").service("LogosService",LogosService);

import InfoService from "./info.service";
angular.module("insights.profile").service("InfoService",InfoService);

import CompanypersonsService from "./companypersons.service";
angular.module("insights.profile").service("CompanypersonsService",CompanypersonsService);

import JobwareService from "./jobware.service";
angular.module("insights.profile").service("JobwareService",JobwareService);

import CompanybasedataService from "./companybasedata.service";
angular.module("insights.profile").service("CompanybasedataService",CompanybasedataService);


import SocialmediaService from "./socialmedia.service";
angular.module("insights.profile").service("SocialmediaService",SocialmediaService);

