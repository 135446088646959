

    "use strict";

    export default class ChartConfig{
        static $inject = ["ChartJsProvider"];

        constructor(ChartJsProvider){
             // Configure all charts 
            // ChartJsProvider.setOptions({});
            // Configure all line charts 
            ChartJsProvider.setOptions('line', {
            showLines: true
            });
        }
    }

     
