import { IQService} from "angular";
import {StateService} from "@uirouter/angularjs"
import { IToasterService } from "ngtoaster";
import CompanybasedataService from "../../../../services/companybasedata.service";

"use strict";


export default class BasedataDetailComponent implements ng.IComponentOptions {

    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;


    constructor() {
        this.bindings = {
            basedata: '<',
            id: '<'

        };
        this.controllerAs = 'vm';
        this.controller = DetailComponentController;
        this.template = require("./detail.html");

    }


}


class DetailComponentController implements ng.IComponentController {

    static $inject: Array<String> = ["$translate","CompanybasedataService","$state","$q","toaster","$rootScope"]

    basedata: any;

    isSaving:boolean = false;
    id:string;

    lang:string;

    countries:any;
    constructor(private $translate:angular.translate.ITranslateService,private CompanybasedataService:CompanybasedataService,private $state:StateService,private $q:IQService,private toaster:IToasterService,private $rootScope) {
        this.countries = require("./countries.json");
        
    }

    $onInit() {

        this.lang = this.$translate.use();

        this.$rootScope.$on('$translateChangeSuccess', () => {
            this.lang = this.$translate.use();
        });


        if(this.id == "new"  && (typeof this.basedata.pkcountry == "undefined" || this.basedata.pkcountry == null))
        {
            this.basedata.pkcountry = 80;
        }

        this.countries.some(element => {
            if(element.nicename_en == this.basedata.country_en)
            {
                this.basedata.pkcountry = element.pkcountry;
             
                return true;
            }
            return false;
        });

        
      
    }

    save()
    {
                
        this.isSaving = true;


        let promise:any = {};       

        this.countries.some(element => {
            if(element.pkcountry == this.basedata.pkcountry)
            {
                this.basedata.country = element.nicename_de;
                this.basedata.country_en = element.nicename_en;
                return true;
            }
           
            return false;
        });
    
        if(this.basedata.pkcountry == null)
        {
            this.basedata.country = null;
            this.basedata.country_en = null;           
        }
        
        if(this.id == "new")
        {
            promise = this.CompanybasedataService.create(this.basedata);
        }
        else
        {
            promise = this.CompanybasedataService.update(this.basedata);
        }
       
        promise.then((result)=> {
            this.basedata = result;

            this.countries.some(element => {
                if(element.nicename_en == this.basedata.country_en)
                {
                    this.basedata.pkcountry = element.pkcountry;
                 
                    return true;
                }
                return false;
            });

            this.toaster.success(this.$translate.instant('Erfolgreich gespeichert!'));
            if(  this.id == "new")
            {
                this.$state.transitionTo(this.$state.$current.name,{id:this.basedata.id},{notify:false});                    
            }
            else
            {
                this.isSaving =false;
            }  

        },
        (responseError:any):any =>
        {    
            this.toaster.error(this.$translate.instant('Es ist ein Fehler aufgetreten!'));                     
            this.isSaving =false;          
        }); 

    }

}