    import * as angular from "angular";

    export interface ICompanies 
    {
        pkcompanies:number, 
        location:string,
        origid:string,
        companyname:string,
        modidate:string,
    }

   
    export interface ICompaniesService 
    {
         getUserCompanies(searchterm:string):angular.IPromise<ICompanies[]>;
         getUserCompany(id:string):angular.IPromise<ICompanies>;
         getUserCompanyByOrigid(origid:string):angular.IPromise<ICompanies>;
    }

    export default class CompaniesService implements ICompaniesService {
        
        static URL:string = "/api/insights/companies";
        static $inject: Array<string> = ['$http'];
        constructor(private $http: angular.IHttpService) {}

        public  getUserCompanies(searchterm:string=""):angular.IPromise<ICompanies[]>{            
              return this.$http.get(CompaniesService.URL,{params:{searchterm:searchterm}}).then((result:any)=>{
                  return result.data;
              });            
        }

        
        public getUserCompany(id){
            return this.$http.get(CompaniesService.URL+"/"+id).then((result:any)=>{
                  return result.data;
              });  
        }

        // origid ist mittlerweile ID
        public getUserCompanyByOrigid(origid){

            return this.getUserCompany(origid);
        }

        public updateKeywords(id,keywords:string[], keywords_application: {application_id: number, keywords: string[]}[] )
        {
            return this.$http.patch(CompaniesService.URL+"/"+id,{keywords, keywords_application}).then((result:any)=>{
               return result.data;
            });
        }
    }


