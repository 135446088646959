
    "use strict";

    import { IPosition,ICampaign } from "./../../services/campaign.service";
    import {IStatisticsService} from './../../services/stats.service';
    import $ = require("jquery");
    import angular = require("angular");
    import { Transition } from "@uirouter/core";
    import * as moment from 'moment';
    let now = moment().format('LLLL');

    interface IPositionSelectable extends IPosition{
        selected:boolean;
         selectedTraffic:boolean;
    }
    export default class LeadTransferController {
        static $inject: Array<string> = ["campaign","$translate","StatisticsService","$q","DTOptionsBuilder","$transition$"];


        campaign:ICampaign;

        trafficstats:any;
        currentPosition:IPosition=null;
        selectedPosition = null;
        dtOptions:any;
        loading:boolean=false;
        activeButton:number=2;
        hideOverview:boolean=false;        
        selectedPositions:any[] = [];    
        selectedPositionsList:string="";

        constructor(campaign:ICampaign,private $translate:angular.translate.ITranslateService,private StatisticsService:IStatisticsService,private $q:angular.IQService,private DTOptionsBuilder,$transition$:Transition) {
            this.campaign = campaign;

             //Plugin "Utlimate Date / Time Sorting" konfigurieren
            //@ts-ignore
            $.fn.dataTable.moment("DD.MM.YYYY");
            
            this.hideOverview = $transition$.params().hideOverview;

            angular.forEach(this.campaign.positions,(position)=>{
                  (<IPositionSelectable>position).selected = true;
                  (<IPositionSelectable>position).selectedTraffic = false;
            });

            this.dtOptions =  this.DTOptionsBuilder.newOptions()
            .withDOM('<"html5buttons"B>lTfgitp')
           
            .withButtons([                
                {extend: 'csv'},
                {extend: 'excel'},
                {extend: 'pdf', title: 'Positionen ' +this.campaign.title},

                {extend: 'print',
                    customize:  (win) => {
                        $(win.document.body).addClass('white-bg');
                        $(win.document.body).css('font-size', '10px');

                        $(win.document.body).find('table')
                            .addClass('compact')
                            .css('font-size', 'inherit');
                    }
                }
            ])
            .withOption('order', [1, 'asc']).withOption('lengthMenu', [25, 50, 100])
            .withLanguageSource('assets/localization/dt-'+this.$translate.use()+".json");

        }

        updateData(){
            this.loading = true;
            let arr_selected = [];
            angular.forEach(this.campaign.positions,(position)=>{
                  if((<IPositionSelectable>position).selected){
                      arr_selected.push(position.id);
                  }
            });

            if(arr_selected.length == 0){
                arr_selected.push("no_selection");
            }


            let p2 = this.StatisticsService.getTrafficStatsByType(this.campaign.id,'campaign',0,"","","month",arr_selected.join()).then((result)=>{
                    this.trafficstats = result.data;
                    return true;
            });

            
            
        }


        toggleSelection(id){
           
            let index = this.selectedPositions.indexOf(id); 
            if(index > -1){
                this.selectedPositions.splice(index,1);
            }
            else{
                this.selectedPositions.push(id);
            }

            if(this.selectedPositions.length == 1){
                angular.forEach(this.campaign.positions,(position)=>{
                    if(position._id == this.selectedPositions[0]){
                        this.currentPosition =position;
                    }
                });
            }
            this.selectedPositionsList = this.selectedPositions.join(",");
        }
        
    }


