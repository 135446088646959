"use strict";


import * as angular from "angular";

import "query-string";
import 'angular-oauth2';
import "angularjs-toaster";
import "angular-chart.js";
import uirouter from '@uirouter/angularjs';
import "angular-ui-bootstrap";
import 'angular-datatables';
import "ui-router-breadcrumbs";
import "ui-select";
import "angular-ui-sortable"
import "angular-date-time-input";
import "ng-tags-input";


angular.module('insights.common', 
    [
    require("angular-animate"), 
    require("angular-cookies"), 
    require("angular-touch"), 
    require("angular-sanitize"),
    require("angular-messages"), 
    require("angular-aria"), 
    require("angular-resource"), 
    uirouter,
    'ui.bootstrap',
    require("angular-translate"),
    require("angular-local-storage"),
    "angular-oauth2",
    require("angular-dynamic-locale"),
    require("angular-translate-loader-static-files"),
    "toaster",
    "datatables",
    "datatables.buttons",
    require("angular-file-saver"),
    require('angular-chartist.js'),
    "chart.js",
    "uiBreadcrumbs",
    "ui.select",
    "angular-ladda",
    "ui.dateTimeInput",
    require("ng-file-upload"),
    require('raven-js/plugins/angular').moduleName,
    require("angular-ui-router-tabs"),
    "cp.ngConfirm",
    "ui.sortable",
    require("ng-quill"),
    require("ngstorage-webpack"),
    "ngTagsInput"
    ]
);
