"use strict";

var angular = require('angular');

import AdvertorialsController from "./../advertorials/advertorials.controller";
angular.module('insights').controller('AdvertorialsController', AdvertorialsController);

import BannerController from "./../banner/banner.controller";
angular.module('insights').controller('BannerController',BannerController);

import LeadGeneratorController from "./../campaign/leadgenerator/leadgenerator.controller";
angular.module('insights').controller('LeadGeneratorController',LeadGeneratorController);

import LeadSolutionController from "./../campaign/leadsolution/leadsolution.controller";
angular.module('insights').controller('LeadSolutionController', LeadSolutionController);

import LeadTransferController from "./../campaign/leadtransfer/leadtransfer.controller";
angular.module('insights').controller('LeadTransferController', LeadTransferController);

import ReachadvertorialController from "./../campaign/reachadvertorial/reachadvertorial.controller";
angular.module('insights').controller('ReachadvertorialController', ReachadvertorialController);

import CampaignsController from "./../campaigns/campaigns.controller";
angular.module('insights').controller('CampaignsController', CampaignsController);

import CompanyentriesController from "./../companies/companyentries/companyentries.controller";
angular.module('insights').controller('CompanyentriesController',CompanyentriesController);

import CompanyentryController from "./../companies/companyentry/companyentry.controller";
angular.module('insights').controller('CompanyentryController', CompanyentryController);

import DashboardController from "./../dashboard/dashboard.controller";
angular.module('insights').controller('DashboardController',DashboardController);

import RegisterController from "../auth/register/register.controller";
angular.module('insights').controller('RegisterController', RegisterController);

import UsercompaniesController from "./../user/usercompanies/usercompanies.controller";
angular.module('insights').controller('UsercompaniesController', UsercompaniesController);


