import {IAuthService} from "./../../services/Auth.service";
import {StateService} from "@uirouter/core";
import * as angular from "angular";
import {IPasswordService} from "../../services/password.service";

export default class ChangepasswordComponent implements ng.IComponentOptions {
    public bindings: any;
    public controller: any;
    public controllerAs: string;
    //public template: string;
    public template: string;

    constructor() {
        this.bindings = {};
        this.controllerAs = 'vm',
            this.controller = ChangepasswordComponentController;
        this.template = require("./changepassword.html");
    }
}

interface IChangepasswordComponentController {
    credentials: any;
    changePassword(): void;
    closeAlert(index: number): void;
    alerts: any[];
}

class ChangepasswordComponentController implements IChangepasswordComponentController {

    public credentials = {
        uuid: null,
        pwd: "",
        pwd_whd: ""
    };

    public language = this.$translate.use();

    alerts = [];

    //EDIT: Regex ist kein String
    public passwordStrength = /^(?=.*?[ÄÖÜA-Z])(?=.*?[äöüa-zß])(?=.*?[0-9])(?=.*?[@#$%^£\_*?!"§$%&/()=?+-.,\]\[\{\}]).{8,}$/;

    //           /^            : Start
    //   (?=.{8,})        : Length
    //   (?=.*[a-zA-Z])   : Letters
    //   (?=.*\d)         : Digits
    //   (?=.*[!#$%&? "]) : Special characters
    //   $/              : End
    //     (/^
    //     (?=.*\d)                //should contain at least one digit
    //     (?=.*[a-z])             //should contain at least one lower case
    //     (?=.*[A-Z])             //should contain at least one upper case
    //     [a-zA-Z0-9]{8,}         //should contain at least 8 from the mentioned characters

    //     $/)


    static $inject: Array<string> = [
        'AuthService',
        "PasswordService",
        '$log',
        '$state',
        "$translate",
        "$timeout",
        "$uibModal"
    ];
    loading: boolean = false;

    constructor(
        private AuthService: IAuthService, private PasswordService: IPasswordService, private $log: angular.ILogService, private $state: StateService,
        private $translate: angular.translate.ITranslateService, private $timeout: angular.ITimeoutService, private $uibModal
    ) {
    }


    changePassword(): void {
        this.credentials.uuid = this.$state.params;
        this.loading = true;
        //Arrow Function, so dass man this an Controller gebunden wird
        this.AuthService.changePassword(this.credentials).then(
            (response: any): any => {
                if (response == true) {
                    this.alerts.push({type: "success", msg: this.$translate.instant('changepassword_success')});
                } else {
                    this.alerts.push({type: "danger", msg: this.$translate.instant('changepassword_notvalid')});
                }

                this.$timeout(() => {
                    this.alerts = [];

                }, 5000);
                //   this.$state.go('login');
            },
            (responseError: any): any => {

                this.alerts.push({type: "danger", msg: this.$translate.instant('changepassword_notvalid')});
                this.$timeout(() => {
                    this.alerts = [];
                }, 5000);
            }).finally(() => {
            this.loading = false;
        });
    }

    closeAlert(index: number): void {
        this.alerts.splice(index, 1);
    }

    openImprint() {
        this.$uibModal.open({
            controllerAs: 'vm',
            controller: ModalInstanceControl,
            template: require('./../../components/common/imprint.html')
        });
    }

    openContact() {
        this.$uibModal.open({
            controllerAs: 'vm',
            controller: ModalInstanceControl,
            template: require('./../../components/common/contact.html')
        });
    }

    showPassword(input: any): any {
        this.PasswordService.showPassword(input);
    }

    showRequirements(input: any): any {
        this.PasswordService.showRequirements(input);
    }
}

class ModalInstanceControl {
    static $inject: Array<string> = ["$uibModalInstance"]

    constructor(private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance) {
    }

    close() {
        this.$uibModalInstance.dismiss();
    }
}

   

