"use strict";

import * as angular from "angular";

import ContactComponent from "./contact/contact.component";
import NewsComponent from "./contents/news/tabs/news/news.component";
import NewsItemComponent from "./contents/news/listitem/newsitem.component";
import NewsDetailComponent from "./contents/news/detail/newsdetail.component";
import NewsFeedsComponent from "./contents/news/tabs/feeds/feeds.component";
import ArticlesComponent from "./contents/news/tabs/articles/articles.component";
import ProductsComponent from "./contents/products/products.component";
import ProductsDetailComponent from "./contents/products/detail/productdetail.component";
import ProductsItemComponent from "./contents/products/listitem/productitem.component";
import BasedataComponent from "./contact/basedata/basedata.component";
import ContactTabsComponent from "./contact/tabs/tabs.component";
import ContactPersonsComponent from "./contact/contactpersons/contactpersons.component";
import ContactPersonItemComponent from "./contact/contactpersons/listitem/contactpersonitem.component";
import ContactPersonsDetailComponent from "./contact/contactpersons/detail/contactpersonsdetail.component";
import JobwareComponent from "./contents/jobware/jobware.component";
import DownloadsComponent from "./contents/downloads/downloads.component";
import DownloadsDetailComponent from "./contents/downloads/detail/downloaddetail.component";
import DownloadsItemComponent from "./contents/downloads/listitem/downloaditem.component";
import EventsComponent from "./contents/events/events.component";
import EventsDetailComponent from "./contents/events/detail/eventdetail.component";
import EventsItemComponent from "./contents/events/listitem/eventitem.component";
import JobsComponent from "./contents/jobs/jobs.component";
import JobsDetailComponent from "./contents/jobs/detail/jobdetail.component";
import JobsItemComponent from "./contents/jobs/listitem/jobitem.component";
import VideosComponent from "./contents/videos/videos.component";
import VideosDetailComponent from "./contents/videos/detail/videodetail.component";
import VideosItemComponent from "./contents/videos/listitem/videoitem.component";
import ListingComponent from "./listing/contentlisting.component";
import ApplicationsComponent from "./applications/applications.component";
import FeedComponent from "./contents/feeds/feed.component";
import NewsTabsComponent from "./contents/news/tabs/tabs.component";
import GalleriesComponent from "./contents/galleries/galleries.component";
import GalleriesDetailComponent from "./contents/galleries/detail/gallerydetail.component";
import GalleriesItemComponent from "./contents/galleries/listitem/galleryitem.component";
import GeneralTabsComponent from "./contents/general/tabs/tabs.component";
import LogosListingComponent from "./contents/general/logos/logos.component";
import LogosItemComponent from "./contents/general/logos/listitem/item.component";
import LogosDetailComponent from "./contents/general/logos/detail/logosdetail.component";
import TopimagesListingComponent from "./contents/general/topimages/topimages.component";
import TopimagesItemComponent from "./contents/general/topimages/listitem/item.component";
import TopimagesDetailComponent from "./contents/general/topimages/detail/topimagesdetail.component";
import AboutUsComponent from "./contents/aboutus/aboutus.component";
import InfoDetailComponent from "./contents/aboutus/detail/infodetail.component";
import InfoItemComponent from "./contents/aboutus/listitem/infoitem.component";
import BasedataItemComponent from "./contact/basedata/listitem/basedataitem.component";
import BasedataDetailComponent from "./contact/basedata/detail/basedatadetail.component";
import SocialmediaItemComponent from "./contact/socialmedia/listitem/socialmediaitem.component";
import SocialmediaDetailComponent from "./contact/socialmedia/detail/socialmediadetail.component";
import SocialmediaComponent from "./contact/socialmedia/socialmedia.component";
import KeywordsComponent from "./contents/keywords/keywords.component";

angular.module("insights.profile").component("profileContact", new ContactComponent());
angular.module("insights.profile").component("profileNews", new NewsComponent());
angular.module("insights.profile").component("profileNewsItem", new NewsItemComponent());
angular.module("insights.profile").component("profileNewsDetail", new NewsDetailComponent());
angular.module("insights.profile").component("profileNewsFeeds", new NewsFeedsComponent());
angular.module("insights.profile").component("profileNewsArticles", new ArticlesComponent());
angular.module("insights.profile").component("profileProducts", new ProductsComponent());
angular.module("insights.profile").component("profileProductDetail", new ProductsDetailComponent());
angular.module("insights.profile").component("profileProductsItem", new ProductsItemComponent());
angular.module("insights.profile").component("profileBasedata", new BasedataComponent());
angular.module("insights.profile").component("profileContactTabs", new ContactTabsComponent());
angular.module("insights.profile").component("profileContactpersons", new ContactPersonsComponent());
angular.module("insights.profile").component("profileContactpersonsItem", new ContactPersonItemComponent());
angular.module("insights.profile").component("profileContactpersonsDetail", new ContactPersonsDetailComponent());
angular.module("insights.profile").component("profileJobware", new JobwareComponent());
angular.module("insights.profile").component("profileDownloads", new DownloadsComponent());
angular.module("insights.profile").component("profileDownloadsDetail", new DownloadsDetailComponent());
angular.module("insights.profile").component("profileDownloadsItem", new DownloadsItemComponent());
angular.module("insights.profile").component("profileEvents", new EventsComponent());
angular.module("insights.profile").component("profileEventsDetail", new EventsDetailComponent());
angular.module("insights.profile").component("profileEventsItem", new EventsItemComponent());
angular.module("insights.profile").component("profileJobs", new JobsComponent());
angular.module("insights.profile").component("profileJobsDetail", new JobsDetailComponent());
angular.module("insights.profile").component("profileJobsItem", new JobsItemComponent());
angular.module("insights.profile").component("profileVideos", new VideosComponent());
angular.module("insights.profile").component("profileVideosDetail", new VideosDetailComponent());
angular.module("insights.profile").component("profileVideosItem", new VideosItemComponent());
angular.module("insights.profile").component("profileListing", new ListingComponent());
angular.module("insights.profile").component("profileApplications", new ApplicationsComponent());
angular.module("insights.profile").component("profileFeed", new FeedComponent());
angular.module("insights.profile").component("profileNewsTabs", new NewsTabsComponent());
angular.module("insights.profile").component("profileGalleries", new GalleriesComponent());
angular.module("insights.profile").component("profileGalleriesDetail", new GalleriesDetailComponent());
angular.module("insights.profile").component("profileGalleriesItem", new GalleriesItemComponent());
angular.module("insights.profile").component("profileGeneralTabs", new GeneralTabsComponent());
angular.module("insights.profile").component("profileLogosListing", new LogosListingComponent());
angular.module("insights.profile").component("profileLogosItem", new LogosItemComponent());
angular.module("insights.profile").component("profileLogosDetail", new LogosDetailComponent());
angular.module("insights.profile").component("profileTopimagesListing", new TopimagesListingComponent());
angular.module("insights.profile").component("profileTopimagesItem", new TopimagesItemComponent());
angular.module("insights.profile").component("profileTopimagesDetail", new TopimagesDetailComponent());
angular.module("insights.profile").component("profileInfo", new AboutUsComponent());
angular.module("insights.profile").component("profileInfoDetail", new InfoDetailComponent());
angular.module("insights.profile").component("profileInfoItem", new InfoItemComponent());
angular.module("insights.profile").component("profileBasedataItem", new BasedataItemComponent());
angular.module("insights.profile").component("profileBasedataDetail", new BasedataDetailComponent());
angular.module("insights.profile").component("profileSocialmediaItem", new SocialmediaItemComponent());
angular.module("insights.profile").component("profileSocialmediaDetail", new SocialmediaDetailComponent());
angular.module("insights.profile").component("profileSocialmedia", new SocialmediaComponent());
angular.module("insights.profile").component("profileKeywords", new KeywordsComponent());