    import {  ICompanies,ICompaniesService} from "./../../services/companies.service";
    import {  ISessionService} from "./../../services/Session.service";
    import { StateService } from "@uirouter/core";
    import ngtoaster = require("ngtoaster");
    import * as angular from "angular";
    import CompanyentryService from "../../services/companyentries.service";

    declare var _gscq:any;

    export default class UsercompaniesController {
        
        static $inject: Array<string> = ['CompaniesService','SessionService','$state',"toaster","$translate","$rootScope","CompanyentryService","$q","$window"];

        public companies: ICompanies[] = [];
        public searchterm:string="";
        public searching:boolean=true;

        public isLoading:boolean=false;

        constructor(private CompaniesService: ICompaniesService,private SessionService:ISessionService,private $state:StateService,
            private toaster:ngtoaster.IToasterService,private $translate:angular.translate.ITranslateService,private $rootScope,private CompanyentryService:CompanyentryService,private $q:angular.IQService,private $window:angular.IWindowService) {
            this.getCompanies();
        }

        selectCompany(company:ICompanies){
            this.isLoading = true;
            this.CompaniesService.getUserCompany(company.origid).then((company)=>{
                
                let p1 = this.CompanyentryService.getCompanyentries(company.origid).then((success)=>{
                    this.SessionService.setCompanyentries(success.data);
                    return true;
                });
                let p2 =  this.SessionService.setActiveCompany(company);

                return this.$q.all([p1,p2]).then((success)=>{

                       //Call getsitecontrol with origid
                      _gscq.push(['targeting', 'kundennummer', company.origid]);

                      //Benachritigungen aktualisieren
                      this.$rootScope.$broadcast('update-notifications');
                      this.$rootScope.$broadcast('company-changed');
                      this.$state.go('index.dashboard');
                })

                
            },(error)=>{
                this.isLoading = false;
                this.toaster.error(this.$translate.instant('usercompanies_error'),this.$translate.instant('usercompanies_error_text'));
            });
        }
        searchCompanies(){
            this.searching=true;
            this.getCompanies();
        }

        private getCompanies(){
            this.CompaniesService.getUserCompanies(this.searchterm).then((companies)=>{
                this.companies = companies;
                this.searching = false;
            });
        }

    }

   