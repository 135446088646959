"use strict";

var angular = require('angular');

import viD3Funnel from "./d3funnel/d3funnel.directive";
angular.module('insights').directive('viD3Funnel', viD3Funnel);

import compareTo from "./compareTo/compareTo.directive";
angular.module('insights').directive("compareTo", compareTo);

import collapse from "./collapse/collapse.directive";
angular.module('insights').directive("collapse", collapse);

import LanguageSelectDirective from "./../language/languageselect.directive";
angular.module('insights').directive('selectLanguage', LanguageSelectDirective.factory());

import scroll from "./scroll/scroll.directive";
angular.module('insights').directive("scrollFixed", scroll);
