import { IQService, ITimeoutService, ui } from "angular";
import moment = require("moment");
import ImagesService from "../../../../services/images.service";
import { ICompanyentry } from "../../../../../../services/companyentries.service";
import {StateService} from "@uirouter/angularjs"
import JobsService from "../../../../services/jobs.service";
import FilesService from "../../../../services/files.service";
import { IToasterService } from "ngtoaster";

"use strict";


export default class JobDetailComponent implements ng.IComponentOptions {

    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;


    constructor() {
        this.bindings = {
            content: '<',
            id: '<'

        };
        this.controllerAs = 'vm';
        this.controller = JobDetailComponentController;
        this.template = require("./jobdetail.html");

    }


}


export class JobDetailComponentController implements ng.IComponentController {

   

    content: any;
    image:any;

    pdf:any;

    datefrom:Date = null;
    dateto:Date = null;

    isSaving:boolean = false;
    image_path:string;
    id:string;
    alerts = [];
    isOpenDateFrom = false;
    isOpenDateTo = false;

    downloaddelete:any;

    image_error = "";
    image_warning = "";

    static $inject: Array<String> = ["$translate","ImagesService","JobsService","$state","FilesService","$q","toaster"];
    constructor(private $translate:angular.translate.ITranslateService,private ImagesService:ImagesService,private JobsService:JobsService,private $state:StateService,private FilesService:FilesService,private $q:IQService,private toaster:IToasterService) {

    }

    $onInit() {
        moment.locale(this.$translate.use());
        if(this.content.datefrom)
        {
            this.datefrom = new Date(this.content.datefrom);
        }
        else
        {
            this.datefrom = new Date();
        }
        
        
        if(this.content.dateto)
        {
            this.dateto = new Date(this.content.dateto);
        }

      
    }

    save()
    {

        this.content.datefrom = moment(this.datefrom).toISOString();

        if(this.dateto  !== null)
        {
            this.content.dateto = moment(this.dateto).toISOString();
        }
        else
        {
            this.content.dateto = null;
        }



        this.isSaving = true;

        let promises:any = {};       
                        
        //Wurde neues Bild hochgeladen?
        if(this.isTempImage()){                        
            promises.image = this.ImagesService.upload(this.image,"","","content").then((result)=>{
                this.image = null;
                return result.data;
            });                        
        }

        //Wurde neues Bild hochgeladen?
        if(this.isTempFile()){
            promises.file = this.FilesService.upload(this.pdf).then((result)=>{
                this.pdf = null;
                return result.data;
            });            
        }


        this.$q.all(promises).then((results:any)=>
        {

            let content = this.content;
            if(results.file)
            {
                if(content.files.length > 0 && typeof content.files[0].id !== "undefined")
                {       
                    //Löschen Async ausführen             
                    this.FilesService.delete(content.files[0]);
                                        
                }
                content.files[0] = results.file;
            }

            if(results.image)
            {
                if(content.images.length > 0 && content.images[0] !== null){
                    //Löschen Async ausführen
                    this.ImagesService.delete(content.images[0].id);
                }
                content.images[0] = results.image;
            }

            if(typeof this.downloaddelete !== "undefined")
            {
                //Löschen Async ausführen
                this.FilesService.delete(this.downloaddelete);
            }

            return content;

        })
        .then((content)=>{
            if(this.id == "new")
            {
               return this.JobsService.create(content);
            }
            else
            {
                return this.JobsService.update(this.id,content);
            }
        })
        .then((result)=> {
            this.content = result;
                this.image = null;
            this.toaster.success(this.$translate.instant('Erfolgreich gespeichert!'));
            if(  this.id == "new")
            {
                this.$state.transitionTo(this.$state.$current.name,{id:this.content.id},{notify:false});                    
            }
            else
            {
                this.isSaving =false;
            }  

        },
        (responseError:any):any =>
        {
            this.image_error =responseError.data.error;
            this.toaster.error(this.$translate.instant('Es ist ein Fehler aufgetreten!'));            
            this.isSaving =false;          
        }); 
      
    }

    isTempImage():boolean
    {

        return (this.image !== null && typeof this.image === 'object')
    }

    isTempFile():boolean
    {
        return (typeof this.pdf === 'object')
    }

    removeDownload()
    {
        if(this.content.files.length > 0 && typeof this.content.files[0].id !== "undefined")
        {
            this.downloaddelete = this.content.files[0];
        }

        this.content.files = [];
    }

    checkRatio(file)
    {
        this.image_warning = "";
        if(file !== null)
        {
            this.ImagesService.checkRatio(file).then((result)=>{
                this.image_warning = result;
            });
        }

    }

}