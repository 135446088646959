import { IScope, IPromise, IQService, ITimeoutService, ui } from "angular";
import moment = require("moment");
import ImagesService from "../../../../services/images.service";
import NewsService from "../../../../services/news.service";
import { ICompanyentry } from "../../../../../../services/companyentries.service";
import {StateService} from "@uirouter/angularjs"
import { IToasterService } from "ngtoaster";

"use strict";


export default class NewsDetailComponent implements ng.IComponentOptions {

    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;


    constructor() {
        this.bindings = {
            content: '<',
            id: '<'

        };
        this.controllerAs = 'vm';
        this.controller = NewsDetailComponentController;
        this.template = require("./newsdetail.html");

    }


}


export class NewsDetailComponentController implements ng.IComponentController {

    

    content: any;
    image:any;

    datefrom:Date = null;
    dateto:Date = null;

    isSaving:boolean = false;

    image_path:string;
    id:string;
    alerts = [];
    isOpenDateFrom = false;
    isOpenDateTo = false;
    image_error = "";
    image_warning = "";
    image_source = "";


    static $inject: Array<String> = ["$translate","ImagesService","NewsService","$state","$q","toaster"];
    constructor(private $translate:angular.translate.ITranslateService,private ImagesService:ImagesService,private NewsService:NewsService,private $state:StateService,private $q:IQService,private toaster:IToasterService) {

    }

    $onInit() {
        moment.locale(this.$translate.use());
        if(this.content.datefrom)
        {
            this.datefrom = new Date(this.content.datefrom);
        }
        else
        {
            this.datefrom = new Date();
        }
        
        
        if(this.content.dateto)
        {
            this.dateto = new Date(this.content.dateto);
        }
        else
        {
            this.dateto = new Date();
            this.dateto.setFullYear(this.dateto.getFullYear() + 2);
        }


        if (this.content.images.length > 0 && this.content.images[0] !== null){
            this.image_source = this.content.images[0].source;

        }

      
    }

    save()
    {
        this.content.datefrom = moment(this.datefrom).toISOString();

        if(this.dateto  !== null)
        {
            this.content.dateto = moment(this.dateto).toISOString();
        }
        else
        {
            this.content.dateto = moment().add(2, 'years').toISOString();
        }

        
        this.isSaving = true;


        let promises:any = {};
        let image_source = "";

        if(this.image_source)
        {
            image_source = this.image_source;
        }



        //Wurde neues Bild hochgeladen?
        if(this.isTempImage()){                        
            promises.image = this.ImagesService.upload(this.image,image_source,image_source,"content").then((result)=>{
                this.image = null;
                return result.data;
            });                        
        }else if (this.image_source ){

            this.ImagesService.changeImageSource(image_source,this.content.images);

        }


        this.$q.all(promises).then((results:any)=>
        {

            let content = this.content;

            if(results.image)
            {

                if(content.images.length > 0 && content.images[0] !== null){
                    //Löschen Async ausführen
                    this.ImagesService.delete(content.images[0].id);
                }
                content.images[0] = results.image;
            }

            return content;

        })
        .then((content)=>{
            if(this.id == "new")
            {
                if (this.image_source && (content.images.length > 0 && content.images[0] !== null)){
                    content.images[0].source = this.image_source;

                }
               return this.NewsService.create(content);
            }
            else
            {
                if (this.image_source && (content.images.length > 0 && content.images[0] !== null)){
                    content.images[0].source = this.image_source;

                }
                return this.NewsService.update(this.id,content);
            }
        })
        .then((result)=> {
            this.content = result;

            this.toaster.success(this.$translate.instant('Erfolgreich gespeichert!'));
            if(  this.id == "new")
            {
                this.$state.transitionTo(this.$state.$current.name,{id:this.content.id},{notify:false});                    
            }
            else
            {
                this.isSaving =false;
            }

            this.image = null;

        },
        (responseError:any):any =>
        {
            this.image_error =responseError.data.error;

            this.toaster.error(this.$translate.instant('Es ist ein Fehler aufgetreten!'));                     
            this.isSaving =false;          
        }); 

    }

    isTempImage():boolean
    {
        
        return (this.image !== null && typeof this.image === 'object')
    }

    checkRatio(file)
    {
        this.image_warning = "";
        if(file !== null)
        {
            this.ImagesService.checkRatio(file).then((result)=>{
                this.image_warning = result;
            });
        }

    }




}