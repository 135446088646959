"use strict";

// Styles
import './index.less';
// Variablen für Legacy Plugins
declare let window;
import $ = require("jquery");
window.jQuery = $;
let jQuery = $;

require("jquery-ui/ui/widgets/sortable");

let angular = require('angular');
let Raven = require('raven-js');
let path = require('path');

//Variable aus webpack-build (wird über gitlab gesetzt)
declare let RELEASE;
declare let ENVIRONMENT;
Raven
  .config('https://3d39fb68e629410abe7a5f3498114855@sentry.vogel.de/29',{
    release: RELEASE,
    environment:ENVIRONMENT
  })
  .addPlugin(require('raven-js/plugins/angular'), angular)
  .install();

 

//Wird für Datatables gebraucht
require('pdfmake/build/pdfmake.js');
require('imports-loader?this=>window!pdfmake/build/vfs_fonts.js');
import JSZip = require("jszip");
//Datatables erwartet JSZip unter window
window.JSZip = JSZip;
//Datatables geht nicht über npm, daher package von website per script-loader laden
require("script-loader!./../assets/js/datatables/datatables.min.js");
require("./../assets/js/datatables/datatables.min.css");
import "./../assets/js/datatables/angular-datatables-datetime-moment.js";

require("quill");
require("quill/dist/quill.snow.css");
require("animate.css");
require("angularjs-bootstrap-datetimepicker/src/css/datetimepicker.css");
require("ui-select/dist/select.min.css");
require("angularjs-toaster/toaster.min.css");

//Angular Ladda (Nice Buttons)
require("./../../node_modules/ladda/dist/ladda-themeless.min.css");
require("angular-ladda");
//Angular Confirmations
require("angular-confirm1");
require("angular-confirm1/dist/angular-confirm.min.css");
//Tags input
require("ng-tags-input/build/ng-tags-input.min.css");
require("ng-tags-input/build/ng-tags-input.bootstrap.min.css");
require("bootstrap");

//Common Modul laden (Enthält alle externen Plugins)
require("./common.module");

//App laden
require("./app.module");

//Pace
let pace = require("imports-loader?define=>false!pace-js");
pace.start();


require("./externals/inspinia.js");

