
    'use strict';

    import * as angular from "angular";
import { ICompanyentry } from "./companyentries.service";
    
    export interface ISessionService {  
        setUser(user);      
        getUser():any;
        destroy():void;
        getActiveCompany():any;
        setActiveCompany(company:any);
        setOrigid(origid:string);
        getOrigid():any;

        setUniqueid(id);

        getCompanyentries():ICompanyentry[];

        setCompanyentries(companyentries:ICompanyentry[]);
    }
    
    export default class SessionService implements ISessionService {

        public user;
        private origid = null;
        private uniqueid = null;

        private companyentries:ICompanyentry[] = null;        
        static $inject: Array<string> = ["$localStorage","$sessionStorage"];
        
        constructor(private $localStorage,private $sessionStorage) {
            this.user = $localStorage.user;
            
        }

        public setOrigid(origid){
            this.origid = origid;
            this.$localStorage.origid = origid;
        }

        public setUniqueid(id){         
            this.user.apiuniqueid = id;
            this.$localStorage.user = this.user
        }

        public getOrigid(){
           
            this.origid = this.$localStorage.origid;
            if (this.origid !== null &&  this.user !== null){
                this.user.origid = this.origid;
                this.$localStorage.user = this.user;
            }
            
        }


        public setUser(user){
            this.user = user;
            this.$localStorage.user = user
        }

        public getActiveCompany(){

            return this.$sessionStorage.active_company;
        }

        public setActiveCompany(company:any)
        {
            this.$sessionStorage.active_company = company;
        }

        public setCompanyentries(companyentries)
        {
            this.$sessionStorage.companyentries = companyentries;
            this.companyentries =  companyentries;
        }

        public getUser(){           
            if (this.origid !== null){
                this.getOrigid();
            }
            return this.user;
        }

        public destroy(){
            this.user = null;
            delete this.$localStorage.user;
            delete this.$sessionStorage.active_company;
            delete this.$sessionStorage.companyentries;

        }

        getCompanyentries(): ICompanyentry[] {
            if(this.user !== null && this.companyentries === null){
                this.companyentries = this.$sessionStorage.companyentries;
            }
            return this.companyentries ? this.companyentries : [];
        }

        setUserCompany(company)
        {
            this.$localStorage.user_compay = company;
        }

        getUserCompany()
        {
            return this.$localStorage.user_compay;
        }
    }

