
import { IPosition, ICampaign, ICampaignService } from './../../services/campaign.service';
import { ILead, ILeadResult, ILeadsService } from './../../services/leads.service';
import angular = require('angular');

import $ = require("jquery");
export default class LeadtableComponent implements ng.IComponentOptions {

    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;


    constructor() {
        this.bindings = {
            leads: '<',
            campaign: '=',
            headers: '<',
            positions: '<',
            afterExport: '&'
        };
        this.controllerAs = 'vm';
        this.controller = LeadtableController;
        this.template = require("./viLeadtable.html");

    }

}

interface LeadtableComponentBindings {
    leads: ILead[];
    campaign: ICampaign;
    headers: any[];
    positions: number[];
}

interface ILeadtableComponentController extends LeadtableComponentBindings {
    dtOptions: any;
}

class LeadtableController implements LeadtableComponentBindings {

    public leads;
    public dtOptions;
    public campaign;
    public headers;
    public positions;
    public afterExport: () => any;

    public dtInstance: any = {};

    public dtColumns: any = {};
    public dtHeaders: any = {};

    static $inject: Array<string> = ['$translate', 'DTOptionsBuilder', '$q', 'DTColumnBuilder', '$filter', 'CampaignService'];

    constructor(private $translate: angular.translate.ITranslateService, private DTOptionsBuilder, private $q: angular.IQService, private DTColumnBuilder, private $filter: angular.IFilterService, private CampaignService: ICampaignService) {
        //Plugin "Utlimate Date / Time Sorting" konfigurieren
        //@ts-ignore
        $.fn.dataTable.moment("dd.MM.yyyy");
    }
    $onInit() {
        //Leer initialisieren
        let defer = this.$q.defer();
        defer.resolve(this.leads);

        let dynColumns = [];
        let index = 0;

        for (let header of this.headers) {

            let key = header.column;

            let columnTitle = header.title;


            let column = this.DTColumnBuilder.newColumn(key);
            let hidden = false;
            //Positionsdinger ausblenden
            if (key.indexOf('pos_') === 0 || key.indexOf('question') >= 0 || key.indexOf('user_id') === 0 || key.indexOf('event_id') === 0 ) {
                //Spalte ausblenden
                hidden = true;
                column = column.notVisible();
                if (key.indexOf('pos_') >= 0) {
                    let posid = key.replace('pos_', '');

                    angular.forEach(this.campaign.positions, (element) => {

                        if (element._id == posid) {
                            columnTitle = element.clientname.title + ": " + element.reporting_title;
                        }
                    });

                    column.renderWith((data, type, full) => {
                        return this.$filter("date")(data, "dd.MM.yyyy");
                    });
                }
                if (key.indexOf('question') >= 0) {
                    columnTitle = 'Leadfrage';
                }
            }
            else {
                column = this.DTColumnBuilder.newColumn(key);

                if ((key.indexOf('date') > -1 || key.indexOf('datum') > -1 || key.indexOf('Datum') > -1 || key.indexOf('lastcall') > -1)) {
                    column.renderWith((data, type, full) => {
                        return this.$filter("date")(data, "dd.MM.yyyy HH:mm");
                    });

                    // column.withOption('type', 'date');
                }
                if (key === "terms" || key === "consent") {
                    column.renderWith((data, type, full) => {
                        return data?"X":"";
                    });
                }
                if (key === "customerewe" && header.title !== "customerewe") {
                    column.renderWith((data, type, full) => {                        
                        return data?"X":"";
                    });
                }

            }

            
            // Customer EWE nur ausgeben, wenn diese explizt im KMT eingestellt wurde (erkennbar am Titel (TODO: sauberer lösen))
            if(key.indexOf('customerewe') !== 0 || (key === "customerewe"  && header.title !== "customerewe"))
            {
                if(this.leads.length > 0 )
                {
                    if(typeof this.leads[0][key] !== "undefined")
                    {
                        dynColumns.push(column);        
                    }
                }
                else
                {
                    dynColumns.push(column);
                }
                
            }
            
            column = column.withTitle(columnTitle);
                        



            index++;

        }


        this.dtColumns = dynColumns;

        //Je nach Bedarf größe des PDF-Formats anpassen
        let pageSize = 'A3';
        if (this.dtColumns.length > 12) {
            pageSize = 'A2';
        }

        let myCampaign = this.campaign;
        let myleads = this.leads;        
        let that = this;
        this.dtOptions = this.DTOptionsBuilder
            .fromFnPromise(defer.promise)
            .withDOM('<"html5buttons"B>lTfgitp')
            .withButtons([
                {
                    extend: 'csv',
                    action: function (e, dt, button, config) {

                        that.markLeads(dt);

                        // Call the original action function afterwards to
                        // continue the action.
                        // Otherwise you're just overriding it completely.
                        //@ts-ignore
                        $.fn.dataTable.ext.buttons.csvHtml5.action.call(this, e, dt, button, config);

                        dt.search('');
                    }
                },
                {
                    extend: 'excel', title: 'Leads ' + this.campaign.title.replace(/\s*\W/g, '-'),
                    action: function (e, dt, button, config) {


                        that.markLeads(dt);

                        // Call the original action function afterwards to
                        // continue the action.
                        // Otherwise you're just overriding it completely.
                        //@ts-ignore
                        $.fn.dataTable.ext.buttons.excelHtml5.action.call(this, e, dt, button, config);

                        dt.search('');
                    }
                },
                /*{extend: 'excel', title: 'Leads ' +this.campaign.title.replace(/\s*\W/g,'-')},*/
                {
                    extend: 'pdf', title: 'Leads ' + this.campaign.title, orientation: 'landscape', pageSize: pageSize,
                    exportOptions: {
                        /*columns: [ 0, 1, 2,3,4,5,6,7 ]*/
                    }, customize: function (doc) {
                        doc.defaultStyle.fontSize = 10; //<-- set fontsize to 16 instead of 10
                    },
                    action: function (e, dt, button, config) {

                        that.markLeads(dt);

                        // Call the original action function afterwards to
                        // continue the action.
                        // Otherwise you're just overriding it completely.
                        //@ts-ignore
                        $.fn.dataTable.ext.buttons.pdfHtml5.action.call(this, e, dt, button, config);
                        dt.search('');
                    }
                },
                {
                    extend: 'print',
                    customize: function (win) {
                        $(win.document.body).addClass('white-bg');
                        $(win.document.body).css('font-size', '10px');

                        $(win.document.body).find('table')
                            .addClass('compact')
                            .css('font-size', 'inherit');
                    }
                }
            ])
            .withOption('order', [1, 'asc']).withOption('lengthMenu', [25, 50, 100])
            .withOption('scrollX', true)
            .withLanguageSource('assets/localization/dt-' + this.$translate.use() + ".json");

    }

    markLeads(dataTable: any) {
        let arr_selection = [];

        //Nur sichtbare Elemente
        let dtfiltered = dataTable.rows({ search: 'applied' }).data();

        for (let x = 0; x < dtfiltered.length; x++) {
            arr_selection.push(dtfiltered[x]);
        }

        this.CampaignService.markLeadsAsExported(this.campaign.id, arr_selection);

        for (let x = 0; x < arr_selection.length; x++) {
            //this.campaing ist mit parent-Element mit two-way-binding verbunden
            if (this.campaign.exported_leads.indexOf(arr_selection[x].user_id) < 0) {
                this.campaign.exported_leads.push(arr_selection[x].user_id);
            }
        }

        //Callback-Methode rufen
        this.afterExport();
    }


    /*
    * Änderungen im Model per Promise an datatable weitergeben
    */
    $onChanges() {

        //Prüfung ob datatable bereits instanziert wurde
        if (angular.isDefined(this.dtInstance.id)) {
            let defer = this.$q.defer();
            defer.resolve(this.leads);
            defer.resolve(this.headers);
            this.dtInstance.changeData(defer.promise);
        }

    }

    $onDestroy() {
    }
}


