import { IQService, ITimeoutService } from "angular";
import moment = require("moment");
import ImagesService from "../../../../services/images.service";
import { ICompanyentry } from "../../../../../../services/companyentries.service";
import {StateService} from "@uirouter/angularjs"
import EventsService from "../../../../services/events.service";
import { IToasterService } from "ngtoaster";

"use strict";


export default class EventDetailComponent implements ng.IComponentOptions {

    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;


    constructor() {
        this.bindings = {
            content: '<',
            id: '<'

        };
        this.controllerAs = 'vm';
        this.controller = EventDetailComponentController;
        this.template = require("./eventdetail.html");

    }


}


export class EventDetailComponentController implements ng.IComponentController {



    content: any;
    image:any;

    datefrom:Date = null;

    event_datefrom:Date = null;
    event_dateto:Date = null;

    isSaving:boolean = false;
    image_path:string;
    id:string;
    alerts = [];
    isOpenDateFrom =false ; 
    
    isOpenDateFromEvent = false;
    isOpenDateTo = false;
    isOpenDateToEvent = false;

    image_error = "";
    image_warning = "";

    static $inject: Array<String> = ["$translate","ImagesService","EventsService","$state","$q","toaster"];
    constructor(private $translate:angular.translate.ITranslateService,private ImagesService:ImagesService,private EventsService:EventsService,private $state:StateService,private $q:IQService,private toaster:IToasterService) {

    }

    $onInit() {
        moment.locale(this.$translate.use());
        
        if(this.content.datefrom)
        {
            this.datefrom = new Date(this.content.datefrom);
        }
        else
        {
            this.datefrom = new Date();
        }
        
        if(this.content.event_datefrom)
        {
            this.event_datefrom = new Date(this.content.event_datefrom);
        }

        if(this.content.event_dateto)
        {
            this.event_dateto = new Date(this.content.event_dateto);
        }

      
    }

    save()
    {
        this.content.datefrom = moment(this.datefrom).toISOString();
        this.content.dateto = moment(this.event_dateto).toISOString();

        this.content.event_datefrom = moment(this.event_datefrom).toISOString();
        this.content.event_dateto = moment(this.event_dateto).toISOString();
        
        
        this.isSaving = true;

        let promises:any = {};

        //Wurde neues Bild hochgeladen?
        if(this.isTempImage()){
            promises.image = this.ImagesService.upload(this.image,"","","content").then((result)=>{
                this.image = null;
                return result.data;
            });
        }


        this.$q.all(promises).then((results:any)=>
        {

            let content = this.content;

            if(results.image)
            {
                if(content.images.length > 0 && content.images[0] !== null){
                    //Löschen Async ausführen
                    this.ImagesService.delete(content.images[0].id);
                }
                content.images[0] = results.image;
            }

            return content;

        })
        .then((content)=>{
            if(this.id == "new")
            {
               return this.EventsService.create(content);
            }
            else
            {
                return this.EventsService.update(this.id,content);
            }
        })
        .then((result)=> {
            this.content = result;
            
            this.toaster.success(this.$translate.instant('Erfolgreich gespeichert!'));     
        
            if(  this.id == "new")
            {
                this.$state.transitionTo(this.$state.$current.name,{id:this.content.id},{notify:false});                    
            }
            else
            {
                this.isSaving =false;
            }
                this.image = null;

        },
        (responseError:any):any =>
        {
            this.image_error =responseError.data.error;

            this.toaster.error(this.$translate.instant('Es ist ein Fehler aufgetreten!'));            
            this.isSaving =false;          
        }); 
    }

    isTempImage():boolean
    {

        return (this.image !== null && typeof this.image === 'object')
    }

    checkRatio(file)
    {
        this.image_warning = "";
        if(file !== null)
        {
            this.ImagesService.checkRatio(file).then((result)=>{
                 this.image_warning = result;
            });
        }

    }


}