   'use strict';

    import CompanyentryService, { ICompanyentry,ICompanyrequests,ICompanyentryService } from "./../../services/companyentries.service";
    import { ISessionService } from "./../../services/Session.service";
    import { IAuthService } from "./../../services/Auth.service";
    import * as moment from 'moment';
    let now = moment().format('LLLL');
    import angular = require("angular");
    import $ = require("jquery");

    export default class CompanyentryController {
        static $inject: Array<string> = ['companyentry', 'CompanyentryService', 'SessionService', '$log', "$scope","$rootScope",'AuthService'];
        companyentry: ICompanyentry;
        companyrequests: ICompanyrequests[];
        trafficdatefrom: string;
        trafficdateto: string;
        currentPage: number;
        currentCount: number;
        totalItems: number;
        active: boolean = true;
        showloading: boolean = false;
        activeCompany:any;

        constructor(companyentry, private CompanyentryService: CompanyentryService, private SessionService: ISessionService, private $log: angular.ILogService, public $scope: angular.IScope,private $rootScope,private AuthService: IAuthService) {

            this.activeCompany = SessionService.getActiveCompany();
            let lowerencCompanyname = encodeURIComponent(this.activeCompany.name.replace(/\s*\W/g, '-')).toLowerCase();

            companyentry.profileurl = companyentry.clienturl + '/' + lowerencCompanyname + '-c-' + this.activeCompany.id;

            this.companyentry = companyentry;

            this.loadRequests();

            this.trafficdatefrom = moment().subtract(1, "years").format('YYYY-MM-DD');

            if (moment(this.companyentry.dateto).diff(moment(), "days") > 0) {
                this.trafficdateto = moment().format('YYYY-MM-DD');
            } else {
                this.trafficdateto = this.companyentry.dateto;
            }

            $scope.$watch('vm.currentPage', (newPage, oldPage) => {
                if (newPage && oldPage && newPage != oldPage) {
                    this.loadRequests();
                }
            });

            $scope.$watch('vm.active', (after, before) => {
                if (after != before) {
                    if (this.currentPage == 1) {
                        this.loadRequests();
                    }
                    else {
                        this.currentPage = 1;
                    }
                }
            });

            
        }
        archiveRequest(pk) {
            this.showloading = true;
            this.CompanyentryService.archiveCompanyRequest(this.activeCompany.origid,pk)
                .then((result) => {
                    this.loadRequests();
    
                    //Benachritigungen aktualisieren
                    this.$rootScope.$broadcast('update-notifications');

                })
                .finally(() => {
                    this.showloading = false;
                });
        };

        loadRequests() {
            this.showloading = true;
            this.CompanyentryService.getCompanyRequests( this.activeCompany.companyid,this.companyentry.application_id, this.active, this.currentCount, this.currentPage)
                .then((result) => {
                    this.totalItems = result.data.total_count;
                    this.companyrequests = result.data.data;

                    angular.forEach(this.companyrequests, (companyrequest) => {

                        if(companyrequest.form.elements)
                        {
                            companyrequest.form.elements.forEach((element:any)=>{
                                companyrequest.form[element.key] = element.value;
                            });
                        }
                        else
                        {
                            companyrequest.form.text = companyrequest.form.text.replace(/\n/g, '<br/>');
                        }


                    });
                })
                .finally(() => {
                    this.showloading = false;

                });
        }

        
        openInNewTab(value) {   
            var link = angular.element('<a href="' + value + '" target="_blank"></a>');
            angular.element(document.body).append(link);
            link[0].click();
            link.remove();
         };
       

    }   

