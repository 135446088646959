var $ = require("jquery");

export default function collapse () {
    return {
        restrict: 'A',

        link: function (vm, ngElement, attributes) {
            var element = ngElement[0];

            vm.$watch(attributes.collapse, function (collapse) {
                var newHeight = collapse ? 0 : getElementAutoHeight();

                element.style.height = newHeight;
                ngElement.toggleClass('collapsed', collapse);                
                $('#page-wrapper').css('height', newHeight);
            });

            function getElementAutoHeight() {
                var currentHeight = getElementCurrentHeight();

                element.style.height = 'auto';
                var autoHeight = getElementCurrentHeight();


                element.style.height = currentHeight;
                getElementCurrentHeight(); // Force the browser to recalc height after moving it back to normal

                return autoHeight;
            }

            function getElementCurrentHeight() {
                return element.offsetHeight
            }
        }
    };
};