import { IScope, IPromise, IQService, ITimeoutService, ui } from "angular";
import moment = require("moment");

import {StateService} from "@uirouter/angularjs"
import { IToasterService } from "ngtoaster";
import ImagesService from "../../../../../services/images.service";
import LogosService from "../../../../../services/logos.service";
import angular = require("angular");

"use strict";


export default class LogosDetailComponent implements ng.IComponentOptions {

    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;


    constructor() {
        this.bindings = {
            content: '<',
            id: '<'

        };
        this.controllerAs = 'vm';
        this.controller = DetailComponentController;
        this.template = require("./detail.html");

    }


}


class DetailComponentController implements ng.IComponentController {

 

    content: any;
    file:any;

    datefrom:Date = null;
    dateto:Date = null;

    isSaving:boolean = false;

    id:string;
  
    isOpenDateFrom = false;
    isOpenDateTo = false;

    positions = [];
    static $inject: Array<String> = ["$translate","ImagesService","LogosService","$state","$q","toaster"];
    constructor(private $translate:angular.translate.ITranslateService,private ImagesService:ImagesService,private LogosService:LogosService,private $state:StateService,private $q:IQService,private toaster:IToasterService) {

    }

    content_before:any;

    $onInit() {
        this.content_before = angular.copy(this.content);
    }

    save()
    {

        if(this.dateto  !== null)
        {
            this.content.dateto = moment(this.dateto).toISOString();
        }
        else
        {
            this.content.dateto = null;
        }


        this.isSaving = true;

        this.ImagesService.upload(this.file,this.content.source,this.content.comment,"logo").then((result)=>{
            this.file = null;
            return result.data;
        })
        .then((content)=>{
             
            content.positions = this.positions;
            
            if(this.id == "new")
            {
               return this.LogosService.create(content);
            }
            else
            {
               //Update ist im Moment nicht möglich
               
            }
        })
        .then((result)=> {
            this.content = result;

            this.toaster.success(this.$translate.instant('Erfolgreich gespeichert!'));

            this.$state.go("index.companyprofile.general.logos");
        },
        (responseError:any):any =>
        {    
            this.toaster.error(this.$translate.instant('Es ist ein Fehler aufgetreten!'));                     
            this.isSaving =false;          
        }); 

    }

    isTempImage():boolean
    {
        return (typeof this.file === 'object')
    }


}