
    'use strict';

    import * as angular from "angular";
    export default class LanguageConfig {

        static $inject = ["$translateProvider"];

        constructor($translateProvider: angular.translate.ITranslateProvider) {

            let defaultLang = "de";

            $translateProvider.fallbackLanguage('de');
            $translateProvider.useStaticFilesLoader({
                prefix: './assets/localization/combined-',
                suffix: '.json'
            });
            //$translateProvider.translations("de",require('./../../translations/combined-de.json'));
            //$translateProvider.translations("en",require('./../../translations/combined-en.json'));

            // Default Language
            $translateProvider.preferredLanguage(defaultLang);
            // Sanitize Strategy
            $translateProvider.useSanitizeValueStrategy(null);
        }

    }

   

