import { StateService } from "@uirouter/core";
import { resource } from "angular";

"use strict";


export default class NewsComponent implements ng.IComponentOptions{
    
    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;
            

    constructor() {
        this.bindings = {                            
            result: '<',
            page:'<',
        };
        this.controllerAs = 'vm';
        this.controller = NewsComponentController;
        this.template = require("./news.html");            
        
    }


}

export class NewsComponentController implements ng.IComponentController{

    static $inject = ["$state"];



 


    
    constructor(private $state:StateService){}

    $onInit(){        
    }

    getData(page:number){
        this.$state.transitionTo(this.$state.$current.name,{page:page},{notify:false});
    }

}