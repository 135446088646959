import { IQService } from "angular";
import moment = require("moment");
import ImagesService from "../../../../services/images.service";
import { ICompanyentry } from "../../../../../../services/companyentries.service";
import {StateService} from "@uirouter/angularjs"
import VideosService from "../../../../services/videos.service";
import { IToasterService } from "ngtoaster";

"use strict";


export default class VideosDetailComponent implements ng.IComponentOptions {

    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;


    constructor() {
        this.bindings = {
            content: '<',
            id: '<'

        };
        this.controllerAs = 'vm';
        this.controller = VideosDetailComponentController;
        this.template = require("./videodetail.html");

    }


}


export class VideosDetailComponentController implements ng.IComponentController {

    

    content: any;

    datefrom:Date = null;
    dateto:Date = null;

    isSaving:boolean = false;

    image_path:string;
    id:string;
    alerts = [];
    isOpenDateFrom = false;
    isOpenDateTo = false;
    static $inject: Array<String> = ["$translate","ImagesService","VideosService","$state","$q","toaster","$scope"]
    constructor(private $translate:angular.translate.ITranslateService,private ImagesService:ImagesService,private VideosService:VideosService,private $state:StateService,private $q:IQService,private toaster:IToasterService,private $scope) {

    }

    $onInit() {
        moment.locale(this.$translate.use());
        if(this.content.datefrom)
        {
            this.datefrom = new Date(this.content.datefrom);
        }
        else
        {
            this.datefrom = new Date();
        }
        
        
        if(this.content.dateto)
        {
            this.dateto = new Date(this.content.dateto);
        }

      
    }

    save()
    {
        this.content.datefrom = moment(this.datefrom).toISOString();

        if(this.dateto  !== null)
        {
            this.content.dateto = moment(this.dateto).toISOString();
        }
        else
        {
            this.content.dateto = null;
        }


        this.isSaving = true;

        let promises:any = {};       



        this.$q.all(promises).then((results:any)=>
        {

            let content = this.content;

            if(results.image)
            {
                if(content.images.length > 0 && content.images[0] !== null){
                    //Löschen Async ausführen
                    this.ImagesService.delete(content.images[0].id);
                }
                content.images[0] = results.image;
            }

            return content;

        })
        .then((content)=>{
            if(this.id == "new")
            {
               return this.VideosService.create(content);
            }
            else
            {
                return this.VideosService.update(this.id,content);
            }
        })
        .then((result)=> {
            this.content = result;

            this.toaster.success(this.$translate.instant("Speichern erfolgreich!"));       
                
            if(  this.id == "new")
            {
                this.$state.transitionTo(this.$state.$current.name,{id:this.content.id},{notify:false});                    
            }
            else
            {
                this.isSaving =false;
            } 

        },
        (): any => {
                this.toaster.error(this.$translate.instant("Es ist ein Fehler aufgetreten!"));
                this.isSaving = false;
            }); 
    }


    closeAlert(index:number):void
    {
        this.alerts.splice(index,1);
    }

    extractId()
    {

        let valid = false;

        if(this.content.description.length > 0)
        {
            if(this.content.description.indexOf("youtube") > -1 )
            {
                let url = new URL(this.content.description);
                this.content.description = url.searchParams.get("v");
            }

            if(this.content.description.indexOf("https://youtu.be/") > -1)
            {
                let url = new URL(this.content.description);
                this.content.description =this.content.description.replace("https://youtu.be/","") ;
            }
            if(this.content.description.indexOf("http://youtu.be/") > -1)
            {
                let url = new URL(this.content.description);
                this.content.description =this.content.description.replace("http://youtu.be/","") ;
            }

            let regex = /[A-Za-z0-9_\-]{11}/;
            if(regex.test(this.content.description))
            {
                valid = true;
            }

        }

        this.$scope.videoform.description.$setValidity("valid_youtube",valid);


        
    }

}