import CompanyentryService, {ICompanyentryService} from './services/companyentries.service';

import {IBannerService} from './services/banner.service';
import AuthService, { IAuthService } from './services/Auth.service';
import SessionService, { ISessionService } from './services/Session.service';
import { ICampaignService } from './services/campaign.service';
import {ILeadsService} from './services/leads.service';

import * as angular from "angular";
import { StateProvider, Transition, UrlRouterProvider, StateService } from '@uirouter/angularjs';
import CompaniesService from './services/companies.service';

  "use strict";



  export default class RouterConfig {

    static $inject = ["$stateProvider", "$urlRouterProvider", "$locationProvider"];

    constructor($stateProvider: StateProvider, $urlRouterProvider: UrlRouterProvider, $locationProvider: angular.ILocationProvider) {
      
       $stateProvider
       .state('qwin', {
          url: "/qwin/:origid",
          resolve: {

            company: ["$transition$", "CompaniesService","$state","SessionService","AuthService","CompanyentryService","$q","toaster",
              function($transition$:Transition, 
              CompaniesService:CompaniesService,
              $state:StateService,
              SessionService:SessionService,
              AuthService:AuthService,
              CompanyentryService:CompanyentryService,
              $q:angular.IQService,
              toaster:toaster.IToasterService) {          
              return CompaniesService.getUserCompanyByOrigid($transition$.params().origid)
                .then(function (response) {
                 
                  if (AuthService.isLoggedIn() == false)
                  {
                    SessionService.setOrigid($transition$.params().companyid);
                    $state.go('login.qwin');
                  }
                  else{
                  
                    let p1 = CompanyentryService.getCompanyentries(response.companyid).then(
                      (success)=>{                     
                        SessionService.setCompanyentries(success.data);
                        return true;
                      });

                    let p2 =  SessionService.setActiveCompany(response);
      
                    $q.all([p1,p2]).then((success)=>{                       
                          //Benachritigungen aktualisieren
                          $state.go('index.dashboard');    
                            
                      },(error)=>{
                        toaster.error("Fehler","Es ist ein Fehler aufgetreten! Bitte versuchen Sie es erneut!");
                        $state.go('login.qwin');
                      });
                  }
              },
              ():any =>
                {    
                     SessionService.setOrigid($transition$.params().origid);
                    $state.go('login.qwin');
                } 
              );
            }]
          }
         
        })    
    

               //QWIN
       .state('origidlogin', {      
          url: "/a/:origid",
          resolve: {

            company: ["$transition$", "CompaniesService","$state","SessionService","AuthService","CompanyentryService","$q","toaster",
            function($transition$:Transition, 
            CompaniesService:CompaniesService,
            $state:StateService,
            SessionService:SessionService,
            AuthService:AuthService,
            CompanyentryService:CompanyentryService,
            $q:angular.IQService,
            toaster:toaster.IToasterService) {          
            return CompaniesService.getUserCompanyByOrigid($transition$.params().origid)
              .then(function (response) {
               
                if (AuthService.isLoggedIn() == false)
                {
                  SessionService.setOrigid($transition$.params().companyid);
                  $state.go('login.qwin');
                }
                else{
                
                  let p1 = CompanyentryService.getCompanyentries(response.companyid).then(
                    (success)=>{                     
                      SessionService.setCompanyentries(success.data);
                      return true;
                    });

                  let p2 =  SessionService.setActiveCompany(response);
    
                  $q.all([p1,p2]).then((success)=>{                       
                        //Benachritigungen aktualisieren
                        $state.go('index.dashboard');    
                          
                    },(error)=>{
                      toaster.error("Fehler","Es ist ein Fehler aufgetreten! Bitte versuchen Sie es erneut!");
                      $state.go('login.qwin');
                    });
                }
            },
            ():any =>
              {    
                   SessionService.setOrigid($transition$.params().origid);
                  $state.go('login.qwin');
              } 
            );
          }]
          }
         
        })
       
        .state('index', {
          abstract: true,
          url: "/i",
          template: require("./components/common/content.html"),
          redirectTo:'index.dashboard'
        })
        .state('index.forbidden', {          
          url: "/fobidden",
          template: require("./errors/403.html") 
        })
        .state('index.dashboard', {
          url: "/dashboard",
          template: require("./dashboard/dashboard.html"),
          controller: "DashboardController",
          controllerAs: "vm",
          data: {}
         
          }
        )
       //Advertorials Übersicht
        .state('index.advertorials', {
          url: "/advertorials",
          template: require("./advertorials/advertorials.html"),
          controller: "AdvertorialsController",
          controllerAs: "vm",
          data: {},
          resolve: {                       
          }
        })
        //Kampagnen Übersicht
        .state('index.campaigns', {
          url: "/campaigns",
          template: require("./campaigns/campaigns.html"),
          controller: "CampaignsController",
          controllerAs: "vm",
          data: {},
          resolve: {
           
          }
        })
        //Kampagne Detailansicht
        .state('index.campaign', {
          url: "/campaign/:id",
          params:{
            hideOverview:false
          },
          template:"<ui-view></ui-view>",
          abstract:true,
          data: {},
          resolve: {
            campaign:  ["$transition$","$state" ,"CampaignService",function ($transition$: Transition,$state:StateService, CampaignService: ICampaignService) {
              return CampaignService.getCampaign($transition$.params().id)
                .then(function (response) {

                    if($transition$.targetState().name() !== "index.campaign."+response.data.campaignproduct)
                    {
                        $transition$.abort();
                        $state.go( "index.campaign."+response.data.campaignproduct,{id:response.data.id});
                    }
                  return response.data;
                });
            }]
          }
        })
        //Kampagne Detailansicht
        .state('index.campaign.generator', {
          url: "/leadgenerator",
          template: require("./campaign/leadgenerator/leadgenerator.html"),
          controller: "LeadGeneratorController",
          controllerAs: "vm",
          data: {} ,
          resolve: {
            leadresult: ["$transition$","LeadsService" ,function ($transition$: Transition, LeadsService: ILeadsService) {
                return LeadsService.getLeadsByCampaign($transition$.params().id)
                  .then(function (response) {
                    return response.data;
                  });
            }]
          } 
        })
        //Kampagne Detailansicht
        .state('index.campaign.solution', {
          url: "/leadsolution",        
          template: require("./campaign/leadsolution/leadsolution.html"),
          controller: "LeadSolutionController",
          controllerAs: "vm",
          data: {},
          resolve: {
            leadresult:  ["$transition$","LeadsService" ,function ($transition$: Transition, LeadsService: ILeadsService) {
              return LeadsService.getLeadsByCampaign($transition$.params().id)
                .then(function (response) {
                  return response.data;
                });
            }]
          } 
        })
        //leadcampaign und solution haben aktuell die gleiche Darstellung
        .state('index.campaign.campaign', {
          url: "/leadcampaign",        
          template: require("./campaign/leadsolution/leadsolution.html"),
          controller: "LeadSolutionController",
          controllerAs: "vm",
          data: {} ,
          resolve: {
            leadresult: ["$transition$","LeadsService" , function ($transition$: Transition, LeadsService: ILeadsService) {
                return LeadsService.getLeadsByCampaign($transition$.params().id)
                  .then(function (response) {
                    return response.data;
                  });
            }]
          }  
        })
        //Kampagne Detailansicht
        .state('index.campaign.transfer', {
          url: "/leadtransfer",
          params:{
            nooverview:false
          },          
          template: require("./campaign/leadtransfer/leadtransfer.html"),
          controller: "LeadTransferController",
          controllerAs: "vm",
          data: {}  
        })
        //Kampagne Reachadvertorial Detailansicht
        .state('index.campaign.reach', {
          url: "/reachadvertorial",                  
          template: require("./campaign/reachadvertorial/reachadvertorial.html"),
          controller: "ReachadvertorialController",
          controllerAs: "vm",
          data: {}  
        })
        //Firmeneintrag Übersicht
        .state('index.banners', {
          url: "/banners",
          template: require("./banner/banner.html"),
          controller: "BannerController",
          controllerAs: "vm"
        })



        //Firmeneintrag Übersicht
        .state('index.companyentries', {
          url: "/companyentries",
          template: require("./companies/companyentries/companyentries.html"),
          controller: "CompanyentriesController",
          controllerAs: "vm",
          data: {},
          resolve: {
            companyentries:  ["SessionService",function (SessionService:ISessionService) {
                return SessionService.getCompanyentries();
            }]
          }
        })
        


       //Nur wenn eingeloggt
         .state('setuniqueid', {      
          url: "/setuniqueid",
          template: "<vi-setuniqueid></vi-setuniqueid>",
            resolve:{
               authenticated:["AuthService",function(AuthService:IAuthService){
                 return AuthService.checkRemoteSession();               
               }]
            },
          data: { pageTitle: 'Unique ID setzen',regfree:true ,specialClass: 'image-bg',requireLogin: true}
        })    


         //Firmeneintrag Detail
        .state('index.companyentry', {
          url: "/companyentry/:id",
          template: require("./companies/companyentry/companyentry.html"),
          controller: "CompanyentryController",
          controllerAs: "vm",
          data: {},
          resolve: {
            companyentry:  ["$transition$","CompanyentryService","SessionService" , function ($transition$: Transition, CompanyentryService: CompanyentryService,SessionService:ISessionService) {
                return CompanyentryService.getCompanyentry(SessionService.getActiveCompany().companyid, $transition$.params().id)               ;
            }]
        }
        })       
        //Companies
        .state('index.usercompanies', {
          url: "/usercompanies",
          template: require("./user/usercompanies/usercompanies.html"),
          controller: "UsercompaniesController",
          controllerAs: "vm",
          data: {}
        })
        .state('index.account',
        {
          url:"/account",
          component:'viAccount'


        })
        //Login
        .state('login', {
          url: "/login",
          component:'viLogin',
          data: { pageTitle: 'Login',regfree:true,specialClass: 'image-bg',qwin:false,kc:false }
        })
         //Login fuer qwin
        .state('login.qwin', {
          url: "/qwin",
          component:'viLogin',
          data: { pageTitle: 'Login',regfree:true,specialClass: 'image-bg',qwin:true,kc:false }
        })
         //Login fuer qwin
        .state('login.kc', {
          url: "/kc",
          component:'viLogin',
          data: { pageTitle: 'Login',regfree:true,specialClass: 'image-bg',qwin:false,kc:true }
        })
         //Login fuer qwin
        .state('login.kcen', {
          url: "/kc-en",
          component:'viLogin',
          data: { pageTitle: 'Login',regfree:true,specialClass: 'image-bg',qwin:false,kcen:true }
        })
        //Send Password
        .state('forgot-password',{
          url:"/forgot-password",
          component:'viResendPassword',
          data: { pageTitle: 'Passwort vergessen',regfree:true ,specialClass: 'image-bg'}
        })
        //Password ändern
        .state('change-password',{
          url:"/change-password/:u",
          component:'viChangePassword',
         params: {
            u: {
            value: null,
            squash: true
          }},
          data: { pageTitle: 'Passwort ändern',regfree:true ,specialClass: 'image-bg'}
        })
        //Registrierung
        .state('register', {
          url: "/register",
          template: require("./auth/register/register.html"),
          controller: "RegisterController",
          controllerAs: "vm",
          data: { pageTitle: 'Register',regfree:true ,specialClass: 'image-bg'}
        })
        //404
        .state('404', {
          url: "/404",
          template: require("./errors/404.html"),
          data: { pageTitle: '404', specialClass: 'gray-bg' ,regfree:true}
        })
        //500
        .state('500', {
          url: "/500",
          template: require("./errors/500.html"),
          data: { pageTitle: '500', specialClass: 'gray-bg' ,regfree:true}
        })
        .state('imprint', {
          url: "/imprint",
          template: require("./components/common/imprint.html"),
          data: { pageTitle: 'imprint', specialClass: 'gray-bg' ,regfree:true}
        });


      $urlRouterProvider.otherwise('/i/dashboard');

      $locationProvider.html5Mode(true);


    }
  }

  

