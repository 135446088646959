import SessionService from "../../../../services/Session.service";
import { IScope, translate } from "angular";
import {uniqBy} from "lodash";

export interface IApplication{
    application_id:number,
    name:string,
    chronic:boolean,
    status:number
}

"use strict";
export default class ApplicationsComponent implements ng.IComponentOptions
{

    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;


    constructor() {
        this.bindings = {
            positions: '=',
            dirty:'=?',
            isDetail:'<?',
            isRequired:'<?',
            limit: "@"
        };
        this.controllerAs = 'vm';
        this.controller = ApplicationsComponentController;
        this.template = require("./applications.html");

    }
}

class ApplicationsComponentController implements ng.IComponentController
{
    public  ID_ALL = 1899;

    private dirty:boolean;

    private isDetail:boolean;

    private isRequired:boolean;

    private limit: number | undefined = undefined;

    private option_all:IApplication ;

    static $inject = ["SessionService","$scope","$translate"];

    applications:IApplication[]= [];

    positions:IApplication[];

    positions_copy:IApplication[];

    constructor(private SessionService:SessionService,private $scope:IScope,private $translate:translate.ITranslateService){}

    $onInit()
    {
        if(typeof this.positions == "undefined")
        {
            this.positions = [];
        }
        this.positions_copy = this.positions.slice(0);


        if(typeof this.dirty == "undefined")
        {
            this.dirty = false;
        }
        if(typeof this.isDetail == "undefined")
        {
            this.isDetail = false;
        }
        if(typeof this.isRequired == "undefined")
        {
            this.isRequired = true;
        }

        this.option_all = {
            application_id: this.ID_ALL,
            name: this.$translate.instant("Alle Firmenprofile"),
            status:5,
            chronic:true
        };

        const orders = this.SessionService.getCompanyentries().map(({application_id, application}) => ({
            application_id,
            name: application,
            status:5,
            chronic:true
        }))
        //Dopplungen vermeiden
        this.applications = uniqBy(orders, "application_id");
        const application_ids = this.applications.map(({application_id}) => application_id)


        if(application_ids.length > 1)
        {
            this.applications.unshift(this.option_all);
        }



        this.$scope.$watchCollection("vm.positions_copy",(newValue:IApplication[],oldValue:IApplication[])=>{

            //War vorher "alle Portale" gewählt
            if(oldValue.length == 1 && oldValue[0].application_id == this.ID_ALL)
            {
                this.positions_copy.splice(0,1);
            }

            //Kam was neues hinzu?
            let boo_new_element = newValue.length > oldValue.length;

            if(boo_new_element)
            {
                let newElement = newValue[newValue.length - 1];

                //Ist neues Element "alle Portale" oder wurden alle Portal gewählt?
                if(newElement.application_id == this.ID_ALL || newValue.length == (this.applications.length -1))
                {
                    this.positions_copy = [];
                    this.positions_copy.push(this.option_all);
                }

            }


            //eigentliches Model aktualisieren
            if(this.positions_copy.length == 1 && this.positions_copy[0].application_id == this.ID_ALL)
            {
                this.positions = [];
                this.applications.forEach(element => {
                    if(element.application_id !== this.ID_ALL)
                    {
                        this.positions.push(element);
                    }
                });
            }
            else
            {
                this.positions = this.positions_copy.slice(0);
            }

            //Alles gewählt und es gibt mehr als ein Portal
            if(newValue.length == (this.applications.length -1) && this.applications.length > 2)
            {
                this.positions_copy = [];
                this.positions_copy.push(this.option_all);
            }

        });

    }

    setDirty(){
        if(this.isRequired)
        {
            this.dirty = this.positions.length > 0;
        }
        else
        {
            this.dirty = true;
        }

    }
}