import { ICompanyentry } from "../../../../../services/companyentries.service";
import ProductsService from "../../../services/products.service";
import { StateService } from "@uirouter/core";
import { resource } from "angular";

"use strict";

export default class ProductsComponent implements ng.IComponentOptions{
    
    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;
            
    constructor() {
        this.bindings = {                            
            result: '<',
            feedsResult :'<',
            page :'<'
        };
        this.controllerAs = 'vm';
        this.controller = ProductsComponentController;
        this.template = require("./products.html");            
        
    }


}
export class ProductsComponentController implements ng.IComponentController{

    static $inject = ["$state"];

    private result;

    private feedsResult:resource.IResource<any>;

    private feedsLoading = true;

    private feeds:any[];

    private page;
    
    constructor(private $state:StateService){}

    $onInit(){        
        this.feedsResult.$promise.then((result)=>{
            this.feeds = result.data;
            this.feedsLoading = false;
        });
    }

    getData(page:number){
        this.$state.transitionTo(this.$state.$current.name,{page:page},{notify:false});
    }

    addFeed(){
        this.feeds.push({abstract:'products',id:"new",positions:[]});
    }


}