var $ = require("jquery");

export default function scroll () {
    return {
        link: function (scope, elem, attrs) {  
           
            var offsetTop = $(elem).offset().top;
          
            $(window).scroll(function() 
            {
                var currentOffset = offsetTop - $(window).scrollTop();

              
                if(currentOffset < 200)
                {
                    $(elem).addClass("btn-fixed");
                }
                else
                {
                    $(elem).removeClass("btn-fixed");
                }
              
                
            });    
            
        }
    };
};


  