import { StateService } from "@uirouter/core";
import { resource } from "angular";

"use strict";


export default class NewsFeedsComponent implements ng.IComponentOptions{
    
    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;
            

    constructor() {
        this.bindings = {                            
            result: '<',
            page:'<',
            feedsResult:'<'
        };
        this.controllerAs = 'vm';
        this.controller = ComponentController;
        this.template = require("./news.html");            
        
    }


}

class ComponentController implements ng.IComponentController{

    static $inject = ["$state"];



    private feedsResult:resource.IResource<any>;

    private feedsLoading = true;
    
    private feeds:any[];

    constructor(private $state:StateService){}

    $onInit(){        
        this.feedsResult.$promise.then((result)=>{
            this.feeds = result.data;
            this.feedsLoading = false;
        });
    }

    getData(page:number){
        this.$state.transitionTo(this.$state.$current.name,{page:page},{notify:false});
    }
    addFeed(){
        this.feeds.push({abstract:'news',id:"new",positions:[]});
    }
}