import {IQService} from "angular";
import ImagesService from "../../../../services/images.service";
import {StateService} from "@uirouter/angularjs"
import GalleriesService from "../../../../services/galleries.service";
import {IToasterService} from "ngtoaster";
import moment = require("moment");
import angular = require("angular");


"use strict";


export default class GalleriesDetailComponent implements ng.IComponentOptions {

    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;


    constructor() {
        this.bindings = {
            content: '<',
            id: '<',

        };
        this.controllerAs = 'vm';
        this.controller = GalleriesDetailComponentController;
        this.template = require("./gallerydetail.html");

    }


}


export class GalleriesDetailComponentController implements ng.IComponentController {

    static $inject: Array<String> = ["$translate","ImagesService","GalleriesService","$state","$q","toaster"]

    content: any;
    file:any;

    datefrom:Date = null;
    dateto:Date = null;

    isSaving:boolean = false;

    image_path:string;
    id:string;
    alerts = [];
    isOpenDateFrom = false;
    isOpenDateTo = false;

    constructor(private $translate:angular.translate.ITranslateService,
        private ImagesService:ImagesService,private GalleriesService:GalleriesService,private $state:StateService,private $q:IQService,
        private toaster:IToasterService) {

    }

    $onInit() {
        moment.locale(this.$translate.use());
        if(this.content.datefrom)
        {
            this.datefrom = new Date(this.content.datefrom);
        }
        else
        {
            this.datefrom = new Date();
        }
        
        
        if(this.content.dateto)
        {
            this.dateto = new Date(this.content.dateto);
        }    
      
    }

    save()
    {
     
        this.content.datefrom = moment(this.datefrom).toISOString();

        if(this.dateto  !== null)
        {
            this.content.dateto = moment(this.dateto).toISOString();
        }
        else
        {
            this.content.dateto = null;
        }

        
        this.isSaving = true;

        let promises:any = [];       
                   
        angular.forEach(this.content.images,(image,index)=>{
                if(image.$ngfBlobUrl)
                {
                    promises.push(this.ImagesService.upload(image).then((result)=>{

                        return {
                            "index": index,
                            "data": result.data
                        };
                    }));
                }

                if(image.delete)
                {
                    promises.push(this.ImagesService.delete(image.id).then((result)=>{
                        return {
                            "index": index,
                            "data": null
                        };
                    }));
                }
        })



        this.$q.all(promises).then((results:any)=>
        {
            let content = this.content;      
            //Bilder kopieren    
            let tmp_images = content.images;
            angular.forEach(results, (result)=>
            {
             
                if(typeof result.index != "undefined")
                {  
                    //Daten zurückschreiben
                    tmp_images[result.index]= result.data;                    
                    
                }
            });
            
            //Gelöschte Elemente rausschmeißen
            content.images = tmp_images.filter(function (el) {
                return el != null;
              });


            return content;

        })
        .then((content)=>{
            if(this.id == "new")
            {
               return this.GalleriesService.create(content);
            }
            else
            {
                return this.GalleriesService.update(this.id,content);
            }
        })
        .then((result)=> {
            this.content = result;
            this.toaster.success(this.$translate.instant("Speichern erfolgreich!"));       

            if(  this.id == "new")
            {
                this.$state.transitionTo(this.$state.$current.name,{id:this.content.id},{notify:false});                    
            }
            else
            {
                this.isSaving =false;
            } 

        },
        (responseError:any):any =>
        {    
            this.toaster.error(this.$translate.instant("Es ist ein Fehler aufgetreten!"));
            this.isSaving =false;          
        }); 
    }



    deleteImage(index)
    {       
        if(this.content.images[index].id)
        {
            this.content.images[index].delete = true;
            
        }
        else
        {
            this.content.images.splice(index,1);
        }
    }

}