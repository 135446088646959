export interface IPasswordService {
    showPassword(input: any);
    showRequirements(input: any);
}

export default class PasswordService implements IPasswordService {
    showPassword(input: any): any {
        let attr: any = input.$$attr,
            id: string = attr.id,
            pwdEl: any = document.getElementById(id),
            type: string = pwdEl.getAttribute("type") === "password" ? "text" : "password";
        pwdEl.setAttribute("type", type);
        attr.type = type;        
    }

    // Passwort Validation
    password: string = "";
    hasEightChars: boolean = false;
    hasLowerCase: boolean = false;
    hasUpperCase: boolean = false;
    hasNumber: boolean = false;
    hasSpecialChar: boolean = false;

    showRequirements(input: any): any {
        
        let elementPassword: HTMLElement = document.getElementById("password");
        
        //@ts-ignore
        this.password = elementPassword.value;
        
        const elements = document.querySelectorAll(".inf-password-requirements").forEach((element:HTMLElement) => { 
            element.style.display = "flex";            
        });
          
        this.hasEightChars = this.password.length >= 8;
    
        this.hasLowerCase = this.password.match(/[a-z]/) !== null;

        this.hasUpperCase = this.password.match(/[A-Z]/) !== null;

        this.hasNumber = this.password.match(/\d/) !== null;
   
        this.hasSpecialChar = this.password.match(/[!?$%^&*(){}[\]<>\/|\-=+~_.,;:]/) !== null;

        // validate the length
        if (this.hasEightChars) {
            document.querySelectorAll(".inf-passwordhint-icon--8-chars").forEach((element:HTMLElement) => { 
                element.classList.remove('fa-times-circle');            
                element.classList.add('fa-check-circle');
            });           

        } else {
            document.querySelectorAll(".inf-passwordhint-icon--8-chars").forEach((element:HTMLElement) => { 
                element.classList.add('fa-times-circle');            
                element.classList.remove('fa-check-circle');
            });              
        }

        // validate lower case
        if (this.hasLowerCase) {
            document.querySelectorAll(".inf-passwordhint-icon--lower-case").forEach((element:HTMLElement) => { 
                element.classList.remove('fa-times-circle');            
                element.classList.add('fa-check-circle');
            });           

        } else {
            document.querySelectorAll(".inf-passwordhint-icon--lower-case").forEach((element:HTMLElement) => { 
                element.classList.add('fa-times-circle');            
                element.classList.remove('fa-check-circle');
            });    
        }

        // validate upper case
        if (this.hasUpperCase) {
            document.querySelectorAll(".inf-passwordhint-icon--upper-case").forEach((element:HTMLElement) => { 
                element.classList.remove('fa-times-circle');            
                element.classList.add('fa-check-circle');
            });           

        } else {
            document.querySelectorAll(".inf-passwordhint-icon--upper-case").forEach((element:HTMLElement) => { 
                element.classList.add('fa-times-circle');            
                element.classList.remove('fa-check-circle');
            });        
        }

        // validate number
        if (this.hasNumber) {
            
            document.querySelectorAll(".inf-passwordhint-icon--number").forEach((element:HTMLElement) => { 
                element.classList.remove('fa-times-circle');            
                element.classList.add('fa-check-circle');
            });           

        } else {
            document.querySelectorAll(".inf-passwordhint-icon--number").forEach((element:HTMLElement) => { 
                element.classList.add('fa-times-circle');            
                element.classList.remove('fa-check-circle');
            });           
        }

        // validate special char
        if (this.hasSpecialChar) {
            document.querySelectorAll(".inf-passwordhint-icon--special-char").forEach((element:HTMLElement) => { 
                element.classList.remove('fa-times-circle');            
                element.classList.add('fa-check-circle');
            });           
          
        } else {
            document.querySelectorAll(".inf-passwordhint-icon--special-char").forEach((element:HTMLElement) => { 
                element.classList.add('fa-times-circle');            
                element.classList.remove('fa-check-circle');
            });           
        }
        if(this.hasSpecialChar && this.hasEightChars && this.hasLowerCase && this.hasNumber && this.hasUpperCase){
            elementPassword.classList.remove('inf-form__input-text--error');
        }
    }
}