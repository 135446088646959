    import {IFeedbackMessage,IFeedbackService} from "./feedback.service";
    import {IInsightsRootScopeService} from "./../../index.run";
    import * as angular from "angular";
    export default class FeedbackComponent implements ng.IComponentOptions{

        public bindings: any;
        public controller: any;
        public controllerAs:string;
        //public template: string;
        public template: string;
        

        constructor() {
            this.bindings = {};
            this.controllerAs= 'vm',
            this.controller = FeedbackComponentController;
            this.template = require("./feedback.html");
        }
    }


    class FeedbackComponentController{

        show:boolean;        
        showLoading:boolean;
        alerts = [];
        static $inject: Array<string> = ["FeedbackService","$timeout","$translate","$rootScope"]; 

        message:IFeedbackMessage;;

        constructor(private FeedbackService:IFeedbackService,private $timeout:angular.ITimeoutService,private $translate:angular.translate.ITranslateService,private $rootScope:IInsightsRootScopeService){            
            this.show = false;
            this.showLoading = false;  
        }
               

       sendFeedback(){
           
           this.showLoading = true;

           this.FeedbackService.sendFeedback(this.message).then(
           (success:any):any=>{

                //RESET Message
                this.message = {
                    text:"",
                    rating:null
                };

                this.$rootScope.feedbackSent = true;
           },
           (error:any):any=>{
                this.alerts.push({ type:"danger",msg:this.$translate.instant("feedback_error")});
                this.$timeout(()=>{                   
                    this.alerts = [];
                },5000);
           }
           ).finally(():any=>{
               this.showLoading = false;

           });
       }

        closeAlert(index:number):void{
            this.alerts.splice(index,1);
        }


    }

   
