
    import CompanyentryService, { ICompanyentryService } from "./../services/companyentries.service";
    import { IAuthService } from "./../services/Auth.service";
    import angular = require("angular");
import SessionService from "../services/Session.service";
    
    export default class HeaderComponent implements ng.IComponentOptions{

        public bindings: any;
        public controller: any;
        public controllerAs:string;
        //public template: string;
        public template: string;
        

        constructor() {
            this.bindings = {};
            this.controllerAs= 'vm',
            this.controller = HeaderComponentController;
            this.template = require("./header.html");
        }
    }


    class HeaderComponentController{

        private loading:boolean = false;

        user:any;
        companyrequests: any;
        companyrequestcount:number;
        
        static $inject: Array<string> = ['CompanyentryService',"$translate","$log","$scope","AuthService","SessionService"]; 

        constructor(private CompanyentryService: CompanyentryService,private $translate:angular.translate.ITranslateService,private $log:angular.ILogService,private $scope,private AuthService:IAuthService,private SessionService:SessionService){}
               

        $onInit(){
            
            this.getCount();                

            this.$scope.$on('update-notifications', (event, args) => {
                this.companyrequests == null
                this.getCount();                                
            });

            this.user = this.SessionService.getUser();
        }   

        getCount(){
            this.companyrequestcount = 0;
            this.loading = true;
            this.CompanyentryService.getCompanyrequestsCount(this.SessionService.getActiveCompany().origid).then((count)=> {            
              
                this.companyrequestcount = count;                
            }).finally(()=>{
                this.loading = false;
            });
        }
        getRequests(){
            if(this.companyrequestcount > 0 && this.companyrequests == null )
            {
                this.loading = true;
                this.CompanyentryService.getCompanyrequestsByClient(this.SessionService.getActiveCompany().origid).then((result) =>{
                      
                        this.companyrequests = result;                    
                        
                    }
                ).finally(()=>{
                    this.loading = false;
                });
            }
            
        }

        logOut(){
            this.AuthService.logOut();
        }
    }

   
