import D3Funnel from 'd3-funnel';

/**
 * iboxTools - Directive for iBox tools elements in right corner of ibox
 */
export default function d3Funnel() {
    return {
        restrict: 'E',
        scope:{
            funnelData:'<'
        },
        template: require("./d3funnel.html"),
        controller: ["$scope", "$element",function ($scope, $element) {
               
                var options = {chart:{ height:250,width:400,bottomPinch:1 },block:{dynamicHeight:false}};

                var chart = new D3Funnel('#funnel');
                chart.draw($scope.funnelData, options); 

                $scope.$watch('funnelData',function(){
                    chart.destroy();
                    chart = new D3Funnel('#funnel');
                    chart.draw($scope.funnelData, options); 
                });
        }]
    };
}

