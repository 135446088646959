
    "use strict";

    export default class LocaleConfig{
        static $inject = ["tmhDynamicLocaleProvider"];

        constructor(tmhDynamicLocaleProvider){
             tmhDynamicLocaleProvider.localeLocationPattern('assets/localization/angular-locale_{{locale}}.js');
        }
    }

    
