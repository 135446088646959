import { StateService } from "@uirouter/core";
import { resource, IQService } from "angular";

"use strict";


export default class JobsComponent implements ng.IComponentOptions{
    
    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;
            

    constructor() {
        this.bindings = {                            
            result: '<',
            page:'<',
            feedsResult :'<',
            jobwareResult: '<'
        };
        this.controllerAs = 'vm';
        this.controller = JobsComponentController;
        this.template = require("./jobs.html");            
        
    }


}

export class JobsComponentController implements ng.IComponentController{

    static $inject = ["$state","$q"];

    private feedsResult:resource.IResource<any>;
    private jobwareResult:resource.IResource<any>;

    private feedsLoading = true;

    private feeds:any[];
    private jobwareconfigs:any[];
    
    constructor(private $state:StateService,private $q:IQService){}

    $onInit(){        

        let promises  = [];
        let promiseFeed = this.feedsResult.$promise.then((result)=>{
            this.feeds = result.data;           
        });
        
        let promiseJobware = this.jobwareResult.$promise.then((result)=>{
            this.jobwareconfigs = result.data;            
        });

        promises.push(promiseFeed,promiseJobware);
        
        this.$q.all(promises).finally(()=>{
            this.feedsLoading = false;
        });
    }

    getData(page:number){
        this.$state.transitionTo(this.$state.$current.name,{page:page},{notify:false});
    }



}