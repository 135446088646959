import {IToasterService} from "ngtoaster";
import {translate, IScope} from "angular";
import ICompanyContentItemComponentController from "../../../../interfaces/ICompanyContentItemComponentController";
import SocialmediaService from "../../../../services/socialmedia.service";
import {StateService} from "@uirouter/core";
import {ContentTypes} from "../contentTypes";
import {includes} from "lodash";


"use strict";


export default class SocialmediaItemComponent implements ng.IComponentOptions {

    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;


    constructor() {
        this.bindings = {
            content: '<',
            onDelete: '&',
            onChange: '&'
        };
        this.controllerAs = 'vm';
        this.controller = ItemComponentController;
        this.template = require("./item.html");
    }


}

class ItemComponentController implements ng.IComponentController, ICompanyContentItemComponentController {
    private content: any;

    private dirty: boolean = false;
    private isDeleting: boolean = false;
    private isSaving: boolean = false;

    static $inject = ["SocialmediaService", "toaster", "$translate", "$ngConfirm", "$scope", "$state"]

    constructor(private SocialmediaService: SocialmediaService, private toaster: IToasterService, private $translate: translate.ITranslateService, private $ngConfirm, private $scope: IScope, private $state: StateService) {
        this.$scope.$on("updatePublications", () => {
            if (this.dirty) {
                this.setPublications();
            }
        });
        this.$scope.$watch("vm.dirty", (newValue, oldValue) => {
            if (newValue) {
                this.onChange();
            }
        });
    }

    $onInit() {
    }


    onDelete() {
    }

    onChange() {
    }

    setPublications() {
        this.isSaving = true;
        this.SocialmediaService.patch(this.content.id, {"positions": this.content.positions})
            .then(() => {
                this.toaster.success(this.$translate.instant("Speichern erfolgreich"));
                this.dirty = false;
            }, () => {
                this.toaster.error(this.$translate.instant("Speichern fehlgeschlagen"));
            }).finally(() => {
            this.isSaving = false;
        })
    }

    delete() {


        this.$ngConfirm({
            title: this.$translate.instant("Löschen"),
            autoClose: 'cancel|8000',
            content: this.$translate.instant("Inhalt wirklich löschen?"),
            type: "red",
            buttons: {
                delete: {
                    text: this.$translate.instant("Löschen"),
                    btnClass: 'btn-danger',
                    action: () => {
                        this.isDeleting = true;
                        this.SocialmediaService.delete(this.content.id)
                            .then((sucess) => {
                                    this.toaster.success(this.$translate.instant("Löschen erfolgreich!"));
                                    this.onDelete();
                                    this.content = null;

                                },
                                (error) => {
                                    this.toaster.error(this.$translate.instant("Löschen fehlgeschlagen!"));
                                }).finally(() => {
                            this.isDeleting = false;
                        });

                    }
                },
                cancel: {
                    text: this.$translate.instant("Abbrechen"),
                    action: () => {
                        this.isDeleting = false;
                        return true;
                    }

                }
            }
        });


    }

    goDetail() {
        this.isSaving = true;
        this.$state.go("index.companyprofile.socialmedia.detail", {id: this.content.id});
    }

    getContentTypeIcon(contentType: string): string {
        const lowerCase = contentType.toLowerCase();
        const mappings = {
            "facebook": "fa-facebook-square",
            "twitter": "fa-twitter-square",
            "xing": "fa-xing-square",
            "youtube": "fa-youtube-square",
            "linkedin": "fa-linkedin-square",
            "instagram": "fa-instagram",
            "tiktok": "",
            "pinterest": "fa-pinterest-square",
            "wechat": "fa-wechat",
            "snapchat": "fa-snapchat-square",
            "kununu": "",
        }
        return mappings[lowerCase] ?? "";
    }
}