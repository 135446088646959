


    export class OAuthConfig {
        static $inject = ["OAuthProvider","NETWORK_ID"];        
        
        constructor(OAuthProvider,NETWORK_ID){
            OAuthProvider.configure({
                baseUrl:'/api/insights',
                clientId:'Insights',
                grantPath: '/oauth?network_id='+NETWORK_ID,
                revokePath: '/oauth/revoke'
            });
        }
    }

    export class OAuthTokenConfig {
            static $inject = ["OAuthTokenProvider","PRODUCTION"];        
            
            constructor(OAuthTokenProvider,PRODUCTION){
                OAuthTokenProvider.configure({
                name: 'token',
                options: {
                    secure: PRODUCTION,
                    path:'/'
                }
            });
        }
    }
