import {IAuthService} from "./../../services/Auth.service";
import SessionService from "./../../services/Session.service";
import CompaniesService from "./../../services/companies.service";
import {StateService} from "@uirouter/core";
import * as angular from "angular";
import CompanyentryService from "../../services/companyentries.service";
import {IPasswordService} from "../../services/password.service";


declare var _gscq: any;
declare var $: any;
declare var jQuery: any;

export default class LoginComponent implements ng.IComponentOptions {

    public bindings: any;
    public controller: any;
    public controllerAs: string;
    //public template: string;
    public template: string;

    constructor() {
        this.bindings = {};
        this.controllerAs = 'vm',
            this.controller = LoginComponentController;
        this.template = require('./login.html')
    }


}

interface ILoginComponentController {
    credentials: any;

    login(): void;

    closeAlert(index: number): void;

    alerts: any[];
    loginLoading: boolean;
}

class LoginComponentController implements ILoginComponentController {

    public credentials = {
        email: "",
        pwd: "",
        permanent: true
    };
    loginLoading = false;
    alerts = [];
    date: Date;

    logourl: string;

    static $inject: Array<string> = [
        'AuthService',
        "PasswordService",
        'SessionService',
        'CompaniesService',
        '$state',
        "$translate",
        "$timeout",
        "$uibModal",
        "$q",
        "CompanyentryService",
        "NETWORK_ID",
        "NETWORK_CONFIG",
        "$window"
    ];

    constructor(
        private AuthService: IAuthService, private PasswordService: IPasswordService, private SessionService: SessionService,
        private CompaniesService: CompaniesService, private $state: StateService, private $translate: angular.translate.ITranslateService,
        private $timeout: angular.ITimeoutService, private $uibModal, private $q: angular.IQService, private CompanyentryService: CompanyentryService,
        private NETWORK_ID: number, private NETWORK_CONFIG: any, private $window
    ) {
        this.date = new Date();
        let data = this.$state.current.data;
        if (data.qwin == true) {
            this.openQwinInfo();
        }
        if (data.kc == true) {
            this.openKCInfo('de');
        }
        if (data.kcen) {
            this.openKCInfo('en');
        }
    }

    login(): void {
        this.loginLoading = true;
        // Arrow Function, so dass man this an Controller gebunden wird
        this.AuthService.logIn(this.credentials).then(
            (isagency: boolean): any => {
                this.loginLoading = true;
                let user = this.SessionService.getUser();

                // Bei Login über QWIN ist origid gesetzt
                if (typeof (user.origid) !== 'undefined' && user.origid !== null) {
                    this.CompaniesService.getUserCompany(user.origid).then(
                        (company: any): any => {
                            let p1 = this.CompanyentryService.getCompanyentries(company.origid).then((success) => {
                                this.SessionService.setCompanyentries(success.data);
                                return true;
                            });
                            let p2 = this.SessionService.setActiveCompany(company);
                            let p3 = this.SessionService.setUserCompany(company);
                            return this.$q.all([p1, p2, p3]).then((success) => {

                                // Call getsitecontrol with origid
                                _gscq.push(['targeting', 'kundennummer', company.origid]);
                                // Benachritigungen aktualisieren
                                this.$state.go('index.dashboard');
                                return true;
                            })
                        },
                        (responseError: any): any => {
                            if (isagency) {
                                this.$state.go('index.usercompanies');
                            } else {
                                this.$state.go('index.dashboard');
                            }
                        });

                } else {
                    this.CompaniesService.getUserCompany(user.companies[0]).then(
                        (company: any): any => {

                            let p1 = this.CompanyentryService.getCompanyentries(company.origid).then((success) => {
                                this.SessionService.setCompanyentries(success.data);
                                return true;
                            });
                            let p2 = this.SessionService.setActiveCompany(company);
                            let p3 = this.SessionService.setUserCompany(company);

                            return this.$q.all([p1, p2, p3]).then((success) => {


                                // Call getsitecontrol with origid
                                _gscq.push(['targeting', 'kundennummer', company.origid]);

                                if (isagency) {
                                    this.$state.go('index.usercompanies');
                                } else {
                                    this.$state.go('index.dashboard');
                                }
                            })
                        });
                }
            },
            (responseError: any): any => {
                this.loginLoading = false;

                //Password wrong
                if (responseError.status == 401) {
                    this.alerts.push({type: "danger", msg: this.$translate.instant('login_error_text')});
                    this.$timeout(() => {
                        this.alerts = [];
                    }, 5000);
                }
                //Other errors
                else {
                    this.alerts.push({type: "warning", msg: this.$translate.instant('login_technical_error')});
                    this.$timeout(() => {
                        this.alerts = [];
                    }, 5000);
                }
            });
    }

    closeAlert(index: number): void {
        this.alerts.splice(index, 1);
    }

    openImprint() {
        this.$uibModal.open({
            controllerAs: 'vm',
            controller: ModalInstanceControl,
            template: require('./../../components/common/imprint.html')
        });
    }

    openContact() {
        this.$uibModal.open({
            controllerAs: 'vm',
            controller: ModalInstanceControl,
            template: require('./../../components/common/contact.html')
        });
    }

    openQwinInfo() {
        this.$uibModal.open({
            controllerAs: 'vm',
            controller: ModalInstanceControl,
            template: require('./../../components/common/qwin.html')
        });
    }

    openKCInfo(lang = "de") {
        if (lang == "de") {
            this.$uibModal.open({
                controllerAs: 'vm',
                controller: ModalInstanceControl,
                template: require('./../../components/common/kc.html')
            });
        } else {
            this.$uibModal.open({
                controllerAs: 'vm',
                controller: ModalInstanceControl,
                template: require('./../../components/common/kc-en.html')
            });
        }
    }

    showPassword(input: any): any {
        this.PasswordService.showPassword(input);
    }
}

class ModalInstanceControl {
    static $inject: Array<string> = ["$uibModalInstance"]

    constructor(private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance) {
    }

    close() {
        this.$uibModalInstance.dismiss();
    }
}