import SessionService from "../../../services/Session.service";
import { IPromise, resource } from "angular";
import IContentResourceClass from "../interfaces/IContentResourceClass.interface";


"use strict";


export default class InfoService {
    static URL: string = "/api/insights/companies/:companyid/info/:id";
    static $inject: Array<string> = ['$http',"$resource","SessionService"];

    private resource:IContentResourceClass<any>;
    constructor(private $http: angular.IHttpService,$resource:ng.resource.IResourceService,private SessionService:SessionService) { 
        this.resource = <IContentResourceClass<any>>$resource(InfoService.URL,{companyid:'@companyid',id:'@id'},{
            update:{method:'PUT',params:{id:'@data.id'}},
            create:{method:'POST'},
            patch:{method:'PATCH'},
            query:{isArray:false,method:"GET"},
            delete:{method:'DELETE'}
        });
    }

    public fetch(id:string):resource.IResource<any>
    {
        return this.resource.get({companyid:this.SessionService.getActiveCompany().origid,id:id});
    }

    public fetchAll(limit=10,skip=0):resource.IResourceArray<any>
    {        
       return this.resource.query({companyid:this.SessionService.getActiveCompany().origid,skip:skip,limit:limit});
    }

    public update(id:string,content:Object):IPromise<Object>
    {
        const content_string = angular.toJson(content);
        content = angular.fromJson(content_string);
        return this.resource.update({companyid:this.SessionService.getActiveCompany().origid,data:content,id:id}).$promise;
    }

    public patch(id:string,map:Object):IPromise<Object>
    {      
        return this.resource.patch({companyid:this.SessionService.getActiveCompany().origid,data:map,id:id}).$promise;
    }

    public create(content:Object):IPromise<Object>
    {
        const content_string = angular.toJson(content);
        content = angular.fromJson(content_string);
        return this.resource.create({companyid:this.SessionService.getActiveCompany().origid,data:content}).$promise;
    }

    public delete(id:string):IPromise<Object>    
    {
        return this.resource.delete({companyid:this.SessionService.getActiveCompany().origid,id:id}).$promise;
    }



}
