import { IQService} from "angular";
import moment = require("moment");
import ImagesService from "../../../../services/images.service";
import {StateService} from "@uirouter/angularjs"
import { IToasterService } from "ngtoaster";
import InfoService from "../../../../services/info.service";

"use strict";


export default class InfoDetailComponent implements ng.IComponentOptions {

    public bindings: any;
    public controller: any;
    public controllerAs: string;
    public template: string;


    constructor() {
        this.bindings = {
            content: '<',
            id: '<'

        };
        this.controllerAs = 'vm';
        this.controller = DetailComponentController;
        this.template = require("./detail.html");

    }


}


export class DetailComponentController implements ng.IComponentController {

    static $inject: Array<String> = ["$translate","ImagesService","InfoService","$state","$q","toaster"]

    content: any;
    file:any;

    datefrom:Date = null;
    dateto:Date = null;

    isSaving:boolean = false;

    image_path:string;
    id:string;
    alerts = [];
    isOpenDateFrom = false;
    isOpenDateTo = false;

    constructor(private $translate:angular.translate.ITranslateService,private ImagesService:ImagesService,private InfoService:InfoService,private $state:StateService,private $q:IQService,private toaster:IToasterService) {

    }

    $onInit() {
        moment.locale(this.$translate.use());
        if(this.content.datefrom)
        {
            this.datefrom = new Date(this.content.datefrom);
        }
        else
        {
            this.datefrom = new Date();
        }
        
        
        if(this.content.dateto)
        {
            this.dateto = new Date(this.content.dateto);
        }

      
    }

    save()
    {
                
        this.isSaving = true;


        let promise:any = {};



        if(this.dateto  !== null)
        {
            this.content.dateto = moment(this.dateto).toISOString();
        }
        else
        {
            this.content.dateto = null;
        }

      
        if(this.id == "new")
        {
            promise = this.InfoService.create(this.content);
        }
        else
        {
            promise = this.InfoService.update(this.id,this.content);
        }
       
        promise.then((result)=> {
            this.content = result;

            this.toaster.success(this.$translate.instant('Erfolgreich gespeichert!'));
            if(  this.id == "new")
            {
                this.$state.transitionTo(this.$state.$current.name,{id:this.content.id},{notify:false});                    
            }
            else
            {
                this.isSaving =false;
            }  

        },
        (responseError:any):any =>
        {    
            this.toaster.error(this.$translate.instant('Es ist ein Fehler aufgetreten!'));                     
            this.isSaving =false;          
        }); 

    }

    isTempImage():boolean
    {
        return (typeof this.file === 'object')
    }


}